
import {Badge, Button, Modal, Row} from "react-bootstrap";
import {IconButton, Typography} from "@material-ui/core";
import {useEffect, useState} from "react";
import CloseIcon from '@material-ui/icons/Close';
import _ from "lodash"
export default function DiagnoseBuilderEvaluation({
    open,
    onClose,
    diseaseOptions
    }) {
    /* const [diseaseOption, setDiseaseOption] = useState([
        {
            title: 'Disease A',
            possibility: 85,
            id: 1,
            checked : false
        },
        {
            title: 'Disease B',
            possibility: 70,
            id: 2,
            checked: false
        },
        {
            title: 'Disease C',
            possibility: 60,
            id: 3,
            checked: false
        },
        {
            title: 'None',
            possibility: null,
            id: 4,
            checked: false
        },
    ]) */
    const [selectedDisease, setSelectedDisease] = useState([])
    const [allowSubmit, setAllowSubmit] = useState(false)

    //const [options, setOptions] = useState(null)

    
    const [localDiseases, setLocalDiseases] = useState([])
    useEffect(()=>{
        /* setLocalDiseases([...diseaseOptions]) */
        if(open) {
            setLocalDiseases([...diseaseOptions, {
                name: 'None',
                possibility: null,
                id: 4,
            }])
        } else {
            setLocalDiseases([])
        }
    },[open])

    /* let options = diseaseOptions
    options?.map(el => {
        return Object.assign(el, {checked: false})
    }) */


        /* setOptions([...options, {
            name: 'None',
            possibility: null,
            id: 4,
        }]) */
/*     
        let options = diseaseOptions
        options.push({
            name: 'None',
            possibility: null,
            id: 4,
        })
        options?.map(el => {
            return Object.assign(el, {checked: false})
        })

        console.log("tes", options) */
    
        //setOptions(tempOptions)

        




    return (
        <>
            <Modal show={open} onHide={() => {}}>
                <Modal.Header  style={{border: 'none', display: 'block', paddingLeft:'0.5rem'}}>
                    <div className={'d-flex'} style={{justifyContent: "space-between", alignItems: "center"}}>
                        <div className={'d-flex'} style={{gap: 10}}>
                            <div style={{
                                display: "flex",
                                width: "28px",
                                height: "28px",
                                padding: "6px",
                                justifyContent: "center",
                                alignItems: "center",
                                borderRadius: "8px",
                                background: "rgba(23, 118, 207, 0.16)"
                            }}>
                                <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16"
                                     fill="none">
                                    <path
                                        d="M4.66667 11.3333H6.06667L10.0667 7.36663L8.63333 5.93329L4.66667 9.89996V11.3333ZM10.5333 6.89996L11.2333 6.16663C11.3 6.09996 11.3333 6.02218 11.3333 5.93329C11.3333 5.8444 11.3 5.76663 11.2333 5.69996L10.3 4.76663C10.2333 4.69996 10.1556 4.66663 10.0667 4.66663C9.97778 4.66663 9.9 4.69996 9.83333 4.76663L9.1 5.46663L10.5333 6.89996ZM3.33333 14C2.96667 14 2.65267 13.8693 2.39133 13.608C2.13 13.3466 1.99956 13.0328 2 12.6666V3.33329C2 2.96663 2.13067 2.65263 2.392 2.39129C2.65333 2.12996 2.96711 1.99951 3.33333 1.99996H6.13333C6.27778 1.59996 6.51956 1.27774 6.85867 1.03329C7.19778 0.788848 7.57822 0.666626 8 0.666626C8.42222 0.666626 8.80289 0.788848 9.142 1.03329C9.48111 1.27774 9.72267 1.59996 9.86667 1.99996H12.6667C13.0333 1.99996 13.3473 2.13063 13.6087 2.39196C13.87 2.65329 14.0004 2.96707 14 3.33329V12.6666C14 13.0333 13.8693 13.3473 13.608 13.6086C13.3467 13.87 13.0329 14.0004 12.6667 14H3.33333ZM8 2.83329C8.14445 2.83329 8.26378 2.78596 8.358 2.69129C8.45222 2.59663 8.49956 2.47729 8.5 2.33329C8.5 2.18885 8.45267 2.06951 8.358 1.97529C8.26333 1.88107 8.144 1.83374 8 1.83329C7.85556 1.83329 7.73622 1.88063 7.642 1.97529C7.54778 2.06996 7.50045 2.18929 7.5 2.33329C7.5 2.47774 7.54734 2.59707 7.642 2.69129C7.73667 2.78552 7.856 2.83285 8 2.83329Z"
                                        fill="#1776CF"/>
                                </svg>
                            </div>
                            <Modal.Title>Give us your evaluation</Modal.Title>
                        </div>
                        <IconButton onClick={() => {
                            onClose()
                        }} aria-label="delete">
                            <CloseIcon fontSize="small" />
                        </IconButton>
                    </div>


                </Modal.Header>

                <Modal.Body style={{paddingTop: 0}}>
                    <Row>
                        <p>From the results of our diagnosis, which one do you feel suits your patient? (You can select
                            more
                            than one)</p>
                    </Row>
                    {
                      localDiseases?.map((disease) => {
                            return (
                                <>
                                    <Row style={{display: 'grid'}} className={'mb-3'}>
                                        <Button onClick={() => {
                                            const mapDisease = localDiseases?.map((v) => {
                                                return {
                                                    ...v,
                                                    checked : v.id === disease.id ? !v.checked : v.checked
                                                }
                                            })
                                            const checkDisease = mapDisease.filter((v) => {
                                                return v.checked
                                            })
                                          if(checkDisease?.length > 0){
                                              setAllowSubmit(true)
                                          }else {
                                          setAllowSubmit(false)
                                          }
                                          setLocalDiseases(mapDisease)
                                          console.log(localDiseases)
                                        }} style={disease?.checked ?
                                        {
                                            borderRadius: "16px",
                                            border: "1px solid #1776CF",
                                            background: "rgba(23, 118, 207, 0.08)"
                                        } : {
                                                borderRadius: "16px",
                                            }
                                        } variant={`outline-secondary`}
                                                size="lg">
                                            <div className={'d-flex'} style={{gap: 20, alignItems: 'center'}}>
                                                <Typography style={{
                                                    fontSize: 14,
                                                    fontWeight: 700
                                                }}>{disease.name}</Typography>
                                                {
                                                    disease.possibility &&  <Badge className={'dg-primary diagnose-builder-badge'}
                                                                       pill>{disease.possibility}% possibility</Badge>
                                                }

                                            </div>
                                        </Button>
                                    </Row>
                                </>
                            )
                        })
                    }

                </Modal.Body>

                <Modal.Footer>
                    <Row>
                        <Button className={'db-primary'} disabled={!allowSubmit} style={{
                            borderRadius: 24
                        }} variant={allowSubmit ? 'primary' : 'secondary'} size="lg"
                        onClick={onClose}
                        >Send evaluation</Button>
                    </Row>
                </Modal.Footer>
            </Modal>
            <div className={'d-flex'}>

            </div>
        </>
    )
}