import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { Grid } from '@material-ui/core';
import { useFormik } from 'formik';
import * as Yup from 'yup';

import {
  Card,
  CardBody,
  CardHeader
} from '../../../_metronic/_partials/controls';
import { EditHeaderToolbar as HeaderToolbar } from '../../components/common/HeaderToolbar';
import { TextField } from '../../components/common/TextField';
import { ActionBarEdit as ActionBar } from '../../components/common/ActionBar';
import { fetchAnimalCategory } from '../../../redux/animalCategory/detail';
import {
  editAnimalCategory,
  deleteAnimalCategory
} from '../../../redux/animalCategory/ops';

const AnimalCategoryEditPage = () => {
  // Prereq Hooks
  const dispatch = useDispatch();

  const { id } = useParams();
  const isFetching = useSelector(state => state.loading.animalCategory);

  const { data } = useSelector(state => state.animalCategory.detail);
  useEffect(() => {
    dispatch(fetchAnimalCategory(id));
  }, []);

  const { completed } = useSelector(state => state.animalCategory.ops);
  useEffect(() => {
    if (completed) {
      setIsEditing(false);
    }
  }, [completed]);

  const [isEditing, setIsEditing] = useState(false);

  const {
    values,
    errors,
    touched,
    handleChange,
    handleSubmit,
    handleReset,
    resetForm
  } = useFormik({
    initialValues: {
      name: data?.category_name || ''
    },
    validationSchema: Yup.object({
      name: Yup.string().required('Cannot be empty')
    }),
    onSubmit: values => {
      dispatch(editAnimalCategory(id, values));
    },
    enableReinitialize: true
  });

  return (
    <Card>
      <CardHeader title="Animal category details">
        <HeaderToolbar
          isEditing={isEditing}
          handleEdit={() => setIsEditing(true)}
          handleCancel={() => {
            resetForm();
            setIsEditing(false);
          }}
        />
      </CardHeader>
      <CardBody>
        <form onSubmit={handleSubmit}>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <TextField
                disabled={!isEditing}
                id="name"
                value={values.name}
                onChange={handleChange}
                label="Category Name *"
                helperText={touched.name ? errors.name : ''}
                error={touched.name && errors.name ? true : false}
              />
            </Grid>
            <ActionBar
              isEditing={isEditing}
              isFetching={isFetching}
              handleSubmit={handleSubmit}
              handleDelete={() => dispatch(deleteAnimalCategory(id))}
            />
          </Grid>
        </form>
      </CardBody>
    </Card>
  );
};

export default AnimalCategoryEditPage;
