import { Row } from 'react-bootstrap';

const HorizontalSeparator = ({ text }) => {
  return (
    <div style={{margin: "1.5rem 0"}}>
      <div className="horizontal-lines">{text}</div>
    </div>
  );
};

export default HorizontalSeparator;
