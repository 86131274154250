import api from '../../api/userAPI';
import {
  setSuccessNotification,
  setErrorNotification
} from '../snackbarNotification';

export const APPROVE_BEGIN = 'appointment.approve/APPROVE_BEGIN';
export const APPROVE_SUCCESS = 'appointment.approve/APPROVE_SUCCESS';
export const APPROVE_FAILED = 'appointment.approve/APPROVE_FAILED';

const approveBegin = () => ({ type: APPROVE_BEGIN });
const approveSuccess = () => ({ type: APPROVE_SUCCESS });
const approveFailed = error => ({ type: APPROVE_FAILED, payload: error });
export const approveAppointment = id => async dispatch => {
  dispatch(approveBegin());

  const formattedData = {
    appointment_id: id
  };

  try {
    const res = await api.post('approve-appointment-schedule', formattedData);

    dispatch(approveSuccess());
    dispatch(setSuccessNotification('Successfully approved appointment'));
  } catch (error) {
    dispatch(approveFailed(error.toString()));
    dispatch(setErrorNotification(error.toString()));
  }
};

const reducer = (state = initialState, action) => {
  const { type, payload } = action;

  switch (type) {
    case APPROVE_BEGIN:
      return { ...state, completed: false, isLoading: true, error: null };

    case APPROVE_SUCCESS:
      return { ...state, completed: true, isLoading: false };

    case APPROVE_FAILED:
      return { ...state, isLoading: false, error: payload };

    default:
      return state;
  }
};

export default reducer;

const initialState = {
  completed: false,
  isLoading: false,
  error: null
};
