import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  Card,
  CardBody,
  CardHeader,
  CardHeaderToolbar
} from '../../../_metronic/_partials/controls';

import { ListHeaderToolbar as HeaderToolbar } from '../../components/common/HeaderToolbar';
import { DataTableLocal as DataTable } from '../../components/common/DataTable';
import { fetchAnimalCategoryList } from '../../../redux/animalCategory/list';

const AnimalCategoryListPage = () => {
  // Prereq Hooks
  const dispatch = useDispatch();

  // Initial fetch
  useEffect(() => {
    dispatch(fetchAnimalCategoryList());
  }, []);

  const { data } = useSelector(state => state.animalCategory.list);
  const isFetching = useSelector(state => state.loading.animalCategory?.list);

  const columns = [
    {
      dataField: 'category_name',
      text: 'Category'
    }
  ];

  return (
    <Card>
      <CardHeader title="Animal category list">
        <CardHeaderToolbar>
          <HeaderToolbar addLink="/animalCategory/add" addText="New Category" />
        </CardHeaderToolbar>
      </CardHeader>
      <CardBody>
        <DataTable
          data={data}
          loading={isFetching}
          totalDataCount={data.length}
          columns={columns}
          editLink="/animalCategory/"
        />
      </CardBody>
    </Card>
  );
};

export default AnimalCategoryListPage;
