import APIRequest from "../utils/APIRequest";

export default class AppointmentModel {

    static addAppointmentSlot = async (category_id, doctor_id, codoctor, start_time, end_time, clinic_id) => {
        console.log("ASDF", start_time, end_time)
        return await APIRequest.set(`v1/appointment-slot`, 'POST', {
            category_id, doctor_id, codoctor, start_time, end_time, clinic_id
        });
    }

    static getDoctorAppointmentByWeek = async (doctorId, dateStart, dateEnd) => {
        console.log("date start", dateStart, "dateEnd", dateEnd)
        return await APIRequest.set(`v1/appointment-slot/dateRange?doctor_id=${doctorId}&dateStart=${dateStart}&dateEnd=${dateEnd}`, 'GET');
    }

    static getDoctorAppointmentByWeekForDoctor = async (dateStart, dateEnd) => {
        return await APIRequest.set(`v2/appointment-user/date?start_date=${dateStart}&end_date=${dateEnd}`, 'POST', {});
    }

    static getAppointmentDetail = async (id) => {
        return await APIRequest.set(`v1/appointment-user/admin/` + id, 'GET');
    }

    static addAppointment = async (appointment_slot_id, booking_id, start_time, end_time, patient_ids, user_id, description, operation_table_id, payment_proof) => {
        console.log("results", [appointment_slot_id, booking_id, start_time, end_time, patient_ids, user_id, operation_table_id, payment_proof])

        return await APIRequest.set(`v1/appointment-user/admin`, 'POST', {
            appointment_slot_id, booking_id, start_time, end_time, patient_ids, user_id, description, operation_table_id, proof_of_payment: payment_proof
        });
    }

    static getWeeklyReport = async (start_date, end_date) => {
        console.log(start_date, end_date)
        return await APIRequest.set(`v1/appointment-user-weeklyreport`, 'POST', {
            start_date, end_date
        });
    }

    static getUpcomingAppointmentslotbyCategoryandDoctorId = async (categoryid, docid) => {

        return await APIRequest.set(`v1/appointment-slot/upcoming/${categoryid}/${docid}`, 'GET');
    };

    static getBookedByAppointmentslotId = async apid => await APIRequest.set(`v1/appointment-user/booked/appointment-slot/${apid}`, 'GET');

    static cancelAppointmentUser = async (id) => {
        return await APIRequest.set(`v1/appointment-user/cancel/${id}`, 'POST');
    }

    static deleteAppointmentSlot = async (id) => {
        return await APIRequest.set(`v1/appointment-slot/timeslot/${id}`, 'DELETE');
    }

    static deleteMedicalRecord = async (id) => {
        return await APIRequest.set(`v1/medical-record/${id}`, 'DELETE');
    }

}
