import HorizontalSeparator from 'app/components/common/HorizontalSeparator/HorizontalSeparator';
import {
  Button,
  Grid,
  MenuItem,
  InputLabel,
  Input,
  OutlinedInput,
  Box,
  Chip,
  Checkbox,
  Radio,
  RadioGroup,
  ListItemText,
  Modal,
  FormGroup,
  FormControl,
  FormControlLabel
} from '@material-ui/core';
import { Link, useHistory, useParams } from 'react-router-dom';
import { TextField } from '../../components/common/TextField';
import { Card, CardBody, CardHeader, CardHeaderToolbar } from '../../../_metronic/_partials/controls';
import React, { useState, useEffect } from 'react';
import MultipleSelect from 'app/components/common/MultipleSelect/MultipleSelect';
import { Autocomplete } from '@material-ui/lab';
import DiseaseModel from '../../models/Disease';
import SymptomModel from '../../models/Symptom';
import generalInfoModel from '../../models/GeneralInformation';
import Select from 'react-select';
import { Row, Col, Form } from 'react-bootstrap';
import swal from 'sweetalert';
import { FaArrowLeft, FaArrowRight } from "react-icons/fa6";
//import DiseaseSymptomSelectionModal from './DiseaseSymptomSelectionModal';

const CATEGORIES = {
  "drinking": "Drinking",
  "eating": "Eating",
  "body_temperature": "Body Temperature",
  "general_condition": "General Condition",
  "food_type": "Food Type",
  "age": "Age",
  "gender": "Gender"
}

const PreDiagnosePage = () => {
  const { id } = useParams();
  const { diagnoseId } = useParams();
  const [personName, setPersonName] = useState([]);

  const [animalOptions, setAnimalOptions] = useState([]);
  //const [bodyPartOptions, setBodyPartOptions] = useState([]);
  const [temperatureOptions, setTemperatureOptions] = useState([]);
  const [drinkingOptions, setDrinkingOptions] = useState([]);
  const [eatingOptions, setEatingOptions] = useState([]);
  const [foodTypeOptions, setFoodTypeOptions] = useState([]);
  const [generalConditionOptions, setGeneralConditionOptions] = useState([]);
  const [symptomOptions, setSymptomOptions] = useState([]);
  const [generalResult, setGeneralResult] = useState([]);
  const [generalInfoCategories, setGeneralInfoCategories] = useState([]);

  const [selectedName, setSelectedName] = useState('');
  const [selectedAnimal, setSelectedAnimal] = useState(null);
  const [selectedAction, setSelectedAction] = useState('');
  const [selectedBodyPart, setSelectedBodyPart] = useState([]);
  const [selectedTemperature, setSelectedTemperature] = useState(null);
  const [selectedDrinking, setSelectedDrinking] = useState(null);
  const [selectedEating, setSelectedEating] = useState(null);
  const [selectedFood, setSelectedFood] = useState(null);
  const [selectedGeneralCondition, setSelectedGeneralCondition] = useState(null);
  const [selectedSymptoms, setSelectedSymptoms] = useState([]);
  const [modalData, setModalData] = useState(null);
  const [submitData, setSubmitData] = useState({})
  const [eatings, setEatings] = useState([])

  const [symptomDetailToEdit, setSymptomDetailToEdit] = useState(null);

  const history = useHistory();

  console.log('SYTO', symptomOptions, selectedSymptoms);

  const initializeGeneralInfo = async () => {
    try {
      let result = await generalInfoModel.getAll();
      let resultSymptom = await SymptomModel.getAllActive();

      console.log(resultSymptom);
      console.log(result);

      let unique = [];

      result.generalinformation.forEach(element => {
        if (!unique.includes(element.category)) {
          unique.push(element.category);
        }
      });

      console.log("hmm", unique)

      setGeneralInfoCategories(unique)

      setGeneralResult(result.generalinformation)
      
      setAnimalOptions(
        result.animal_types.map(a => ({
          value: a.id,
          label: a.animal_name
        }))
      );
      console.log(result.animal_types);
      
    } catch (e) {
      console.log('error', e);
    }
  };

  const submit = async () => {
    //console.log(selectedSymptoms);
    if (selectedName == null || selectedName == '' || selectedName == []) {
      swal({
        title: 'Name',
        text: 'Name cannot be empty',
        icon: 'error'
      });
      return;
    }
    if (selectedAnimal == null || selectedAnimal == '' || selectedAnimal == []) {
      swal({
        title: 'Animal',
        text: 'Animal cannot be empty',
        icon: 'error'
      });
      return;
    }
    if (selectedAction == null || selectedAction == '' || selectedAction == []) {
      swal({
        title: 'Action',
        text: 'Action cannot be empty',
        icon: 'error'
      });
      return;
    }
    if (selectedTemperature == null || selectedTemperature == '' || selectedTemperature == []) {
      swal({
        title: 'Body Temperature',
        text: 'Body temperature cannot be empty',
        icon: 'error'
      });
      return;
    }
    if (selectedDrinking == null || selectedDrinking == '' || selectedDrinking == []) {
      swal({
        title: 'Drinking',
        text: 'Drinking cannot be empty',
        icon: 'error'
      });
      return;
    }
    if (selectedEating == null || selectedEating == '' || selectedEating == []) {
      swal({
        title: 'Food Frequency',
        text: 'Food frequency cannot be empty',
        icon: 'error'
      });
      return;
    }
    if (selectedFood == null || selectedFood == '' || selectedFood == []) {
      swal({
        title: 'Food Type',
        text: 'Food type cannot be empty',
        icon: 'error'
      });
      return;
    }
    if (selectedGeneralCondition == null || selectedGeneralCondition == '' || selectedGeneralCondition == []) {
      swal({
        title: 'General Condition',
        text: 'General condition cannot be empty',
        icon: 'error'
      });
      return;
    }
    if (selectedSymptoms == null || selectedSymptoms == '' || selectedSymptoms == []) {
      swal({
        title: 'Symptom',
        text: 'Symptoms cannot be empty',
        icon: 'error'
      });
      return;
    }

    let generalInfo = [];
    selectedDrinking?.forEach(element => {
      generalInfo.push(element.value);
    });
    selectedEating?.forEach(element => {
      generalInfo.push(element.value);
    });
    selectedFood?.forEach(element => {
      generalInfo.push(element.value);
    });
    selectedGeneralCondition?.forEach(element => {
      generalInfo.push(element.value);
    });
    selectedTemperature?.forEach(element => {
      generalInfo.push(element.value);
    });
    let body = {
      name: selectedName,
      action: selectedAction,
      animal_type_id: selectedAnimal?.value,
      general_informations: generalInfo
    };
    //console.log(body, selectedSymptoms)
    let symArray = [];
    selectedSymptoms?.forEach(el => {
      el.detail?.forEach(det => {
        det.options?.forEach(opt => {
          symArray.push({ symptom_id: opt.symptom_id, symptom_detail_options_id: opt.symptom_detail_option_id });
        });
      });
    });
    body.symptoms = symArray;
    console.log(body, 'BODY');

    try {
      let res = await DiseaseModel.create(body);
      //console.log("apa itu res", res)

      swal({
        title: 'Success',
        text: "Disease '" + res.name + "' has been added",
        icon: 'success'
      }).then(() => {
        window.location.href = `/disease/${res.id}`;
      });

      /* alert("Disease '" + res.name + "' has been added successfully")

      window.location.href = `/disease/${res.id}` */
    } catch (error) {
      swal({
        title: 'Error',
        text: 'Cannot create new disease',
        icon: 'error'
      });
    }
  };

  const reset = () => {
    setSelectedAction('');
    setSelectedAnimal(null);
    setSelectedDrinking(null);
    setSelectedEating(null);
    setSelectedFood(null);
    setSelectedGeneralCondition(null);
    setSelectedName('');
    setSelectedSymptoms(null);
    setSelectedTemperature(null);
  };

  const changeEating = (id) => {
    if (eatings.includes(id))
      setEatings(eatings.filter(i => i !== id))
    else
      setEatings([...eatings, id])
  }

  const changeSubmitData = (category, id) => {
    if(submitData[category] == id) {
      let tempSubmit = {...submitData}
      //console.log('here', tempSubmit)
      delete tempSubmit[category]
      //console.log('here', tempSubmit)
      setSubmitData(tempSubmit)
    } else {
      setSubmitData({ ...submitData, [category]: id });
    }
  }

  const handleChange = (event, category) => {
    //console.log(event)
    setSubmitData({ ...submitData, [event.target.name]: event.target.value });
    //console.log("hmm", eatings)
  };

  console.log("hmm", eatings)
  console.log('here_main', submitData)

  useEffect(() => {
    initializeGeneralInfo();
    delete history.location.state?.generalInfo?.animal_type
    console.log("mana", history.location.state)
  }, []);

  return (
    <div>
      <div style={{ marginTop: "auto", marginBottom: 20 }}>
        <b style={{ fontSize: 20 }}>Pre-diagnose form</b>
        <br />
        Fill the pre-diagnose form below.
      </div>
      {/* <Card>
        <CardBody> */}
      <form>
        {generalInfoCategories.filter(el => el != 'age' && el != 'gender').map(el => (
          <div style={{
            borderStyle: "solid",
            borderRadius: 15,
            borderWidth: "thin",
            borderColor: "#e6e1e1",
            padding: 10,
            marginBottom: 20,
          }}>
            <Grid container spacing={2} style={{ marginBottom: 10 }}>
              <Grid item xs={12}>
                <b>{CATEGORIES[el]}</b>
              </Grid>
              {/* <Grid item xs={1}>
                      <Button style={{rotate: "180deg"}}>V</Button>
                    </Grid> */}
            </Grid>

            <Row>
              {generalResult.filter(el2 => el2.category == el).map(e => (
                <Col xl={2} md={3}>
                  {
                    el == "eating" ?

                      <Form>

                        <div
                          onClick={() => changeEating(e.id)}
                          key={`inline-${e.value}`} className="mb-3" style={{
                            borderStyle: "solid",
                            borderRadius: 10,
                            borderWidth: "thin",
                            borderColor: eatings.includes(e.id) ? "rgba(0, 55, 255, 0.5)" : "#bfbaba",
                            width: "100%",
                            padding: 7,
                            backgroundColor: eatings.includes(e.id) ? "rgba(0, 155, 255, 0.2)" : "transparent",
                            cursor: "pointer"
                            //marginTop: "-1.5vh"
                          }}>
                          <Form.Check
                            //custom
                            inline
                            label={e.value}
                            name={e.value + '-name'}
                            type="checkbox"
                            id={e.value + '-id'}
                            checked={eatings.includes(e.id)}
                            //onClick={() => changeEating(e.id)}
                          />
                        </div>

                      </Form>
                      /* <FormControlLabel control={<Checkbox name={el} color="primary" onChange={event => handleChange(event, el)} />} label={e.value} value={e.id} style={{
                        borderStyle: "solid",
                        borderRadius: 10,
                        borderWidth: "thin",
                        borderColor: "#bfbaba",
                        width: "250px"
                        //marginTop: "-1.5vh"
                      }}
                      /> */
                      :
                      /*  <RadioGroup name={el} value={submitData[el]} onChange={event => handleChange(event, el)}>
                        <FormControlLabel control={<Radio color="primary" />} label={e.value} value={e.id} style={{
                          borderStyle: "solid",
                          borderRadius: 10,
                          borderWidth: "thin",
                          borderColor: "#bfbaba",
                          width: "250px"
                          //marginTop: "-1.5vh"
                        }}
                        />
                      </RadioGroup> } */
                      <div
                        onClick={()=> changeSubmitData(el, e.id)}
                        style={{
                          borderStyle: "solid",
                          borderRadius: 10,
                          borderWidth: "thin",
                          borderColor: submitData[el] == e.id ? "rgba(0, 55, 255, 0.5)" : "#bfbaba",
                          width: "100%",
                          marginBottom: 5,
                          backgroundColor: submitData[el] == e.id ? "rgba(0, 155, 255, 0.2)" : "transparent",
                          cursor : "pointer"
                          //marginTop: "-1.5vh"
                        }}
                      >
                        <Radio
                          checked={submitData[el] == e.id}
                          // onChange={event => handleChange(event, el)}
                          value={e.id}
                          color="primary"
                          name={el}
                          inputProps={{ 'aria-label': e.value }}
                        />
                        {e.value}
                      </div>
                  }

                </Col>
              ))}
            </Row>
          </div>

        ))}
      </form>
      <Grid container style={{ marginBottom: 10 }}>
        <Grid className='d-flex align-items-center justify-content-center' item xs={12}>
          <div 
            //onClick={()=>{history.push("/diagnose/" + id + "/general-data/" + patientID)}}
            onClick={() => { history.push({
              pathname: "/diagnose/" + id + "/general-data/" + diagnoseId,
              state: {
                animal_type_id: history.location.state?.animal_type_id,
                generalInfo: {...history.location.state?.generalInfo, ...submitData, eatings: eatings}
              }
            })}}
            className='d-flex align-items-center justify-content-center'
            style={{background : "#1776CF", width : 50, height : 50, borderRadius : 5, cursor : 'pointer'}}>
            <FaArrowLeft size={20} color='white' />
          </div>
          <div style={{flex : 1}}>
            

          </div>
          <div 
            onClick={() => { history.push({
              pathname: "/diagnose/" + id + "/symptom-form/" + diagnoseId,
              state: {
                animal_type_id: history.location.state?.animal_type_id,
                generalInfo: {...history.location.state?.generalInfo, ...submitData, eatings: eatings}
              }
            })}}
            className='d-flex align-items-center justify-content-center'
            style={{background : "#1776CF", width : 50, height : 50, borderRadius : 5, cursor : 'pointer'}}>
            <FaArrowRight size={20} color='white' />
          </div>
        </Grid>
      </Grid>
    </div>
  );
};

export default PreDiagnosePage;
