import React, {useEffect, useState} from 'react';
import {useHistory, useParams, Link} from 'react-router-dom';
import {Link as LinkIcon, CloudUploadOutlined, ArrowBackIosOutlined, DeleteTwoTone} from '@material-ui/icons';
import {Button, Grid, IconButton, Modal} from '@material-ui/core';
import {useFormik} from 'formik';
import * as Yup from 'yup';
import {Row, Col} from 'react-bootstrap';

import {DataTableLocal as DataTable} from '../../components/common/DataTable';
import {Card, CardBody, CardHeaderToolbar, CardHeaderTitle} from '../../../_metronic/_partials/controls';
import {TextField} from '../../components/common/TextField';
import {ActionBarAdd} from '../../components/common/ActionBar';

import swal from 'sweetalert2';
import File from 'app/models/File';
import MedicalRecord from 'app/models/MedicalRecord';
import {useSelector} from "react-redux";
import DoctorModel from "../../models/Doctor";
import UserModel from '../../../model/UserModel';
import {Autocomplete} from "@material-ui/lab";
import AppointmentModel from "../../../model/AppointmentModel";

import FreeForAllDoctor from "../../../utils/FreeForAllDoctor";
import Vaccination from "../../models/Vaccination";
import KeyboardDatePicker from "../../components/common/KeyboardDatePicker/KeyboardDatePicker";

const VaccinationAddPage = () => {
    const history = useHistory();
    const {patient_id} = useParams();

    const {role} = useSelector(state => {
        // console.log("ROLLINGx", state.auth)
        return state.auth
    });
    let isAdmin = role === "ADMIN"

    const [doctorSelection, setDoctorSelection] = useState([])
    const [selectedDoctor, setSelectedDoctor] = useState(null)

    useEffect(() => {
        retrieveDoctor()
    }, [])

    const {values, errors, touched, handleChange, handleSubmit, handleReset, setFieldValue} = useFormik({
        initialValues: {
            description: '',
            vaccination_date: '',
        },
        validationSchema: Yup.object({
            description: Yup.string(),
            medication: Yup.string(),
            medicine: Yup.string()
        }),
        onSubmit: formValues => {
            submitVaccination(formValues);
        }
    });

    const retrieveDoctor = async () => {
        try {

            const result = await DoctorModel.getAll()

            console.log("doctor list", result)

            if (result) {
                let formatted = result.map(obj => {
                    return {
                        ...obj,
                        label: `${obj.doctor_name}`,
                        value: obj.id
                    }
                })
                setDoctorSelection(formatted)
            } else {
                setDoctorSelection([])
            }

        } catch (e) {
            console.log(e)
        }
    }


    const submitVaccination = async formValues => {

        let formData = {
            patient_id: patient_id,
            description: formValues.description,
            doctor_id: selectedDoctor?.doctor_id,
            vaccination_date: formValues.vaccination_date
        };

        console.log("fd", formData)

        if (!formData.doctor_id) {
            return swal.fire({
                title: 'Required field cannot be empty',
                text: 'Please select a doctor',
                icon: 'error',
                confirmButtonText: 'Ok',
                customClass: {
                    container: 'swal-z'
                }
            })
        }

        if (!formData.vaccination_date) {
            return swal.fire({
                title: 'Required field cannot be empty',
                text: 'Please fill in a vaccination date',
                icon: 'error',
                confirmButtonText: 'Ok',
                customClass: {
                    container: 'swal-z'
                }
            })
        }

        if (!formData.description) {
            return swal.fire({
                title: 'Required field cannot be empty',
                text: 'Please fill in a description',
                icon: 'error',
                confirmButtonText: 'Ok',
                customClass: {
                    container: 'swal-z'
                }
            })
        }

        try {
            let result = await Vaccination.createVaccination(formData);
            console.log(result);
            swal
                .fire({
                    title: 'Success',
                    text: 'Vaccination added successfully',
                    icon: 'success',
                    confirmButtonText: 'Go back',
                    customClass: {
                        container: 'swal-z'
                    }
                })
                .then(result => {
                    /* Read more about isConfirmed, isDenied below */
                    if (result.isConfirmed) {
                        history.push("/patient/" + patient_id);
                    }
                });
            setSelectedDoctor(null)
        } catch (e) {
            swal.fire({
                title: 'Error',
                text: 'An Error Occurred',
                icon: 'error',
                confirmButtonText: 'Ok',
                customClass: {
                    container: 'swal-z'
                }
            })
            console.log('error: ', e);
        }
    };
    return (
        <Card>
            <div className="card-header">
                <div className="mt-4 card-title">
          <span
              style={{
                  cursor: 'pointer'
              }}
          >
            <ArrowBackIosOutlined onClick={() => history.goBack()}></ArrowBackIosOutlined>
          </span>
                    <h3 className="ml-3 mt-3 mb-2">Vaccination Details</h3>
                </div>
            </div>
            <CardBody>
                <form onSubmit={handleSubmit}>
                    <Grid container spacing={2}>
                        {
                            (isAdmin) &&
                            <Grid item xs={12}>
                                <Autocomplete
                                    label="Doctor"
                                    value={selectedDoctor}
                                    onChange={(_, newValue) => {
                                        console.log("new val", newValue)
                                        setSelectedDoctor(newValue);
                                        //setBookingTypeSelection(newValue.booking_type_name.map(x=>{return {label:x.id,value:x}}))
                                    }}
                                    options={doctorSelection}
                                    getOptionLabel={option => (option ? `${option.label}` : '')}
                                    size="small"
                                    renderInput={params => <TextField
                                        label={"Doctor"}
                                        {...params} variant="outlined"/>}
                                />
                            </Grid>
                        }
                        <Grid item xs={12}>
                            <KeyboardDatePicker
                                value={values.vaccination_date || ''}
                                onChange={date => setFieldValue('vaccination_date', date, true)}
                                label="Vaccination Date"
                                helperText={touched.vaccination_date && errors.vaccination_date ? 'Invalid date' : ''}
                                error={touched.vaccination_date && errors.vaccination_date ? true : false}
                                clearable
                                format="dd/MM/yyyy"
                                InputLabelProps={{shrink: values.vaccination_date ? true : false}}
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <TextField
                                id="description"
                                value={values.description}
                                onChange={handleChange}
                                label="Vaccination Description"
                                helperText={touched.description && errors.description ? errors.description : ''}
                                error={touched.description && errors.description ? true : false}
                                multiline
                                rows={5}
                            />
                        </Grid>
                        <ActionBarAdd handleSubmit={handleSubmit}/>
                    </Grid>
                </form>
            </CardBody>
        </Card>
    );
};

export default VaccinationAddPage;
