import ApiRequest from '../../utils/APIRequest';

export default class Nametag {
  static getAll = async () => {
    let result = await ApiRequest.setV1userAPI('api/user/nametags', 'GET');
    return result.result
  };

  static create = async body => {
    return await ApiRequest.setV1userAPI('api/user/nametag', 'POST', body);
  };

  static edit = async (data) => {
    return await ApiRequest.setV1userAPI('api/user/nametag/update', 'PUT', data);
  };

  static getById = async (id) => {
    let result =  await ApiRequest.setV1userAPI('api/user/nametag/get-by-id/'+id, 'GET');
    return result.result
  };
}
