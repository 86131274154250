import { useSelector } from 'react-redux';
import { Grid } from '@material-ui/core';

import { TextField } from 'app/components/common/TextField';
import KeyboardDatePicker from 'app/components/common/KeyboardDatePicker';

const CustomerForm = ({ formik, isEditing }) => {
  const {
    values,
    touched,
    errors,
    handleChange,
    handleSubmit,
    setFieldValue
  } = formik;
  const { role } = useSelector(state => state.auth);

  return (
    <form onSubmit={handleSubmit}>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <TextField
            disabled={!isEditing}
            id="name"
            value={values.name}
            onChange={handleChange}
            label="Full Name *"
            helperText={touched?.name && errors?.name ? errors?.name : ''}
            error={touched?.name && errors?.name ? true : false}
          />
        </Grid>
        <Grid item xs={12}>
          { role !== 'DOCTOR' && 
            <TextField
            disabled={!isEditing}
            id="email"
            value={values.email}
            onChange={handleChange}
            label="Email"
            helperText={touched?.email && errors?.email ? errors?.email : ''}
            error={touched?.email && errors?.email ? true : false}
            />
          }
        </Grid>
        {(role === 'ADMIN' || role === 'CS') && (
          <Grid item xs={12}>
            <Grid container>
              <Grid item xs>
                <TextField
                  disabled={!isEditing}
                  id="phone"
                  value={values.phone}
                  onChange={handleChange}
                  label="Phone"
                  helperText={
                    touched?.phone && errors?.phone ? errors?.phone : ''
                  }
                  error={touched?.phone && errors?.phone ? true : false}
                />
              </Grid>
            </Grid>
          </Grid>
        )}
        <Grid item xs={12}>
          <KeyboardDatePicker
            disabled={!isEditing}
            id="dateOfBirth"
            value={values.dateOfBirth}
            onChange={date => setFieldValue('dateOfBirth', date, true)}
            label="Date of Birth"
            helperText={
              touched?.dateOfBirth && errors?.dateOfBirth ? 'Invalid date' : ''
            }
            error={touched?.dateOfBirth && errors?.dateOfBirth ? true : false}
            disableFuture
            clearable
            format="dd/MM/yyyy"
          />
        </Grid>
        <Grid item xs={12}>
          <TextField
            disabled={!isEditing}
            id="address"
            value={values.address}
            onChange={handleChange}
            label="address"
            helperText={
              touched?.address && errors?.address ? errors?.address : ''
            }
            error={touched?.address && errors?.address ? true : false}
          />
        </Grid>
      </Grid>
    </form>
  );
};

export default CustomerForm;
