import {
    Stepper,
    Step,
    StepLabel,
    makeStyles,
} from "@material-ui/core";
import NoteAddIcon from '@material-ui/icons/NoteAdd';
import AddAPhotoIcon from '@material-ui/icons/AddAPhoto';
import AddAlertIcon from '@material-ui/icons/AddAlert';
import clsx from 'clsx';
import { FaPaw, FaClipboardList } from "react-icons/fa6";
import { CiStethoscope } from "react-icons/ci";
import { TbChecklist } from "react-icons/tb";
import { useEffect, useState } from "react";
import {useLocation} from "react-router-dom"

const STEP_ROUTES = ["general-data", "pre-diagnose", "symptom-form", "result"]

function DiagnoseStepper() {

    const [activeStep, setActiveStep] = useState(0)
    const location = useLocation();

    useEffect(()=>{
        for(let stepIndex in STEP_ROUTES){
            let step = STEP_ROUTES[stepIndex]
            if(location.pathname.includes(step)){
                setActiveStep(stepIndex)
            }
        }
    },[location.pathname])

    const renderBadge=(index, Icon)=>{

    }

    return (
        <div
            className="d-flex flex-direction align-items-center justify-content-center" 
            style={{height : 50, background : "black", borderRadius : 50, paddingLeft : 10, paddingRight : 10}}
        >
            <div style={{background : "#3c77ea", padding : 7, borderRadius : 25, cursor : "pointer"}}>
                <FaPaw size={20} color='white' />
            </div>
            <div style={{height : 5, background : activeStep > 0 ? "white" : "#444444", width : 100, marginLeft : 20, marginRight : 20}}>

            </div>
            <div style={{background : activeStep > 0 ? "#3c77ea" : "#444444", padding : 7, borderRadius : 25, cursor : "pointer"}}>
                <TbChecklist size={20} color={activeStep > 0 ? "white" : "#666666"} />
            </div>

            <div style={{height : 5, background : activeStep > 1 ? "white" : "#444444", width : 100, marginLeft : 20, marginRight : 20}}></div>
            <div style={{background :activeStep > 1 ? "#3c77ea" : "#444444", padding : 7, borderRadius : 25, cursor : "pointer"}}>
                <CiStethoscope  size={20} color={activeStep > 1 ? "white" : "#666666"} />
            </div>

            <div style={{height : 5, background : activeStep > 2 ? "#white" : "#444444", width : 100, marginLeft : 20, marginRight : 20}}></div>
            <div style={{background : activeStep > 2 ? "#3c77ea" : "#444444", padding : 7, borderRadius : 25}}>
                <FaClipboardList  size={20} color={activeStep > 2 ? "white" : "#666666"} />
            </div>
        </div>
            // <Stepper
            //     style={{
            //         background:'#000',
            //         borderRadius:100
            //     }}
            //     activeStep={1}>
            //     <Step>
            //         <StepLabel StepIconComponent={CustomStepIcon}>First Step</StepLabel>
            //     </Step>
            //     <Step>
            //         <StepLabel StepIconComponent={CustomStepIcon}>Second Step</StepLabel>
            //     </Step>
            //     <Step>
            //         <StepLabel StepIconComponent={CustomStepIcon}>Third Step</StepLabel>
            //     </Step>
            // </Stepper>

    );
}

export default DiagnoseStepper;