import React, { useEffect, useRef, useState } from 'react';
import { Link, useHistory } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { Grid, IconButton, Button } from '@material-ui/core';
import { DatePicker, MuiPickersUtilsProvider } from '@material-ui/pickers';
import Clinic from 'app/models/Clinic';
import {
    Assignment as AssignmentIcon,
    Close as CloseIcon
} from '@material-ui/icons';
import { endOfWeek, format, parseISO } from 'date-fns';
import DateFnsUtils from '@date-io/date-fns';

import {
    Card,
    CardBody,
    CardHeader
} from '../../../_metronic/_partials/controls';
import ButtonLink from 'app/components/common/ButtonLink';
import { sortCaret } from '../../../_metronic/_helpers';
import { DataTableLocal as DataTable } from '../../components/common/DataTable';
import ApproveAppointmentDialog from './components/ApproveAppointmentDialog';
import { CardHeaderToolbar } from '../../../_metronic/_partials/controls';

import moment from "moment"

import qs from "qs"
import Appointment from "../../models/Appointment";
import AddAppointmentDialogV2 from "./components/AddAppointmentDialogV2";

import Select from "react-select"
import { Col, Container, Row, Spinner } from "react-bootstrap";

let contentTimer;

const startOfDay = (paramDate) => {
    let startOfDay = new moment(paramDate)
    return startOfDay.set({ hour: 0, minute: 0, second: 0 }).toDate()
}

const statusFilterOptions = [
    { label: "Done", value: "Done" },
    { label: "Need admin confirmation", value: "Need admin confirmation" },
    { label: "Confirmed", value: "Confirmed" },
    { label: "Waiting for Payment", value: "Waiting for Payment" },
    { label: "Expired", value: "Expired" },
    { label: "Cancelled", value: "Cancelled" },
    { label: "Created By Admin", value: "Created By Admin" },
]

const AppointmentListPageV2 = (props) => {

    const history = useHistory();
    const [clinicFilter, setClinicFilter] = useState([])
    const [clinicFilterOptions, setClinicFilterOptions] = useState([])

    const editSearchQueryValue = (value) => {
        clearTimeout(contentTimer);

        contentTimer = setTimeout(async () => {
            setSearchQuery(value)
            changeHistoryPush(value)
            // history.push(`/appointment?q=${value}&dateStart=${dateStart}&dateEnd=${dateEnd}`)
        }, 500);
    }

    const usePrevious = value => {
        const ref = useRef();
        useEffect(() => {
            ref.current = value;
        });
        return ref.current;
    };

    const [isLoading, setIsLoading] = useState(false)

    const [searchQuery, setSearchQuery] = useState("")
    const [searchRef, setSearchRef] = useState(null)
    const [defaultSearchValue, setDefaultSearchValue] = useState(null)
    const [data, setData] = useState([])
    const [isAddDialogOpen, setIsAddDialogOpen] = useState(false);
    const [statusFilter, setStatusFilter] = useState(null)

    const { data: keywords } = useSelector(
        state => state.appointment.approve
    );

    const isFetching = useSelector(state => state.loading.appointment);
    const { completed } = useSelector(state => state.appointment.ops);
    const previousCompleted = usePrevious(completed);
    const { completed: approveCompleted } = useSelector(
        state => state.appointment.approve
    );

    useEffect(() => {
        if (completed && completed !== previousCompleted) {
            fetchAppointmentConditionally();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [completed, previousCompleted]);

    useEffect(() => {
        approveCompleted && fetchAppointmentConditionally();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [approveCompleted]);

    const [startDate, setStartDate] = useState();
    const [endDate, setEndDate] = useState();
    const [appointmentType, setAppointmentType] = useState('');

    const onChangeStartDate = (newValue) => {
        setStartDate(newValue)
        changeHistoryPush(undefined, newValue, undefined)
    }

    const onChangeEndDate = (newValue) => {
        setEndDate(newValue)
        changeHistoryPush(undefined, undefined, newValue)
    }

    const changeHistoryWhenClearDateFilter = () => {
        history.push(`/appointment?q=${searchQuery}&startDate=null&endDate=null`)
    }

    const changeHistoryPush = (valueParams, startDateParams, endDateParams) => {

        let sd = moment(startDateParams ? startDateParams : startDate).format('yyyy-MM-DD HH:mm:ss')
        let ed = moment(endDateParams ? endDateParams : endDate).format('yyyy-MM-DD HH:mm:ss')

        let startDateFragment = null
        let endDateFragment = null

        if (startDate) {
            startDateFragment = `&startDate=${sd}`
        } else {
            startDateFragment = ""
        }

        if (endDate) {
            endDateFragment = `&endDate=${ed}`
        } else {
            endDateFragment = ""
        }

        setTimeout(() => {
            if (valueParams !== undefined) {
                history.push(`/appointment?q=${valueParams}${startDateFragment}${endDateFragment}`)
            } else {
                history.push(`/appointment?q=${searchQuery}${startDateFragment}${endDateFragment}`)
            }
        }, 200)

    }

    useEffect(() => {

        const queryParams = qs.parse(props.location.search, { ignoreQueryPrefix: true })
        if (queryParams.q) {
            setDefaultSearchValue(queryParams.q)
            setSearchQuery(queryParams.q)
        }

        setStartDate(startOfDay(new Date()));
        // setEndDate(addDays(startOfDay(new Date()), 1));
        setEndDate(endOfWeek(startOfDay(new Date()), { weekStartsOn: 1 }));

        // console.log("QP", queryParams.startDate, queryParams.endDate)
        if (queryParams.startDate) {
            if (queryParams.startDate === "null") {
                setStartDate(null)
            } else {
                setStartDate(new Date(queryParams.startDate))
            }
        }

        if (queryParams.endDate) {
            if (queryParams.endDate === "null") {
                setEndDate(null)
            } else {
                setEndDate(new Date(queryParams.endDate))
            }
        }


    }, []);

    const [filteredData, setFilteredData] = useState([]);
    useEffect(() => {
        setFilteredData(data);
    }, [data, appointmentType]);

    useEffect(() => {

        let dayDiff = moment(endDate).diff(startDate, "days")
        // console.log("ddiff", dayDiff)
        if (dayDiff >= 0) {
            fetchAppointmentConditionally();
        }

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [startDate, endDate]);

    useEffect(() => {
        fetchAppointmentConditionally();
    }, [])

    const fetchAppointmentConditionally = () => {

        console.log("APS FETCHING APPOINTMENT")

        if (startDate && endDate) {

            const formattedStartDate = moment(startDate).format('yyyy-MM-DD HH:mm:ss');
            const formattedEndDate = moment(endDate).format('yyyy-MM-DD HH:mm:ss');

            fetchAppointmentByDateRange(formattedStartDate, formattedEndDate)
        } else if (startDate === null && endDate === null) {
            fetchAppointment()
        }
    };

    const fetchAppointmentByDateRange = async (startDate, endDate) => {

        let appointmentModel = new Appointment()
        setIsLoading(true)

        if (moment(endDate).diff(startDate, "days") > 14) {
            try {
                let totalData = []
                let pointerDate = moment(startDate);
                while (pointerDate.isBefore(endDate)) {
                    let queryStart = pointerDate.format("YYYY-MM-DD HH:mm:ss")
                    let queryEnd;

                    if (moment(endDate).diff(pointerDate, "days") > 14) {
                        pointerDate.add(14, "days");
                        let tempDate = new moment(pointerDate)
                        tempDate.subtract(1, "seconds")
                        queryEnd = tempDate.format("YYYY-MM-DD HH:mm:ss")

                    } else {
                        pointerDate = moment(endDate)
                        queryEnd = pointerDate.format("YYYY-MM-DD HH:mm:ss")
                    }

                    let appointments = await appointmentModel.getAppointmentByDate(queryStart, queryEnd)
                    let formattdA = appointments.map(obj => {
                        return {
                            ...obj,
                            patient_stringify: JSON.stringify(obj.patients)
                        }
                    })
                    totalData.push(...formattdA)

                }

                setData(totalData)
                console.log("INI", totalData)
                setIsLoading(false)

            } catch (e) {
                setIsLoading(false)
                console.log(e)
            }
        } else {
            try {
                let appointments = await appointmentModel.getAppointmentByDate(startDate, endDate)
                console.log("APS", appointments)
                let formattdA = appointments.map(obj => {
                    return {
                        ...obj,
                        patient_stringify: JSON.stringify(obj.patients)
                    }
                })
                setData(formattdA)
                setIsLoading(false)
            } catch (e) {
                setIsLoading(false)
                console.log(e)
            }
        }

    }

    const fetchAppointment = async () => {

        let appointmentModel = new Appointment()

        console.log("APS FETCHING APPOINTMENT")

        try {

            let startDate = startOfDay(new Date())
            let endDate = endOfWeek(startOfDay(new Date()), { weekStartsOn: 1 })
            // setEndDate(addDays(startOfDay(new Date()), 1));

            let appointments = await appointmentModel.getAppointmentByDate(startDate, endDate)

            let formattedAppointments = appointments.map(row => {

                let formattedStatus = ""

                if (row.status === "DONE") {
                    formattedStatus = "Done"
                } else if (row.payment_status === "PAID") {
                    formattedStatus = "Need admin confirmation"
                } else if (row.payment_status === "PAID_CONFIRMED" || row.payment_status === "ADMIN_CONFIRMED") {
                    formattedStatus = "Confirmed"
                } else if (row.payment_status === "UNPAID") {
                    formattedStatus = "Waiting for Payment"
                } else if (row.payment_status === "EXPIRED") {
                    formattedStatus = "Expired"
                } else if (row.status === "CANCELLED") {
                    formattedStatus = "Cancelled"
                } else if (row.payment_status === "ADMIN_CREATED") {
                    formattedStatus = "Created By Admin"
                } else {
                    formattedStatus = `${row.status} | ${row.payment_status}`
                }

                return {
                    ...row,
                    formattedStatus: formattedStatus
                }
            })

            console.log("APS", formattedAppointments)

            setData(formattedAppointments)

        } catch (e) {
            console.log(e)
        }

    }


    useEffect(() => {
        fetchClinicList()
    }, [])

    const fetchClinicList = async () => {
        let clinicModel = new Clinic();
        try {
            let result = await clinicModel.getAllClinic();
            if (result?.length > 0) {
                setClinicFilterOptions(result?.map((value) => {
                    return {
                        label: value.name,
                        value: value.id
                    }
                }));
            }

        } catch (e) {
            console.log('ERROR' + e);
        }
    };

    const columns = [
        {
            dataField: 'start_time',
            text: 'time',
            formatter: (cell, row, rowIndex, formatExtraData) => {
                return row && `${format(parseISO(row.start_time), 'yyyy-MM-dd HH:mm')} - ${format(parseISO(row.end_time), 'HH:mm')}`;
            },
            sort: true,
            sortCaret: sortCaret
        },
        {
            dataField: 'clinic_name',
            text: 'Clinic',
        },
        {
            dataField: 'customer_name',
            text: 'customer',
            formatter: (cell, row) => {
                return row && <Link to={`/customer/${row?.customer_id}`}>{cell}</Link>;
            }
        },
        {
            dataField: 'patient_stringify',
            text: 'patient',
            formatter: (cell, row) => {
                let patients = row.patients
                return (row && patients) && <div>
                    {patients?.map((obj, key) => {
                        return `${obj.patient_name}${key < patients.length - 1 ? ", " : ""}`
                    })}
                </div>;
            }
        },
        {
            dataField: 'doctor_name',
            text: 'doctor'
        },
        {
            dataField: 'booking_type_name',
            text: 'type',
            formatter: (cell, row) => {
                return row.bookingType?.booking_type_name
            }
        },
        {
            dataField: 'status',
            text: 'status',
            formatter: (cell, row) => {

                let status = ""

                if (row.status === "DONE") {
                    status = "Done"
                } else if (row.payment_status === "PAID") {
                    status = "Need admin confirmation"
                } else if (row.payment_status === "PAID_CONFIRMED" || row.payment_status === "ADMIN_CONFIRMED") {
                    status = "Confirmed"
                } else if (row.payment_status === "UNPAID") {
                    status = "Waiting for Payment"
                } else if (row.payment_status === "EXPIRED") {
                    status = "Expired"
                } else if (row.status === "CANCELLED") {
                    status = "Cancelled"
                } else if (row.payment_status === "ADMIN_CREATED") {
                    status = "Created By Admin"
                } else {
                    status = `${row.status} | ${row.payment_status}`
                }

                return row && <>
                    {status}
                </>
            }
        }
    ];

    const [isApproveDialogOpen, setIsApproveDialogOpen] = useState(false);
    const [selectedAppointment, setSelectedAppointment] = useState(undefined);

    const handleClearDateClick = () => {
        setStartDate(null);
        setEndDate(null);
        changeHistoryWhenClearDateFilter()
    };

    let dayDiff = moment(endDate).diff(startDate, "days")

    let filteredByStatus = (statusFilter && statusFilter.length > 0) ? filteredData.filter((obj) => {
        for (let s of statusFilter) {
            console.log(s.value + " vs " + obj.formattedStatus)
            if (s.value === obj.formattedStatus) {
                return true;
            }
        }
    }) : filteredData

    if (clinicFilter?.length > 0) {
        filteredByStatus = filteredByStatus?.filter((obj) => {
            for (let s of clinicFilter) {
                if (s.value === obj.clinic_id) {
                    return true;
                }
            }
        })
    }
        

    // console.log('isi data',filteredData)
    return (
        <>
            <Card>
                <CardHeader title="Appointment list">
                    <CardHeaderToolbar>
                        <ButtonLink to="/appointmentType" style={{ marginRight: '1rem' }}>
                            Manage Type
                        </ButtonLink>
                        {/*<Button onClick={() => setIsAddDialogOpen(true)} variant="contained" color="primary">*/}
                        {/*  New Appointment*/}
                        {/*</Button>*/}
                    </CardHeaderToolbar>

                </CardHeader>
                <CardBody>
                    <div style={{ width: "100%" }}>
                        <Row>
                            <Col md={12} style={{
                                display: "flex", flexDirection: "row",
                                alignItems: "center"
                            }}>
                                <MuiPickersUtilsProvider utils={DateFnsUtils}>
                                    <DatePicker
                                        value={startDate}
                                        onChange={onChangeStartDate}
                                        label="Start date"
                                        variant="inline"
                                        inputVariant="outlined"
                                        size="small"
                                        style={{
                                            width: '160px'
                                        }}
                                    />
                                </MuiPickersUtilsProvider>
                                <Grid
                                    item
                                    style={{ marginLeft: '0.25rem', marginRight: '0.25rem' }}
                                >
                                    <span> - </span>
                                </Grid>
                                <Grid item>
                                    <MuiPickersUtilsProvider utils={DateFnsUtils}>
                                        <DatePicker
                                            disabled={!startDate}
                                            value={endDate}
                                            onChange={onChangeEndDate}
                                            label="End date"
                                            variant="inline"
                                            inputVariant="outlined"
                                            size="small"
                                            style={{
                                                width: '160px'
                                            }}
                                        />
                                    </MuiPickersUtilsProvider>
                                </Grid>
                                <Grid item>
                                    {
                                        isLoading ?
                                            <>
                                                &nbsp;<Spinner size={"sm"} animation="border" />
                                            </>
                                            :
                                            <IconButton onClick={handleClearDateClick} size="small">
                                                <CloseIcon />
                                            </IconButton>
                                    }
                                </Grid>
                                <Grid item style={{ color: "red" }}>
                                    {
                                        dayDiff < 0 && "End date should be higher dan start date"
                                    }
                                </Grid>
                            </Col>

                        </Row>
                        <Row style={{ width: "100%", }}>
                            <Col md={6} item>
                                <small className='d-block mt-3 mb-4'>Status : </small>
                                <Select
                                    name="filters"
                                    placeholder="Status"
                                    value={statusFilter}
                                    options={statusFilterOptions}
                                    onChange={(value) => {
                                        setStatusFilter(value)
                                    }}
                                    isMulti
                                />
                            </Col>
                            <Col md={4} item>
                                <small className='d-block mt-3 mb-4'>Clinic : </small>
                                <Select
                                    name="filters"
                                    placeholder="Clinic"
                                    value={clinicFilter}
                                    options={clinicFilterOptions}
                                    onChange={(v) => {
                                        setClinicFilter(v)
                                    }}
                                    isMulti
                                />
                            </Col>
                            <Col style={{ marginTop: 10 }} md={12}>
                                <small>Keyword : </small>
                            </Col>
                        </Row>
                    </div>
                    <DataTable
                        defaultSearchValue={defaultSearchValue}
                        data={filteredByStatus}
                        loading={isFetching}
                        totalDataCount={filteredByStatus.length}
                        columns={columns}
                        editLink="/appointment/"
                        handleSearchChange={(value) => {
                            editSearchQueryValue(value)
                            // console.log("boi", value)
                        }}
                        setSearchRef={setSearchRef}
                    // renderCustomActions={row => {
                    //     return (
                    //         <Tooltip title="Approve">
                    //             <IconButton
                    //                 onClick={() => {
                    //                     setSelectedAppointment({...row});
                    //                     setIsApproveDialogOpen(true);
                    //                 }}
                    //                 size="small"
                    //             >
                    //                 <AssignmentIcon/>
                    //             </IconButton>
                    //         </Tooltip>
                    //     );
                    // }}
                    />
                </CardBody>
            </Card>
            <ApproveAppointmentDialog
                open={isApproveDialogOpen}
                handleClose={() => setIsApproveDialogOpen(false)}
                data={selectedAppointment}
            />
            <AddAppointmentDialogV2
                isOpen={isAddDialogOpen}
                handleClose={(refresh) => {
                    setIsAddDialogOpen(false);
                    if (refresh) {
                        fetchAppointmentConditionally()
                    }
                }}
            //   doctorID={id}
            //   data={selectedAppointmentSlot}
            />
        </>
    );
};

export default AppointmentListPageV2;
