import api from '../../api/userAPI';
import { setErrorNotification } from '../snackbarNotification';

export const FETCH_BEGIN = 'medicalRecord.list/FETCH_BEGIN';
export const FETCH_SUCCESS = 'medicalRecord.list/FETCH_SUCCESS';
export const FETCH_FAILED = 'medicalRecord.list/FETCH_FAILED';

const fetchBegin = () => ({ type: FETCH_BEGIN });
const fetchSuccess = data => ({ type: FETCH_SUCCESS, payload: data });
const fetchFailed = error => ({ type: FETCH_FAILED, payload: error });
export const fetchMedicalRecordListByPatientID = patientID => async dispatch => {
  dispatch(fetchBegin());

  try {
    const res = await api(`retrieve-medical-record?patient_id=${patientID}`);

    dispatch(fetchSuccess(res.data?.result || []));
  } catch (error) {
    dispatch(fetchFailed(error.toString()));
    dispatch(setErrorNotification(error.toString()));
  }
};

export const fetchMedicalRecordListByAppointmentID = appointmentID => async dispatch => {
  dispatch(fetchBegin());

  try {
    const res = await api(
      `retrieve-medical-record?appointment_id=${appointmentID}`
    );

    dispatch(fetchSuccess(res.data?.result || []));
  } catch (error) {
    dispatch(fetchFailed(error.toString()));
    dispatch(setErrorNotification(error.toString()));
  }
};

const reducer = (state = initialState, action) => {
  const { type, payload } = action;

  switch (type) {
    case FETCH_BEGIN:
      return { ...state, data: [], isLoading: true, error: null };
    case FETCH_SUCCESS:
      return { ...state, data: payload, isLoading: false };
    case FETCH_FAILED:
      return { ...state, isLoading: false, error: payload };

    default:
      return state;
  }
};

export default reducer;

const initialState = {
  data: [],
  isLoading: false,
  error: null
};
