import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Grid, MenuItem } from '@material-ui/core';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import swal from 'sweetalert2';
import { useHistory, useParams } from 'react-router-dom';
import KeyboardDatePicker from 'app/components/common/KeyboardDatePicker';
import { fetchAppointment } from 'redux/appointment/detail';
import { ArrowBackIosOutlined } from '@material-ui/icons';
import Reminder from 'app/models/Reminder';
import TimePicker from 'app/components/common/TimePicker';
import moment from 'moment';

import { Card, CardBody } from '../../../_metronic/_partials/controls';
import { TextField } from '../../components/common/TextField';
import { ActionBarEdit } from '../../components/common/ActionBar';

const ReminderEditPage = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const { id, reminderId } = useParams();

  const [reminderData, setReminderData] = useState({});
  const [startTime, setStartTime] = useState(null);

  const getReminder = async () => {
    let reminderModel = new Reminder();

    try {
      let result = await reminderModel.getById(reminderId);
      setReminderData(result);
    } catch (e) {
      console.log(e);
    }
  };

  const editReminder = async values => {
    let reminderModel = new Reminder();

    try {
      let result = await reminderModel.edit(reminderId, values);
      swal.fire({
        title: 'Success',
        text: 'Reminder edited successfully',
        icon: 'success',
        confirmButtonText: 'Go back',
        customClass: {
          container: 'swal-z'
        }
      }).then(result => {
        /* Read more about isConfirmed, isDenied below */
        if (result.isConfirmed) {
          history.goBack();
        }
      });
      resetForm();
    } catch (e) {
      console.log(e);
    }
  };

  const renderTimeInput = props => {
    return (
      <TextField
        disabled={props.disabled}
        value={`${props.value}`}
        onChange={props.onChange}
        onClick={props.onClick}
        label={props.label}
      />
    );
  };

  const { data } = useSelector(state => state.appointment.detail);

  useEffect(() => {
    dispatch(fetchAppointment(id));
    getReminder();
    console.log(data);
  }, []);

  const { values, errors, touched, handleChange, handleSubmit, handleReset, setFieldValue, resetForm } = useFormik({
    initialValues: {
      patient_id: reminderData.patient_id,
      title: reminderData.title,
      description: reminderData.description,
      notified_at: reminderData.notified_at || null,
      owner: reminderData.customer_name,
      email: reminderData.customer_email,
      time: reminderData.notified_at || null,
    },
    validationSchema: Yup.object({
      title: Yup.string().required('Cannot be empty'),
      description: Yup.string().required('Cannot be empty'),
      notified_at: Yup.string().required('Cannot be empty')
    }),
    onSubmit: values => {
      const formatDate = new moment(values.notified_at).set({
        hour: new Date(values.time).getHours(),
        minute: new Date(values.time).getMinutes()
      });
      const formattedValues = {
        appointment_user_id: id,
        user_id: reminderData.customer_id,
        patient_id: values.patient_id ? values.patient_id : reminderData.patient_id,
        doctor_id: reminderData.doctor_id,
        title: values.title ? values.title : reminderData.title,
        description: values.description ? values.description : reminderData.description,
        notified_at: formatDate ? formatDate.toDate() : reminderData.notified_at
      };

      editReminder(formattedValues);
    },
    enableReinitialize: 'true'
  });

  return (
    <Card>
      <div className="card-header">
        <div className="mt-4 card-title">
          <span
            style={{
              cursor: 'pointer'
            }}
          >
            <ArrowBackIosOutlined onClick={() => history.goBack()}></ArrowBackIosOutlined>
          </span>
          <h3 className="ml-3 mt-3 mb-2">Edit Reminder</h3>
        </div>
      </div>
      <CardBody>
        <form onSubmit={handleSubmit}>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <TextField
                id="owner"
                value={data?.owner?.user_name}
                onChange={handleChange}
                label="Owner"
                disabled={true}
              />
            </Grid>
            <Grid item xs={12}>
              <KeyboardDatePicker
                value={values.notified_at ? values.notified_at : ''}
                onChange={date => setFieldValue('notified_at', new Date(date), true)}
                label="Notify at *"
                helperText={touched.notified_at && errors.notified_at ? 'Invalid date' : ''}
                error={touched.notified_at && errors.notified_at ? true : false}
                clearable
                format="dd/MM/yyyy"
                InputLabelProps={{ shrink: values.notified_at ? true : false }}
              />
            </Grid>
            <Grid item xs={12}>
              Reminder time
              <TimePicker
                value={values.time || ''}
                onChange={date => setFieldValue('time', date, true)}
                clearable
                TextFieldComponent={renderTimeInput}
                InputLabelProps={{ shrink: values.notified_at ? true : false }}
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                id="patient_id"
                select
                value={values.patient_id || ''}
                onChange={event => {
                  setFieldValue('patient_id', event.target.value);
                }}
                label="Pick Patient *"
              >
                {data &&
                  data.patients &&
                  data.patients.map((obj, key) => (
                    <MenuItem key={obj.id} value={obj.id}>
                      {`${obj.patient_name}`}
                    </MenuItem>
                  ))}
              </TextField>
            </Grid>
            <Grid item xs={12}>
              <TextField
                id="title"
                value={values.title}
                onChange={handleChange}
                label="title *"
                helperText={touched.title && errors.title ? errors.title : ''}
                error={touched.title && errors.title ? true : false}
                type="title"
                InputLabelProps={{ shrink: !!values.title }}
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                id="description"
                value={values.description}
                onChange={handleChange}
                label="description"
                helperText={touched.description && errors.description ? errors.description : ''}
                error={touched.description && errors.description ? true : false}
                type="text"
                InputLabelProps={{ shrink: !!values.description }}
              />
            </Grid>
            <ActionBarEdit isEditing={true} handleSubmit={handleSubmit} handleReset={handleReset} />
          </Grid>
        </form>
      </CardBody>
    </Card>
  );
};

export default ReminderEditPage;
