import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  Card,
  CardBody,
  CardHeader
} from '../../../_metronic/_partials/controls';

import { ListHeaderToolbar } from '../../components/common/HeaderToolbar';
import { DataTableLocal as DataTable } from '../../components/common/DataTable';
import { fetchMedicineList } from '../../../redux/medicine/list';

export default function SymptomListPage() {
  // Prereq Hooks
  const dispatch = useDispatch();

  // Initial fetch
  useEffect(() => {
    dispatch(fetchMedicineList());
  }, []);

  const { data } = useSelector(state => state.medicine.list);
  const isFetching = useSelector(state => state.loading.medicine);

  const columns = [
    {
      dataField: 'medicine_name',
      text: 'Name'
    },
    {
      dataField: 'side_effect',
      text: 'Side Effects',
      formatter: cell => cell.replace(/\n/g, ', '),
      style: {
        textOverflow: 'ellipsis',
        maxWidth: '100px',
        whiteSpace: 'nowrap',
        overflow: 'hidden'
      }
    },
    {
      dataField: 'dosage_info',
      text: 'Dosage Info',
      style: {
        textOverflow: 'ellipsis',
        maxWidth: '100px',
        whiteSpace: 'nowrap',
        overflow: 'hidden'
      }
    }
  ];

  return (
    <Card>
      <CardHeader title="Medicine list">
        <ListHeaderToolbar addLink="/medicine/add" addText="New Medicine" />
      </CardHeader>
      <CardBody>
        <DataTable
          data={data}
          loading={isFetching}
          totalDataCount={data.length}
          columns={columns}
          editLink="/medicine/"
        />
      </CardBody>
    </Card>
  );
}
