import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions
} from '@material-ui/core';

import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(theme => ({
  container: {
    backgroundColor: 'rgba(0, 0, 0, 0.25)'
  },
  paper: {
    width: '800px',
    maxWidth: '800px'
  },
  title: {
    marginBottom: '1rem',
    padding: '2rem 2rem 1rem 2rem',
    borderBottom: `1px solid ${theme.palette.divider}`
  },
  actionContainer: {
    justifyContent: 'flex-start',
    marginTop: '1rem',
    padding: '1.5rem 2rem 1.5rem 2rem',
    borderTop: `1px solid ${theme.palette.divider}`
  }
}));

const CustomDialog = ({ open, handleClose, title, content, action }) => {
  const classes = useStyles();

  return (
    <Dialog
      open={open}
      onClose={handleClose}
      classes={{ container: classes.container, paper: classes.paper }}
    >
      <DialogTitle className={classes.title}>{title}</DialogTitle>
      <DialogContent>{content}</DialogContent>
      <DialogActions className={classes.actionContainer}>
        {action}
      </DialogActions>
    </Dialog>
  );
};

export default CustomDialog;
