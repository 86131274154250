import React, { useEffect, useState } from 'react';
import { useHistory, useParams, Link } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { Link as LinkIcon, CloudUploadOutlined, ArrowBackIosOutlined, DeleteTwoTone } from '@material-ui/icons';
import { Button, Grid, IconButton, Modal } from '@material-ui/core';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import { Row, Col } from 'react-bootstrap';

import { DataTableLocal as DataTable } from '../../components/common/DataTable';
import { Card, CardBody, CardHeaderToolbar, CardHeaderTitle } from '../../../_metronic/_partials/controls';
import { TextField } from '../../components/common/TextField';
import { ActionBarEdit } from '../../components/common/ActionBar';

import swal from 'sweetalert2';
import File from 'app/models/File';
import MedicalRecord from 'app/models/MedicalRecord';
import AppointmentModel from "../../../model/AppointmentModel";

const MedicalRecordEditPageV2 = () => {
  const history = useHistory();
  const { id } = useParams();

  const [imageWithDesc, setImageWithDesc] = useState([]);
  const [openImgModal, setOpenImgModal] = useState(false);

  const { data } = useSelector(state => state.appointment.detail);
  const [medicalRecordData, setMedicalRecordData] = useState({});

  const getMedicalRecord = async id => {
    let medicalRecordModel = new MedicalRecord();

    try {
      let result = await medicalRecordModel.getMedicalRecordById(id);
      console.log(result);
      setMedicalRecordData(result);
      setImageWithDesc(result.attachments ? result.attachments : []);
    } catch (e) {
      console.log(e);
    }
  };

  const handleDelete = async () =>{
    try{
      let result = await AppointmentModel.deleteMedicalRecord(id)

      let swalResult = await swal.fire({
        title: 'Success',
        text: 'Appointment is Deleted',
        icon: 'success',
        confirmButtonText: 'Ok',
        customClass: {
          container: 'swal-z'
        }
      })

      history.push("/appointment/"+medicalRecordData.appointment_user_id)

    }catch (e){
      console.log(e)
      let swalResult = await swal.fire({
        title: 'Error',
        text: 'An Error Occured',
        icon: 'error',
        confirmButtonText: 'Ok',
        customClass: {
          container: 'swal-z'
        }
      })
    }
  }

  const submitEdit = async (values) => {
    let medicalRecordModel = new MedicalRecord();

    let formData = {
      description: values.description,
      medication: values.medication,
      attachments: imageWithDesc
    };

    try {
      let result = await medicalRecordModel.edit(id, formData);
      console.log(result);

      swal.fire({
        title: 'Success',
        text: 'Medical Record edited successfully',
        icon: 'success',
        confirmButtonText: 'Go back',
        customClass: {
          container: 'swal-z'
        }
      });
      // handleReset();
    } catch (e) {
      console.log(e);
    }
  };
  const { values, errors, touched, handleChange, handleSubmit, handleReset, setFieldValue } = useFormik({
    initialValues: {
      description: medicalRecordData?.description || '',
      medication: medicalRecordData?.medication || '',
      medicine: medicalRecordData?.medicine || '',
      attachment: medicalRecordData?.attachments || null,
      imageDesc: '',
      imageWithDesc: medicalRecordData?.attachments || null
    },
    validationSchema: Yup.object({
      description: Yup.string(),
      medication: Yup.string(),
      medicine: Yup.string()
    }),
    onSubmit: formValues => {
      submitEdit(formValues);
    },
    enableReinitialize: 'true'
  });

  useEffect(() => {
    getMedicalRecord(id);
  }, []);

  const deleteImageWithDesc = (row) => {
    console.log(row)
    setImageWithDesc(imageWithDesc.filter((image) => image.media_url !== row.media_url))
  }

  const pushImageAndDesc = async () => {
    let uploadModel = new File();
    let mediaUrl;

    try {
      mediaUrl = await uploadModel.uploadFile(values.attachment);
      console.log(mediaUrl);
      setImageWithDesc([
        ...imageWithDesc,
        {
          media_url: mediaUrl.location,
          description: values.imageDesc
        }
      ]);
      setFieldValue('attachment', null);
      setFieldValue('imageDesc', '');
      setOpenImgModal(false);
    } catch (e) {
      console.log('error: ', e);
    }
  };

  return (
    <Card>
      <div className="card-header">
        <div className="mt-4 card-title">
          <span
            style={{
              cursor: 'pointer'
            }}
          >
            <ArrowBackIosOutlined onClick={() => history.goBack()}></ArrowBackIosOutlined>
          </span>
          <h3 className="ml-3 mt-3 mb-2">Medical Record Details</h3>
        </div>
        <CardHeaderToolbar>
          <Grid item>
            Related Appointment
            <Link to={`/appointment/${medicalRecordData.appointment_user_id}`}>
              <IconButton size="small" color="primary">
                <LinkIcon />
              </IconButton>
            </Link>
          </Grid>
        </CardHeaderToolbar>
      </div>
      <CardBody>
        <form onSubmit={handleSubmit}>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <TextField
                id="description"
                value={values.description}
                onChange={handleChange}
                label="Description (Catatan internal)"
                helperText={touched.description && errors.description ? errors.description : ''}
                error={touched.description && errors.description ? true : false}
                multiline
                rows={5}
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                id="medication"
                value={values.medication}
                onChange={handleChange}
                label="Medication (Catatan untuk pasien)"
                helperText={touched.medication && errors.medication ? errors.medication : ''}
                error={touched.medication && errors.medication ? true : false}
                multiline
                rows={5}
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                id="medicine"
                value={values.medicine}
                onChange={handleChange}
                label="Medicine (Nama Obat)"
                helperText={touched.medicine && errors.medicine ? errors.medicine : ''}
                error={touched.medicine && errors.medicine ? true : false}
                multiline
                rows={3}
              />
              <Button
                color="primary"
                variant="outlined"
                component="span"
                onClick={() => setOpenImgModal(true)}
                className="mt-5"
              >
                Add Media
              </Button>
            </Grid>
            <Modal
              open={openImgModal}
              onClose={() => setOpenImgModal(false)}
              style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}
            >
              <Card style={{ minWidth: '600px' }}>
                <CardHeaderTitle className="mt-8 pl-9">Add Attachment</CardHeaderTitle>
                <hr></hr>
                <CardBody className="pt-0">
                  <Row>
                    <Col>
                      <input
                        id="attachment"
                        onChange={event => {
                          setFieldValue('attachment', event.currentTarget.files[0]);
                        }}
                        accept=".jpg, .png, .jpeg, .gif, .mp4"
                        style={{ display: 'none' }}
                        multiple
                        type="file"
                      />
                    </Col>
                  </Row>
                  <Row>
                    <Col>
                      <label htmlFor="attachment">
                        <Button color="primary" variant="outlined" component="span" className="w-100">
                          Upload Media <CloudUploadOutlined />
                        </Button>
                        <span style={{ marginLeft: '1rem' }}>{values.attachment?.name || ''}</span>
                      </label>
                      <TextField
                        id="imageDesc"
                        value={values.imageDesc}
                        onChange={handleChange}
                        label="Image Description"
                        helperText={touched.imageDesc && errors.imageDesc ? errors.imageDesc : ''}
                        error={touched.imageDesc && errors.imageDesc ? true : false}
                        multiline
                        rows={3}
                      />
                    </Col>
                  </Row>
                  <Row item className="mt-10">
                    <Col>
                      <span>*Please add image before clicking</span>
                      <br></br>
                      <Button color="primary" variant="outlined" component="span" onClick={pushImageAndDesc}>
                        Add Attachment and Desc
                      </Button>
                    </Col>
                  </Row>
                </CardBody>
              </Card>
            </Modal>
            {imageWithDesc.length > 0 && (
              <Grid item xs={12} className="w-100">
                <DataTable
                  data={imageWithDesc}
                  totalDataCount={imageWithDesc.length}
                  columns={[
                    {
                      text: 'Media',
                      dataField: 'media_url',
                      formatter: (cell, row, rowIndex) => {
                        let content = row.media_url.includes('.mp4') ? (
                          <video width="300" height="300" controls>
                            <source src={row.media_url} type="video/mp4" />
                            Your browser does not support the video tag.
                          </video>
                        ) : (
                          <img src={row.media_url} width={'300px'} alt={row.description}></img>
                        );

                        return content;
                      }
                    },
                    {
                      text: 'Description',
                      dataField: 'description'
                    }
                  ]}
                  noEditAction={true}
                  noSearchAction={true}
                  style={{ width: '100%' }}
                  renderCustomActions={(_, row) => {
                    const handleDelete = () =>{
                      deleteImageWithDesc(row)
                    }
                    return (
                      <IconButton
                      size="small"
                      onClick={handleDelete}
                  >
                      <DeleteTwoTone />
                  </IconButton>
                  );

                }}
                />
              </Grid>
            )}
            <ActionBarEdit
                handleDelete={()=>{
                  handleDelete()
                }}
                isEditing={true} handleSubmit={handleSubmit} handleReset={handleReset} />
          </Grid>
        </form>
      </CardBody>
    </Card>
  );
};

export default MedicalRecordEditPageV2;
