import React from "react";
import { createMuiTheme, ThemeProvider } from "@material-ui/core";
import { blue, red } from "@material-ui/core/colors";

const theme = createMuiTheme(
  /**
   * @see https://material-ui.com/customization/themes/#theme-configuration-variables
   */
  {
    typography: {
      fontFamily: ["Poppins"].join(",")
    },

    palette: {
      // primary: {
      //   // light: will be calculated from palette.primary.main,
      //   main: "#17c191",
      //   // dark: will be calculated from palette.primary.main,
      //   // contrastText: "#fff" //will be calculated to contrast with palette.primary.main
      // },
      primary: blue,
      secondary: red,
      error: red,
    },

    /**
     * @see https://material-ui.com/customization/globals/#default-props
     */
    props: {
      // Name of the component ⚛️
      MuiButtonBase: {
        // The properties to apply
        disableRipple: false // No more ripple, on the whole application 💣!
      },

      MuiButton: {
        disableElevation: true, 
      },

      // Set default elevation to 1 for popovers.
      MuiPopover: {
        elevation: 1
      }
    },

    overrides: {
      MuiInput: {
        underline: {
          // '&:hover:not($disabled):after': {
          //   borderBottom: '1px solid red !important',
          // },
          '&:hover:not($disabled):before': {
            borderBottom: `1px solid ${blue[400]} !important`,         
          },
        },
      },
    },
  }
);

export function MaterialThemeProvider(props) {
  const { children } = props;

  return <ThemeProvider theme={theme}>{children}</ThemeProvider>;
}
