import { Grid, CircularProgress } from '@material-ui/core';

import { Card } from '_metronic/_partials/controls';

const LoadingCard = () => {
  return (
    <Card style={{ padding: '2rem' }}>
      <Grid container justify="center">
        <CircularProgress />
      </Grid>
    </Card>
  );
};

export default LoadingCard;
