import axios from '../../api/userAPI';
import axiosNEO from '../../api/userAPINEO';
import { setErrorNotification } from '../snackbarNotification';

export const FETCH_BEGIN = 'appointmentType.list/FETCH_BEGIN';
export const FETCH_SUCCESS = 'appointmentType.list/FETCH_SUCCESS';
export const FETCH_FAILED = 'appointmentType.list/FETCH_FAILED';

const fetchBegin = () => ({ type: FETCH_BEGIN });
const fetchSuccess = data => ({ type: FETCH_SUCCESS, payload: data });
const fetchFailed = error => ({ type: FETCH_FAILED, payload: error });
export const fetchAppointmentTypeList = () => async dispatch => {
  dispatch(fetchBegin());

  try {
    const res = await axios('retrieve-booking-types');

    dispatch(fetchSuccess(res.data.result));
  } catch (error) {
    dispatch(fetchFailed(error.toString()));
    dispatch(setErrorNotification(error.toString()));
  }
};

export const fetchAppointmentTypeListNEO = () => async dispatch => {
  dispatch(fetchBegin());

  try {
    const res = await axiosNEO('v1/bookingtypes');

    dispatch(fetchSuccess(res.data));
  } catch (error) {
    dispatch(fetchFailed(error.toString()));
    dispatch(setErrorNotification(error.toString()));
  }
};

const initialState = {
  data: [],
  isFetching: false,
  error: null
};

const appointmentTypeListReducer = (state = initialState, action) => {
  const { type, payload } = action;

  switch (type) {
    case FETCH_BEGIN:
      return { ...state, data: [], isFetching: true, error: null };
    case FETCH_SUCCESS:
      return { ...state, data: payload, isFetching: false };
    case FETCH_FAILED:
      return { ...state, isFetching: false, error: payload };

    default:
      return state;
  }
};

export default appointmentTypeListReducer;
