//LIST
import {useEffect, useState} from 'react';
import {useHistory, Link} from 'react-router-dom';
import {useDispatch, useSelector} from 'react-redux';
import {IconButton, Tooltip} from '@material-ui/core';
import {Assignment as AssignmentIcon} from '@material-ui/icons';
import {Card, CardBody, CardHeader} from '../../../../_metronic/_partials/controls';
import {DataTableLocal as DataTable} from '../../../components/common/DataTable';
import Nametag from 'app/models/Nametag';
import Button from '@material-ui/core/Button';
import Swal from 'sweetalert2';

const NametagListPage = () => {
  let [nametags, setNametags] = useState([]);
  let [loading, setLoading] = useState(false)

  const fetchAll = async () => {

    setLoading(true)

    try {
      let result = await Nametag.getAll();
      console.log(result);
      setNametags(result);

    } catch (e) {
      console.log('ERROR' + e);
    }

    setLoading(false)
  };

  async function create() {

    try {
      let x = await Swal.fire({
        title: 'Input the number of nametag you want to create',
        text: "",
        input: 'text',
        confirmButtonText: 'Ok',
        customClass: {
          container: 'swal-z'
        }
      })

      if (isNaN(parseInt(x.value))) return

      let result = await Nametag.create({num: x.value})

      if (result.success) {
        Swal.fire({
          title: "Success",
          text: `${x.value} name tag created successfully`,
          icon: 'success',
        })
        fetchAll()
      } else {
        Swal.fire({
          title: 'Error',
          text: "An Error Occured",
          icon: 'error',
          confirmButtonText: 'Ok',
          customClass: {
            container: 'swal-z'
          }
        });

      }

    } catch (e) {
      Swal.fire({
        title: 'Error',
        text: e.error_message ? e.error_message : "An Error Occured",
        icon: 'error',
        confirmButtonText: 'Ok',
        customClass: {
          container: 'swal-z'
        }
      });
    }

  }

  useEffect(() => {
    fetchAll()
  }, []);

  const columns = [
    {
      text: 'ID',
      dataField: 'id'
    },
    {
      text: 'Tag',
      dataField: 'tag'
    },
    {
      text: 'Pet ID',
      dataField: 'pet_id',
      formatter: (cell, row) => {
        return row.pet_id ? <><Link to={`/patient/${cell}`}>{cell}</Link></> : '-'
      },
    },
    {
      text: 'Pet name',
      dataField: 'patient_name',
      formatter: (cell, row) => {
        return cell ? cell : '-'
      }
    },
    {
      text: 'Link',
      dataField: 'tag',
      formatter: (cell, row) => {
        return cell ? <a target="_blank" href={`https://www.klinikhewanrajanti.com/#/pages/nametag/`+cell}>
          {`https://www.klinikhewanrajanti.com/#/pages/nametag/`+cell}
        </a> : '-'
      }
    }
  ];

  return (
      <>
        <Card>
          <CardHeader title="Nametag">
            <Button variant="contained" color="primary" size="small" style={{height: 30, marginTop: 20}}
                    onClick={create}
            >Create nametag</Button>
          </CardHeader>
          <CardBody>
            <DataTable
                loading={loading}
                data={nametags}
                totalDataCount={nametags.length}
                columns={columns}
                editLink='/nametag/edit/'
            />
          </CardBody>
        </Card>
      </>
  );
};

export default NametagListPage;
