import React from 'react';
import { Button, Grid } from '@material-ui/core';
import DeleteOutlineIcon from '@material-ui/icons/DeleteOutline';

import ActionBar from './ActionBar';
import { useStyles } from './ActionBarCss';

const ActionBarEdit = ({
  isEditing,
  isFetching,
  handleSubmit,
  handleDelete,
  isDeleteButtonShown = true
}) => {
  const classes = useStyles();

  if (!isEditing) return null;

  return (
    <ActionBar isFetching={isFetching}>
      <Grid item>
        <Button onClick={handleSubmit} variant="contained" color="primary">
          Save
        </Button>
      </Grid>
      {isDeleteButtonShown && (
        <Grid item>
          <Button
            onClick={handleDelete}
            variant="outlined"
            color="secondary"
            startIcon={<DeleteOutlineIcon />}
            classes={{ root: classes.deleteButton }}
          >
            Delete
          </Button>
        </Grid>
      )}
    </ActionBar>
  );
};

export default ActionBarEdit;
