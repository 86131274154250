import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { Button, Grid, MenuItem } from '@material-ui/core';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import { Card, CardBody, CardHeader } from '_metronic/_partials/controls';
import TextField from 'app/components/common/TextField';
import { ActionBarAdd } from 'app/components/common/ActionBar';
import { ArrowBackIosOutlined } from '@material-ui/icons';
import Clinic from 'app/models/Clinic';
import File from 'app/models/File';
import swal from 'sweetalert2';
import { useHistory, useParams } from 'react-router-dom';

const ClinicAddPage = ({ data, isEditing = true, headerToolbar, mode }) => {
  const { completed } = useSelector(state => state.medicalRecord.add);
  let history = useHistory();
  const { id } = useParams();

  const [isEditMode, setEditMode] = useState(false);
  const [clinicData, setClinicData] = useState(null);

  useEffect(() => {
    if (completed) {
      resetForm();
    }
    setEditMode(history.location.pathname.includes('edit'));
    console.log(history.location.pathname.includes('edit'));
  }, [completed, resetForm]);

  useEffect(() => {
    if (isEditMode) {
      getClinicDetails(id);
    }
  }, []);

  const { values, handleChange, handleSubmit, handleReset, setFieldValue, resetForm } = useFormik({
    initialValues: {
      clinicName: clinicData?.name || '',
      address: clinicData?.address || '',
      clinicType: clinicData?.type || null,
      attachment: clinicData?.imageUrl || null
    },
    validationSchema: Yup.object({
      clinicName: Yup.string().required('Cannot be empty'),
      address: Yup.string()
    }),
    onSubmit: formValues => {
      submitNewClinic(formValues);
    }
  });

  const getClinicDetails = async id => {
    let clinicModel = new Clinic();

    try {
      let result = await clinicModel.getClinicById(id);
      console.log(result);
      setClinicData(result);
    } catch (e) {
      console.log('ERROR' + e);
    }
  };

  const submitNewClinic = async formValues => {
    console.log(formValues);
    let clinicModel = new Clinic();
    let uploadModel = new File();
    let imgUrl;

    try {
      imgUrl = await uploadModel.uploadFile(formValues.attachment);
      console.log("img url", imgUrl, formValues);
    } catch (e) {
      console.log('error: ', e);
    }

    let formData = {
      name: formValues.clinicName,
      address: formValues.address,
      image_url: imgUrl.location,
      type: formValues.clinicType
    };

    try {
      let result = await clinicModel.addClinic(formData);
      console.log(result);
      handleReset();
      swal.fire({
        title: 'Success',
        text: 'Clinic added successfully',
        icon: 'success',
        confirmButtonText: 'Go back',
        customClass: {
          container: 'swal-z'
        }
      });
    } catch (e) {
      console.log('ERROR' + e);
    }
  };

  const clinicOptions = ['SMALL', 'BIG'];

  return (
    <Card>
      <div className="card-header">
        <div className="mt-4 card-title">
          <span
            style={{
              cursor: 'pointer'
            }}
          >
            <ArrowBackIosOutlined onClick={() => history.goBack()}></ArrowBackIosOutlined>
          </span>
          <h3 className="ml-3 mt-3 mb-2">Add Clinic</h3>
        </div>
      </div>
      <CardBody>
        <form onSubmit={handleSubmit}>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <TextField value={values.clinicName} onChange={handleChange} name="clinicName" label="Clinic Name" />
            </Grid>
            <Grid item xs={12}>
              <TextField value={values.address} onChange={handleChange} name="address" label="address" />
            </Grid>
            <Grid item xs={12}>
              <input
                id="attachment"
                onChange={event => {
                  setFieldValue('attachment', event.currentTarget.files[0]);
                }}
                accept="image/*"
                style={{ display: 'none' }}
                multiple
                type="file"
              />
              <label htmlFor="attachment">
                <Button color="primary" variant="outlined" component="span">
                  Upload Attachment
                </Button>
                <span style={{ marginLeft: '1rem' }}>{values.attachment?.name || ''}</span>
              </label>
            </Grid>
            {data?.file_attachment && (
              <img
                src={`${process.env.REACT_APP_USER_API_V3_CONFIG}/api/image?image_name=${data?.file_attachment}`}
                alt="medical-record"
                style={{ width: '100%' }}
              />
            )}
            <ActionBarAdd handleSubmit={handleSubmit} handleReset={handleReset} />
          </Grid>
        </form>
      </CardBody>
    </Card>
  );
};

export default ClinicAddPage;
