import { useEffect, useState } from 'react';
import { Button, Grid, MenuItem } from '@material-ui/core';
import { Autocomplete } from '@material-ui/lab';
import { useSelector } from 'react-redux';
import { TextField } from 'app/components/common/TextField';
import TextFormatter from "../../../../utils/TextFormatter";
import moment from "moment"

const PatientFormReadOnly = ({values}) => {

  const { doctorID, role } = useSelector(state => state.auth);

  const ageToBirthDate = (birthDate) =>{
    let year = moment().diff(birthDate, "years")
    let month = moment().diff(birthDate, "month") % 12
    return `${year} year(s) and ${month} month(s)`
  }

  return (
    <form>
      <Grid container spacing={2}>
        {values?.picture && values?.picture !== 'No Attachment' && (
          <Grid item xs={12}>
            <img
              src={TextFormatter.getImageURL(values?.picture)}
              alt="patient"
              style={{ height: '160px', marginBottom: '1rem' }}
            />
          </Grid>
        )}
        <Grid item xs={12}>
          <TextField
            disabled={true}
            id="name"
            value={values.name}
            label="Name"
          />
        </Grid>
        <Grid item xs={12}>
          <TextField
            disabled={true}
            id="animalType"
            value={values.animalType}
            label="AnimalType"
          >
          </TextField>
        </Grid>
        <Grid item xs={12}>
          <TextField
            disabled={true}
            id="breed"
            value={values.breed}
            label="Breed"
          />
        </Grid>

        <Grid item xs={6}>
          <TextField
            disabled={true}
            id="ageString"
            value={ageToBirthDate(values?.birthdate)}
            label="Age"
            type="text"
          />
        </Grid>

        <Grid item xs={6}>
          <TextField
            disabled={true}
            id="gender"
            value={values.gender}
            label="Gender"
          >
          </TextField>
        </Grid>
      </Grid>
    </form>
  );
};

export default PatientFormReadOnly;
