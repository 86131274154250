import { createContext, useEffect, useState } from 'react';
import { Grid } from '@material-ui/core';
import ReactFlow, { MiniMap, Controls, Background } from 'react-flow-renderer';

import DiagnosisNode from './DiagnosisNode';
import DiagnosisEdge from './DiagnosisEdge';

const DiagnoseBuilderForm = ({ isEditing }) => {
  const initialElements = [
    {
      id: '1',
      type: 'diagnosisNode',
      data: { question: 'Where does it hurt?' },
      position: { x: 0, y: 0 }
    },
    {
      id: '2',
      type: 'diagnosisNode',
      data: { question: 'One leg or mutiple?' },
      position: { x: 250, y: 0 }
    },
    {
      id: 'e1-2',
      source: '1',
      target: '2',
      type: 'diagnosisEdge',
      data: { answer: 'One leg' }
    }
  ];

  const [elements, setElements] = useState(initialElements);

  useEffect(() => {
    setElements(els =>
      els.map(el => {
        el.data = {
          ...el.data,
          label: 'watdefaks'
        };

        return el;
      })
    );
  }, [setElements]);

  return (
    <Grid container spacing={2}>
      <Grid item xs={12} style={{ height: '600px' }}>
        <ReactFlow
          elements={elements}
          nodeTypes={nodeTypes}
          edgeTypes={edgeTypes}
        >
          <MiniMap
            nodeStrokeColor={n => {
              if (n.style?.background) return n.style.background;
              if (n.type === 'input') return '#0041d0';
              if (n.type === 'output') return '#ff0072';
              if (n.type === 'default') return '#1a192b';

              return '#eee';
            }}
            nodeColor={n => {
              if (n.style?.background) return n.style.background;

              return '#fff';
            }}
            nodeBorderRadius={2}
          />
          <Controls />
          <Background color="#1A192B" gap={16} />
        </ReactFlow>
      </Grid>
    </Grid>
  );
};

export default DiagnoseBuilderForm;

const nodeTypes = {
  diagnosisNode: DiagnosisNode
};

const edgeTypes = {
  diagnosisEdge: DiagnosisEdge
};
