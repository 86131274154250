//EDIT
import { useEffect, useState } from 'react';
import { Grid, MenuItem } from '@material-ui/core';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import { Card, CardBody, CardHeader } from '_metronic/_partials/controls';
import TextField from 'app/components/common/TextField';
import { ActionBarAdd, ActionBarEdit } from 'app/components/common/ActionBar';
import Nametag from 'app/models/Nametag';
import Patient from 'app/models/Patient';
import User from '../../../../model/UserModel';
import swal from 'sweetalert2';
import { useParams, useHistory } from 'react-router-dom';
import { ArrowBackIosOutlined } from '@material-ui/icons';
import { getPagesCount } from '_metronic/_helpers';
import Swal from 'sweetalert2';
import UserModel from '../../../../model/UserModel';
import {Autocomplete} from "@material-ui/lab";

const NametagEditPage = ({ data, isEditing = true, headerToolbar, mode }) => {
  let [nametagData, setNameTagData] = useState(null);
  let [pets, setPets]  = useState([])
  let [users,setUsers] = useState([])
  let [selectedUser, setSelectedUser] = useState(null)
  let [selectedPet, setSelectedPet] = useState(null)

  const history = useHistory();
  const { id } = useParams();

  const editOperationTable = formValues => {

    try {
      let result = Nametag.edit(id, {
        tag: formValues.tag,
        pet_id: formValues.pet_id
      });
      swal.fire({
        title: 'Success',
        text: 'Operation Table edited successfully',
        icon: 'success',
        confirmButtonText: 'Go back',
        customClass: {
          container: 'swal-z'
        }
      });
    } catch (e) {
      console.log('error: ', e);
    }
  };

  const getDetail = async id => {

    try {
      let result = await Nametag.getById(id);
      setNameTagData(result);
    } catch (e) {
      console.log('error: ', e);
    }
  };

  const getPets = async()=> {

    try {
      let result = await UserModel.retrievePatientByOwnerId(selectedUser.id)
      result = result.result
      let formatted = result.map(r=>{return{label:r.patient_name,value:r.id,id:r.id}})


      setPets(formatted);
      setSelectedPet(formatted.find(p=>p.id === nametagData.pet_id))

    } catch (e) {
      console.log('error: ', e);
    }
  };

  const getUsers = async()=> {

    try {
      let result = await User.retrieveUser()
      result = result.result
      let formatted = result.map(r=>{return{label:r.user_name,value:r.id,id:r.id}})
      setUsers(formatted);
    } catch (e) {
      console.log('error: ', e);
    }
  };

  const setNameTagUser = async(pet_id)=> {

    let pet = await Patient.getById(pet_id)
    setSelectedUser(users.find(u=>u.id === pet.pet_owner_id))

  }


  const { values, handleChange, handleSubmit, handleReset, setFieldValue } = useFormik({
    initialValues: {
      tag: nametagData?.tag || '',

    },
    validationSchema: Yup.object({
      tag: Yup.string().required('Cannot be empty')
    }),
    onSubmit: async formValues => {
      try {
        let result = await Nametag.edit({...formValues,id, pet_id:selectedPet.id});
        if(result.success) Swal.fire('Success','Edited succesfully', 'success')
      } catch (e) {
        Swal.fire('Error', e.error,'error')
      }


    },
    enableReinitialize: true
  });

  useEffect(() => {
    getDetail(id);
    getUsers()
  }, []);

  useEffect( () => {
    if(users.length > 0 && nametagData.pet_id) setNameTagUser(nametagData.pet_id)
  }, [users]);

  useEffect(()=>{
    if(selectedUser) getPets()
    else
    {
      setSelectedPet(null)
      setPets([])
    }
  },[selectedUser])

  return (
      <Card>
        <div className="card-header">
          <div className="mt-4 card-title">
          <span
              style={{
                cursor: 'pointer'
              }}
          >
            <ArrowBackIosOutlined onClick={() => history.goBack()}></ArrowBackIosOutlined>
          </span>
            <h3 className="ml-3 mt-3 mb-2">{`Edit Nametag`}</h3>
          </div>
        </div>
        <CardBody>
          <form onSubmit={handleSubmit}>
            <Grid container spacing={2}>
              <Grid item xs={12}>
                Tag
                <TextField value={values.tag} onChange={handleChange} name="tag"/>
              </Grid>
              <Grid item xs={12}>
                User
                <Autocomplete
                    value={selectedUser}
                    onChange={(_, newValue) => {
                      setSelectedUser(newValue);
                    }}
                    options={users}
                    getOptionLabel={option => (option ? `${option.label}` : '')}
                    size="small"
                    renderInput={params => <TextField {...params} variant="outlined"/>}
                />
              </Grid>
              <Grid item xs={12}>
                Pet
                <Autocomplete
                    value={selectedPet}
                    onChange={(_, newValue) => {
                      setSelectedPet(newValue);
                    }}
                    options={pets}
                    getOptionLabel={option => (option ? `${option.label}` : '')}
                    size="small"
                    renderInput={params => <TextField {...params} variant="outlined"/>}
                />
              </Grid>
              <ActionBarEdit isEditing={true} handleSubmit={handleSubmit} isDeleteButtonShown={false}/>
            </Grid>
          </form>
        </CardBody>
      </Card>
  );
};

export default NametagEditPage;
