import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { Grid } from '@material-ui/core';
import { useFormik } from 'formik';
import * as Yup from 'yup';

import {
  Card,
  CardBody,
  CardHeader
} from '../../../_metronic/_partials/controls';
import { EditHeaderToolbar as HeaderToolbar } from '../../components/common/HeaderToolbar';
import { TextField } from '../../components/common/TextField';
import { ActionBarEdit as ActionBar } from '../../components/common/ActionBar';
import { fetchParticipant } from '../../../redux/participant/detail';

const ParticipantEditPage = () => {
  const { id } = useParams();
  const dispatch = useDispatch();

  // const isFetching = useSelector(state => state.loading.animalCategory);

  const { data, isFetching } = useSelector(state => state.participant.detail);
  useEffect(() => {
    dispatch(fetchParticipant(id));
  }, []);

  // const { completed } = useSelector(state => state.animalCategory.ops);
  // useEffect(() => {
  //   if (completed) {
  //     setIsEditing(false);
  //   }
  // }, [completed]);

  const [isEditing, setIsEditing] = useState(false);

  const {
    values,
    errors,
    touched,
    handleChange,
    handleSubmit,
    handleReset,
    resetForm
  } = useFormik({
    initialValues: {
      name: data?.full_name || '',
      youtubeName: data?.youtube_name || '',
      youtubeEmail: data?.youtube_email || '',
      phone: data?.phone_number || ''
    },
    // validationSchema: Yup.object({
    //   name: Yup.string().required('Cannot be empty')
    // }),
    // onSubmit: values => {
    //   dispatch(editAnimalCategory(id, values));
    // },
    enableReinitialize: true
  });

  return (
    <Card>
      <CardHeader title="Participant details">
        {/* <HeaderToolbar
          isEditing={isEditing}
          handleEdit={() => setIsEditing(true)}
          handleCancel={() => {
            resetForm();
            setIsEditing(false);
          }}
        /> */}
      </CardHeader>
      <CardBody>
        <form onSubmit={handleSubmit}>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <TextField
                disabled={!isEditing}
                id="name"
                value={values.name}
                onChange={handleChange}
                label="Name *"
                helperText={touched.name ? errors.name : ''}
                error={touched.name && errors.name ? true : false}
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                disabled={!isEditing}
                id="youtubeName"
                value={values.youtubeName}
                onChange={handleChange}
                label="Youtube Name *"
                helperText={touched.youtubeName ? errors.youtubeName : ''}
                error={touched.youtubeName && errors.youtubeName ? true : false}
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                disabled={!isEditing}
                id="youtubeEmail"
                value={values.youtubeEmail}
                onChange={handleChange}
                label="Youtube Email *"
                helperText={touched.youtubeEmail ? errors.youtubeEmail : ''}
                error={
                  touched.youtubeEmail && errors.youtubeEmail ? true : false
                }
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                disabled={!isEditing}
                id="phone"
                value={values.phone}
                onChange={handleChange}
                label="Phone *"
                helperText={touched.phone ? errors.phone : ''}
                error={touched.phone && errors.phone ? true : false}
              />
            </Grid>
            <ActionBar
              isEditing={isEditing}
              isFetching={isFetching}
              // handleSubmit={handleSubmit}
              // handleDelete={() => dispatch(deleteAnimalCategory(id))}
            />
          </Grid>
        </form>
      </CardBody>
    </Card>
  );
};

export default ParticipantEditPage;
