import ApiRequest from '../../utils/APIRequest2';

export default class Diagnose {

  static getAll = async () => {
    return await ApiRequest.set('v1/diagnoses', 'GET');
  };

  static getDiagnoseById = async (id) => {
    return await ApiRequest.set('v1/diagnose/' + id, 'GET');
  };

  static getByAppointmentUserandPatientId = async (id, patientId) => {
    return await ApiRequest.set('v1/diagnose/' + id + "/" + patientId, 'GET');
  };

  static getByAppointmentPatientId = async (patientId) => {
    return await ApiRequest.set(`/v1/diagnose_record/${patientId}`, 'GET');
  };

  /* deleteDiseaseById = async (id) => {
    return await ApiRequest.set('v1/disease/'+id, 'DELETE')
  } */

  static edit = async (id, values) => {
    return await ApiRequest.set('v1/diagnose/'+id, 'PUT', values)
  } 

  static create = async (values) => {
    return await ApiRequest.set('v1/diagnose', 'POST', values)
  }


}
