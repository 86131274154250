import HorizontalSeparator from 'app/components/common/HorizontalSeparator/HorizontalSeparator';
import {
  Button,
  Grid,
  MenuItem,
  //Select,
  InputLabel,
  Input,
  OutlinedInput,
  Box,
  Chip,
  Checkbox,
  ListItemText,
  FormHelperText
} from '@material-ui/core';
import { TextField } from '../../components/common/TextField';
import { Card, CardBody, CardHeader, CardHeaderToolbar } from '../../../_metronic/_partials/controls';
import { useState, useEffect } from 'react';
import { Link, useHistory } from 'react-router-dom';
import MultipleSelect from 'app/components/common/MultipleSelect/MultipleSelect';
import Select from 'react-select';
import CreatableSelect from 'react-select/creatable';
import { Autocomplete } from '@material-ui/lab';
import swal from 'sweetalert';
import SymptomModel from '../../models/Symptom';
import generalInfoModel from '../../models/GeneralInformation';

const SymptomAddPageV2 = () => {
  const history = useHistory();

  const [personName, setPersonName] = useState([]);

  const [symptomName, setSymptomName] = useState('');
  const [animalOptions, setAnimalOptions] = useState([]);
  //const [bodyPartOptions, setBodyPartOptions] = useState([]);
  const [temperatureOptions, setTemperatureOptions] = useState([]);
  const [drinkingOptions, setDrinkingOptions] = useState([]);
  const [eatingOptions, setEatingOptions] = useState([]);
  const [foodTypeOptions, setFoodTypeOptions] = useState([]);
  const [generalConditionOptions, setGeneralConditionOptions] = useState([]);

  const [selectedAnimal, setSelectedAnimal] = useState([]);
  const [selectedBodyPart, setSelectedBodyPart] = useState('');
  const [selectedTemperature, setSelectedTemperature] = useState([]);
  const [selectedDrinking, setSelectedDrinking] = useState([]);
  const [selectedEating, setSelectedEating] = useState([]);
  const [selectedFood, setSelectedFood] = useState([]);
  const [selectedType, setSelectedType] = useState('');

  const [reactSelectValue, setReactSelectValue] = useState([]);

  const [symptomDetails, setSymptomDetails] = useState([{ name: '', options: '', type: ''}]);

  const handleChange = event => {
    setPersonName(event.target.value);
  };

  /* const handleChangeMultiple = event => {
    const { options } = event.target;
    const value = [];
    for (let i = 0, l = options.length; i < l; i += 1) {
      if (options[i].selected) {
        value.push(options[i].value);
      }
    }
    setPersonName(value);
  }; */

  /* let animalOptions = [
    {
      value: 'Dog',
      label: 'Dog'
    },
    {
      value: 'Cat',
      label: 'Cat'
    },
    {
      value: 'Rabbit',
      label: 'Rabbit'
    }
  ]; */

  let typeOptions = [
    {
      value: 'checkbox',
      label: 'Checkbox'
    },
    {
      value: 'radio',
      label: 'Radio'
    },
  ]

  let bodyPartOptions = [
    {
      value: 'Mouth',
      label: 'Mouth'
    },
    {
      value: 'Nose',
      label: 'Nose'
    },
    {
      value: 'Stomach',
      label: 'Stomach'
    },
    {
      value: 'Eye',
      label: 'Eye'
    },
    {
      value: 'Fur',
      label: 'Fur'
    },
    {
      value: 'Leg',
      label: 'Leg'
    },
    {
      value: 'Chest',
      label: 'Chest'
    },
    {
      value: 'Back',
      label: 'Back'
    },
    {
      value: 'Ribs',
      label: 'Ribs'
    },
    {
      value: 'Bone',
      label: 'Bone'
    },
    {
      value: 'Nerves',
      label: 'Nerves'
    },
    {
      value: 'Others',
      label: 'Others'
    }
  ];

  /* let temperatureOptions = [
    {
      value: '>38',
      label: 'Over 38 Celcius'
    },
    {
      value: '36-38',
      label: 'Between 36-38 Celcius'
    },
    {
      value: '<36',
      label: 'Below 36 Celcius'
    }
  ]; */

  /* let drinkingOptions = [
    {
      value: 'Very Little',
      label: 'Very Little'
    },
    {
      value: 'Little',
      label: 'Little'
    },
    {
      value: 'Normal',
      label: 'Normal'
    },
    {
      value: 'Much',
      label: 'Much'
    },
    {
      value: 'Too Much',
      label: 'Too Much'
    } 
  ]; */

  /* let eatingOptions = [
    {
      value: '1x',
      label: '1x'
    },
    {
      value: '2x',
      label: '2x'
    },
    {
      value: '3x',
      label: '3x'
    },
    {
      value: '>5x',
      label: '>5x'
    }
  ]; */

  /* let foodTypeOptions = [
    {
      value: 'Raw food',
      label: 'Raw food'
    },
    {
      value: 'Canned food',
      label: 'Canned food'
    },
    {
      value: 'Liquid',
      label: 'Liquid'
    }
  ]; */

  const initializeGeneralInfo = async () => {
    try {
      let result = await generalInfoModel.getAll();

      console.log(result);

      let tempDrink = result.drinking.map(a => ({
        value: a.value,
        label: a.value
      }));
      setDrinkingOptions(tempDrink);

      let tempFood = result.food_type.map(a => ({
        value: a.value,
        label: a.value
      }));
      setFoodTypeOptions(tempFood);

      let tempEating = result.eating.map(a => ({
        value: a.value,
        label: a.value
      }));
      setEatingOptions(tempEating);

      let tempTemperature = result.body_temperature.map(a => ({
        value: a.value,
        label: a.value
      }));
      setTemperatureOptions(tempTemperature);

      let tempGeneral = result.general_condition.map(a => ({
        value: a.value,
        label: a.value
      }));
      setGeneralConditionOptions(tempGeneral);

      console.log('drinks', drinkingOptions);
      console.log('foodType', foodTypeOptions);
    } catch (e) {
      console.log('error', e);
    }
  };

  const handleDetailChange = (index, event) => {
    let data = [...symptomDetails];
    data[index][event.target.name] = event.target.value;
    setSymptomDetails(data);
  };

  const handleTypeChange = (index, event) => {
    //console.log("ini", event)
    let data = [...symptomDetails];
    data[index]['type'] = event;
    setSymptomDetails(data);
  };

  const handleOptionsChange = (index, event) => {
    /* console.log(event)
    let data = [...symptomDetails];
    let newData = event[0].value
    for(let i = 1; i < event.length; i++) {
      newData = newData + ';' + event[i].value
    }
    data[index]["options"] = newData;
    setSymptomDetails(data)

    console.log(symptomDetails)
    setReactSelectValue(event) */
    let data = [...symptomDetails];
    data[index]['options'] = event;
    setSymptomDetails(data);
    console.log(symptomDetails);
  };

  const addDetails = () => {
    let newDetail = { name: '', options: '' };

    setSymptomDetails([...symptomDetails, newDetail]);
  };

  const removeDetail = index => {
    let data = [...symptomDetails];
    data.splice(index, 1);
    setSymptomDetails(data);
  };

  const handleSubmit = async e => {
    try {
      if (symptomName == null || symptomName == '') {
        swal({
          title: 'Symptom name',
          text: 'Symptom name cannot be empty',
          icon: 'error'
        });
        return;
      }
      if (selectedBodyPart == null || selectedBodyPart == '') {
        swal({
          title: 'Body part',
          text: 'Body part must be selected',
          icon: 'error'
        });
        return;
      }

      let Symptom = new SymptomModel();
      //console.log("submitting", symptomDetails)
      //console.log("submitting", symptomName)
      //console.log("submitting", selectedBodyPart)
      //console.log("submitting", symptomDetails)

      let submitDetail = [];

      if (symptomDetails.length == 0) {
        swal({
          title: 'Symptom details',
          text: 'Symptom details must be filled',
          icon: 'error'
        });
        return;
      }

      for (let i = 0; i < symptomDetails.length; i++) {
        let name = symptomDetails[i].name;
        let optionsArr = symptomDetails[i].options.map(o => {
          return o.value;
        });
        let type = symptomDetails[i].type;

        if (name == null || name == '') {
          swal({
            title: 'Symptom details name',
            text: 'Symptom details name must be filled',
            icon: 'error'
          });
          return;
        }
        if (optionsArr[0] == null || optionsArr[0] == '') {
          swal({
            title: 'Symptom details values',
            text: 'Symptom details values must be filled',
            icon: 'error'
          });
          return;
        }
        if (type == null || type == '') {
          swal({
            title: 'Symptom input type',
            text: 'Symptom input type must be filled',
            icon: 'error'
          });
          return;
        }

        submitDetail.push({ detail_name: name, options: optionsArr, type: type.value });
      }

      //console.log("submitting", submitDetail)

      let body = {
        name: symptomName,
        body_part: selectedBodyPart.value,
        details: submitDetail
      };

      console.log('submitting', body);

      setSymptomName('');
      setSelectedBodyPart('');
      setSymptomDetails([{ name: '', options: '', type: ''}]);
      setReactSelectValue([]);

      let result = await Symptom.create(body);

      /* alert("Symptom '" + result.name + "' has been added successfully")

        window.location.href = `/symptom/${result.id}` */

      //console.log(result)

      swal({
        title: 'Success',
        text: "Symptom '" + result.name + "' has been added successfully",
        icon: 'success'
      }).then(() => {
        history.push("/symptom/" + result.id)
      });
    } catch (e) {
      console.log(e);

      swal({
        title: 'Error',
        text: e.error_message ? e.error_message : 'An Error Occurred',
        icon: 'error',
        customClass: {
          container: 'swal-z'
        }
      });
    }
  };

  useEffect(() => {
    //initializeGeneralInfo()
  }, []);

  return (
    <>
      <Card>
        <CardHeader title="Symptom details">
          {/* <CardHeaderToolbar>
            <Button color={'primary'} variant="contained" onClick={handleSubmit}>
              Save
            </Button>
          </CardHeaderToolbar> */}
        </CardHeader>
        <CardBody>
          <form onSubmit={handleSubmit}>
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <TextField
                  // id="name"
                  value={symptomName}
                  onChange={e => setSymptomName(e.target.value)}
                  label="Symptom Name *"
                  input={<OutlinedInput label="Tag" />}
                  // helperText={touched.name ? errors.name : ''}
                  // error={touched.name && errors.name ? true : false}
                />
              </Grid>
              <Grid item xs={12} sm={5}>
                {/* <MultipleSelect
                  label={'Body Part associated with the disease'}
                  options={bodyPartOptions}
                  selected={selectedBodyPart}
                  setSelected={setSelectedBodyPart}
                /> */}

                <InputLabel id="demo-simple-select-label">Body Part associated with the disease</InputLabel>
                <Select
                  labelId="demo-simple-select-label"
                  id="demo-simple-select"
                  value={selectedBodyPart}
                  placeholder="Pick one"
                  onChange={e => setSelectedBodyPart(e)}
                  options={bodyPartOptions}
                  styles={{
                    // Fixes the overlapping problem of the component
                    menu: provided => ({ ...provided, zIndex: 9999 })
                  }}
                  //style={{minWidth: 120}}
                />
                  {/* {bodyPartOptions?.map((el, idx) => {
                        return (
                            <MenuItem key={el.value} value={el.value}>
                                <ListItemText primary={el.label} />
                            </MenuItem>
                        );
                    })} */}
                {/* {selectedBodyPart == '' ? <FormHelperText>Pick one</FormHelperText> : null} */}
              </Grid>
            </Grid>
            <HorizontalSeparator text={'Details'} />

            {symptomDetails.map((input, index) => {
              return (
                <Grid container spacing={2} key={index}>
                  <Grid item xs={12} sm={4}>
                    <TextField
                      name={'name'}
                      value={input.name}
                      onChange={event => handleDetailChange(index, event)}
                      label="Detail (frequency, time, etc)"
                      input={<OutlinedInput label="Tag" />}
                      // helperText={touched.name ? errors.name : ''}
                      // error={touched.name && errors.name ? true : false}
                    />
                  </Grid>
                  <Grid item xs={12} sm={4}>
                    {/* <TextField
                            name={"options"}
                            value={input.options}
                            onChange={event => handleDetailChange(index, event)}
                            label="Values (use ; to separate each values)"
                            input={<OutlinedInput label="Tag" />}
                            // helperText={touched.name ? errors.name : ''}
                            // error={touched.name && errors.name ? true : false}
                            /> */}
                    <CreatableSelect
                      isMulti
                      name={'options'}
                      value={input.options}
                      components={{ DropdownIndicator: null }}
                      onChange={event => handleOptionsChange(index, event)}
                      placeholder="Values (press enter to create option)"
                    />
                  </Grid>
                  <Grid item xs={12} sm={3}>
                    <Select
                      name={'type'}
                      value={input.type}
                      placeholder="Input type"
                      onChange={event => handleTypeChange(index, event)}
                      options={typeOptions}
                      styles={{
                        // Fixes the overlapping problem of the component
                        menu: provided => ({ ...provided, zIndex: 9999 })
                      }}
                      //style={{minWidth: 120}}
                    />
                  </Grid>
                  <Grid item xs={12} sm={1}>
                    <Button color={'secondary'} variant="contained" onClick={() => removeDetail(index)}>
                      Remove
                    </Button>
                  </Grid>
                </Grid>
              );
            })}

            <br />
            <Button color={'primary'} variant="contained" onClick={addDetails}>
              Add Details
            </Button>
          </form>
          <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'flex-end' }}>
            <Button color={'primary'} variant="contained" onClick={handleSubmit}>
              Save
            </Button>
          </div>
        </CardBody>
      </Card>
    </>
  );
};

export default SymptomAddPageV2;
