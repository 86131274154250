import React, {useEffect, useRef, useState} from 'react';
import {Link, useHistory} from 'react-router-dom';
import {useDispatch, useSelector} from 'react-redux';
import {Grid, IconButton, MenuItem, Tooltip} from '@material-ui/core';
import {DatePicker, MuiPickersUtilsProvider} from '@material-ui/pickers';
import {
    Assignment as AssignmentIcon,
    Close as CloseIcon,
    DoneOutline
} from '@material-ui/icons';
import {endOfWeek, format, parseISO, startOfDay} from 'date-fns';
import DateFnsUtils from '@date-io/date-fns';

import {
    Card,
    CardBody,
    CardHeader
} from '../../../_metronic/_partials/controls';
import ButtonLink from 'app/components/common/ButtonLink';
import {TextField} from 'app/components/common/TextField';
import {sortCaret} from '../../../_metronic/_helpers';
import {ListHeaderToolbar} from '../../components/common/HeaderToolbar';
import {DataTableLocal as DataTable} from '../../components/common/DataTable';
import ApproveAppointmentDialog from './components/ApproveAppointmentDialog';
import {
    fetchAppointment,
    fetchAppointmentByDateRange
} from '../../../redux/appointment/list';

import moment from "moment"

import qs from "qs"
import Appointment from "../../models/Appointment";
import ApproveAppointmentDialogV2 from "./components/ApproveAppointmentDialogV2";
import swal from "sweetalert2";

let contentTimer;

const IncomingAppointmentListPage = (props) => {
    const dispatch = useDispatch();
    const history = useHistory();
    const {role} = useSelector(state => state.auth);

    const editSearchQueryValue = (value) => {
        clearTimeout(contentTimer);

        contentTimer = setTimeout(async () => {
            setSearchQuery(value)
            changeHistoryPush(value)
        }, 500);
    }

    const [searchQuery, setSearchQuery] = useState("")
    const [searchRef, setSearchRef] = useState(null)
    const [defaultSearchValue, setDefaultSearchValue] = useState(null)

    const isFetching = useSelector(state => state.loading.appointment);

    const [medicalRecordAppointments, setMedicalRecordAppointments] = useState([])

    const changeHistoryWhenClearDateFilter = () => {
        history.push(`/incoming-appointment?q=${searchQuery}`)
    }

    const changeHistoryPush = (value) => {

        // if (value !== undefined) {
        //     history.push(`/appointment?q=${value}`)
        // } else {
        //     history.push(`/appointment?q=${searchQuery}`)
        // }
    }

    useEffect(() => {

        const queryParams = qs.parse(props.location.search, {ignoreQueryPrefix: true})
        if (queryParams.q) {
            setDefaultSearchValue(queryParams.q)
            setSearchQuery(queryParams.q)
        }

    }, []);

    useEffect(() => {
        fetchAppointmentConditionally();

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const fetchAppointmentConditionally = () => {
        fetchIncomingAppointment();
    };

    const fetchIncomingAppointment = async () => {
        let appointmentModel = new Appointment()
        try {
            const result = role === 'DOCTOR' ? await appointmentModel.getMedicalRecordAppointmentForDoctor() : await appointmentModel.getMedicalRecordAppointment()
            console.log("FIA", result)
            setMedicalRecordAppointments(result.map(obj => {
                return {
                    ...obj,
                    medical_description: obj.medical_record?.description
                }
            }))
        } catch (e) {
            console.log(e)
            swal.fire({
                title: 'Error',
                text: e.error_message ? e.error_message : "An Error Occured",
                icon: 'error',
                confirmButtonText: 'Ok',
                customClass: {
                    container: 'swal-z'
                }
            });
        }
    }

    const columns = [
        {
            dataField: 'medical_record_id',
            text: 'ID',
            sort: true,
            sortCaret: sortCaret
        },
        {
            dataField: 'start_time',
            text: 'Appointment Time',
            formatter: (cell, row, rowIndex, formatExtraData) => {
                return row && <>{format(parseISO(row.start_time), 'yyyy-MM-dd HH:mm')} - {format(parseISO(row.end_time), 'HH:mm')}</>
            },
            sort: true,
            sortCaret: sortCaret
        },
        {
            dataField: 'customer_name',
            text: 'customer',
            formatter: (cell, row) => {
                return row && <Link to={`/customer/${row?.user_id}`}>{cell}</Link>;
            }
        },
        {
            dataField: 'patients_name',
            text: 'patient',
            formatter: (cell, row) => {
                return row && cell?.map((obj, i) => {
                        return <>
                        <Link to={`/patient/${row?.patients_id[i]}`}> 
                        {obj}
                        </Link>
                        <br></br>
                        </>
                    })
            }
        },
        {
            dataField: 'doctor_name',
            text: 'doctor'
        },
        {
            dataField: 'booking_type_name',
            text: 'type'
        },
        {
            dataField: 'medical_description',
            text: 'description'
        },
    ];

    const [isApproveDialogOpen, setIsApproveDialogOpen] = useState(false);
    const [selectedAppointment, setSelectedAppointment] = useState(undefined);

    return (
        <>
            <Card>
                <CardHeader title="Medical Record List">
                    <ListHeaderToolbar
                        // addLink="/appointment/add"
                        // addText="New Appointment"
                        additionalComponent={
                            role === "ADMIN" ?
                                <ButtonLink to="/appointmentType" style={{marginRight: '1rem'}}>
                                    Manage Type
                                </ButtonLink>
                                :
                                null
                        }
                    />
                </CardHeader>
                <CardBody>
                    <DataTable
                        defaultSearchValue={defaultSearchValue}
                        data={medicalRecordAppointments}
                        loading={isFetching}
                        totalDataCount={medicalRecordAppointments.length}
                        columns={columns}
                        editLink="/appointment/"
                        handleSearchChange={(value) => {
                            editSearchQueryValue(value)
                            // console.log("boi", value)
                        }}
                        setSearchRef={setSearchRef}
                    />
                </CardBody>
            </Card>
            <ApproveAppointmentDialogV2
                open={isApproveDialogOpen}
                handleClose={(refresh) => {
                    setIsApproveDialogOpen(false)
                    if (refresh) {
                        fetchIncomingAppointment()
                    }
                }}
                data={selectedAppointment}
            />
        </>
    );
};

export default IncomingAppointmentListPage;
