import React from 'react';
import { Button } from '@material-ui/core';
import { Link } from 'react-router-dom';

const ButtonLink = ({ link, variant, color, children, ...props }) => {
  return (
    <Link to={link} {...props}>
      <Button variant={variant} color={color}>{children}</Button>
    </Link>
  )
}

export default ButtonLink;