import { combineReducers } from 'redux';

import list from './list';
import listByDoctor from './listByDoctor';
import detail from './detail';
import ops from './ops';

export default combineReducers({
  list,
  listByDoctor,
  detail,
  ops
});
