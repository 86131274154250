import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router';
import { useDispatch, useSelector } from 'react-redux';
import { Button, Grid, IconButton, MenuItem } from '@material-ui/core';
import Files from '../../../models/File';
import { TextField } from 'app/components/common/TextField';
import TimePicker from 'app/components/common/TimePicker';

import { makeStyles } from '@material-ui/core/styles';
import UserModel from '../../../../model/UserModel';
import CategoryModel from '../../../../model/CategoryModel';
import OperationTable from 'app/models/OperationTable';
import { Container, Row, Col, Modal } from 'react-bootstrap';

import moment from 'moment';
import Select from 'react-select';
import { Autocomplete } from '@material-ui/lab';
import AppointmentModel from '../../../../model/AppointmentModel';
import swal from 'sweetalert2';
import Doctor from '../../../models/Doctor';
import BookingTypeModel from '../../../../model/BookingTypeModel';

const useStyles = makeStyles(theme => ({
    container: {
        backgroundColor: 'rgba(0, 0, 0, 0.25)'
    },
    paper: {
        width: '800px',
        maxWidth: '800px'
    },
    title: {
        marginBottom: '2rem',
        padding: '2rem 2rem 1rem 2rem',
        borderBottom: `1px solid ${theme.palette.divider}`
    },
    timeSlotContainer: {
        maxWidth: '360px'
    },
    datePicker: {
        marginBottom: '1rem'
    },
    actionContainer: {
        justifyContent: 'flex-start',
        marginTop: '2rem',
        padding: '1.5rem 2rem 1.5rem 2rem',
        borderTop: `1px solid ${theme.palette.divider}`
    }
}));

const AddAppointmentDialogV2 = ({ isOpen, handleClose }) => {
    const history = useHistory();
    const dispatch = useDispatch();
    const classes = useStyles();
    const { role } = useSelector(state => state.auth);

    const [startTime, setStartTime] = useState(null);
    const [endTime, setEndTime] = useState(null);

    const [selectedOwner, setSelectedOwner] = useState(null);
    const [selectedOwnerInputValue, setSelectedOwnerInputValue] = useState(null);
    const [ownerSelection, setOwnerSelection] = useState([]);

    const [selectedPatient, setSelectedPatient] = useState(null);
    const [patients, setPatients] = useState([]);
    const [selectedPatientInputValue, setSelectedPatientInputValue] = useState(null);
    const [patientSelection, setPatientSelection] = useState([]);

    const [selectedBookingType, setSelectedBookingType] = useState(null);
    const [selectedBookingTypeInputValue, setSelectedBookingTypeInputValue] = useState(null);
    const [selectedOperationTableInputValue, setSelectedOperationTableInputValue] = useState(null);
    const [selectedOperationTableType, setSelectedOperationTableType] = useState(null);
    const [operationTableOption, setOperationTableOption] = useState([]);
    const [bookingTypeSelection, setBookingTypeSelection] = useState([]);

    const [selectedDoctor, setSelectedDoctor] = useState(null);
    const [doctorSelection, setDoctorSelection] = useState([]);
    const [doctorInputValue, setDoctorInputValue] = useState(null);

    const [appointmentSlotSelection, setAppointmentSlotSelection] = useState([]);
    const [selectedAppointmentSlot, setSelectedAppointmentSlot] = useState(null);

    const [description, setDescription] = useState(null);
    const [paymentProof, setPaymentProof] = useState(null);

    const onClose = () => {
        handleClose(true);
        reset();
    };

    const submitForm = async () => {
        try {
            if (patients.length === 0) {
                swal.fire({
                    title: 'Error',
                    text: 'Mohon pilih setidaknya 1 pasien',
                    icon: 'error',
                    confirmButtonText: 'Go back',
                    customClass: {
                        container: 'swal-z'
                    }
                });
                return;
            }
            let patientIds = patients.map(p => p.value);
            let result = await AppointmentModel.addAppointment(
                selectedAppointmentSlot.value,
                selectedBookingType.value,
                startTime,
                endTime.toDate(),
                patientIds,
                selectedOwner.value,
                description,
                selectedOperationTableType?.value,
                paymentProof,
            );

            let confirmResult = await swal.fire({
                title: 'Success',
                text: 'Appointment berhasil dibuat',
                icon: 'success',
                confirmButtonText: 'Go back',
                customClass: {
                    container: 'swal-z'
                }
            });

            onClose();
        } catch (e) {
            let error = await swal.fire({
                title: 'Error',
                text: e.error_message ? e.error_message : 'Terjadi Kesalahan',
                icon: 'error',
                confirmButtonText: 'Go back',
                customClass: {
                    container: 'swal-z'
                }
            });

            console.log(e);
        }
    };

    const retrieveUser = async () => {
        try {
            const result = await UserModel.retrieveUser();

            if (result.result) {
                let formatted = result.result.map(obj => {
                    return {
                        label: `${obj.user_name} (${obj.mobile})`,
                        value: obj.id
                    };
                });
                setOwnerSelection(formatted);
            } else {
                setOwnerSelection([]);
            }
        } catch (e) {
            console.log(e);
        }
    };

    const retrievePatient = async () => {
        try {
            setPatientSelection([]);
            setSelectedPatient(null);

            const result = await UserModel.retrievePatientByOwnerId(selectedOwner.value);

            if (result.result) {
                let formatted = result.result.map(obj => {
                    return {
                        label: `${obj.patient_name} - ${obj.animal_name}`,
                        value: obj.id
                    };
                });
                setPatientSelection(formatted);
            } else {
                setPatientSelection([]);
            }
        } catch (e) {
            console.log(e);
        }
    };

    const retrieveDoctor = async () => {
        try {
            setDoctorSelection([]);
            setSelectedDoctor(null);

            const result = await Doctor.getAll();

            console.log('RPOZ doctor', result);

            let formatted = result.map(obj => {
                return {
                    label: `${obj.doctor_name}`,
                    value: obj.id,
                    doctor_id: obj.doctor_id
                };
            });
            setDoctorSelection(formatted);
        } catch (e) {
            console.log(e);
        }
    };

    const retrieveBookingType = async () => {
        try {
            setBookingTypeSelection([]);
            setSelectedBookingType(null);

            console.log('RPOZ selected', selectedDoctor);

            const result = await BookingTypeModel.getByDoctorId(selectedDoctor.doctor_id);

            console.log('RPOZ', result);

            if (result) {
                let formatted = result.map(obj => {
                    return {
                        label: `${obj.booking_type_name} (${obj.duration * 10} mins)`,
                        value: obj.id,
                        duration: obj.duration,
                        isOperation: obj.category_id === 3,
                        category_id: obj.category_id
                    };
                });
                setBookingTypeSelection(formatted);
            } else {
                setBookingTypeSelection([]);
            }
        } catch (e) {
            console.log('RPO', e);
        }
    };

    const retrieveAppointmentSlot = async () => {
        try {
            const result = await AppointmentModel.getUpcomingAppointmentslotbyCategoryandDoctorId(
                selectedBookingType.category_id,
                selectedDoctor.doctor_id
            );
            console.log('RPOZ', result);

            let formatted = result.map(obj => {
                return {
                    label: `${obj.clinic_name}, ${moment(obj.start_time).format('DD MMMM YYYY HH:mm')} - ${moment(obj.end_time).format('HH:mm')}`,
                    value: obj.id,
                    clinic_id: obj.clinic_id,
                    start_time: obj.start_time,
                    end_time: obj.end_time
                };
            });

            setAppointmentSlotSelection(formatted);
        } catch (e) {
            console.log(e);
        }
    };

    const retrieveOperationTable = async id => {
        let operationTableModel = new OperationTable();
        try {
            const result = await operationTableModel.getAllOperationByClinicId(id);

            console.log('Operation table', result);
            if (result) {
                let formatted = result.map(obj => {
                    return {
                        label: obj.name,
                        value: obj.id
                    };
                });
                setOperationTableOption(formatted);
            } else {
                setOperationTableOption([]);
            }
        } catch (e) {
            console.log('RPO', e);
        }
    };

    const uploadPaymentProof = async (files) => {
        let FileModel = new Files();
        let result = await FileModel.uploadFile(files)
        if (!result.location) {
            swal.fire({
                title: 'Error',
                text: 'Unable to upload signature, please try again later',
                icon: 'error',
                confirmButtonText: 'Go back',
                customClass: {
                    container: 'swal-z'
                }
            });
            return;

        }
        console.log('hubla', result)
        setPaymentProof(result.location)
    }

    useEffect(() => {
        retrieveUser();
        retrieveDoctor();
    }, []);

    useEffect(() => {
        if (selectedBookingType && selectedDoctor) {
            retrieveAppointmentSlot();
        }
    }, [selectedBookingType, selectedDoctor]);

    useEffect(() => {
        console.log('ROSEEEE', selectedOwner);
        retrievePatient();
        // retrievePatients()
    }, [selectedOwner]);

    useEffect(() => {
        if (selectedDoctor) {
            retrieveBookingType();
        }
    }, [selectedDoctor]);

    const reset = () => {
        setStartTime(null);
        setEndTime(null);
        setSelectedOwner(null);
        setSelectedOwnerInputValue(null);
        // setOwnerSelection([]);

        setSelectedPatient(null);
        setPatients([]);
        setSelectedPatientInputValue(null);
        setPatientSelection([]);

        setSelectedBookingType(null);
        setSelectedBookingTypeInputValue(null);
        setSelectedOperationTableInputValue(null);
        setSelectedOperationTableType(null);
        setOperationTableOption([]);
        setBookingTypeSelection([]);

        setSelectedDoctor(null);
        // setDoctorSelection([]);
        setDoctorInputValue(null);

        setAppointmentSlotSelection([]);
        setSelectedAppointmentSlot(null);
        setDescription(null)
        setPaymentProof(null)
    };

    const renderTimeInput = props => {
        return (
            <TextField
                disabled={props.disabled}
                value={`${props.value}`}
                onChange={props.onChange}
                onClick={props.onClick}
                label={props.label}
            />
        );
    };

    const handleStartTimeChange = date => {
        setEndTime(moment(date).add((selectedBookingType.duration * 10) / 60, 'hours'));
        console.log(moment(date).add((selectedBookingType.duration * 10) / 60, 'hours'));
    };

    const handleBookingTypeChange = pickedBookingType => {
        if (!pickedBookingType) return;

        console.log('pickedBookingType', pickedBookingType);

        setSelectedBookingType(pickedBookingType);
        console.log(startTime);
        let dateObject = moment(startTime).toDate();
        console.log(dateObject);

        //setEndTime(moment(dateObject).add((pickedBookingType.duration * 10)), 'minute')
        setEndTime(moment(dateObject).add(pickedBookingType.duration * 10 * patients.length, 'minutes'));
        //console.log(moment(dateObject).add((pickedBookingType.duration * 10)/60, 'hours'))
    };

    const handleDoctorChange = pickedDoctor => {
        if (!pickedDoctor) return;

        console.log('pickedBookingType', pickedDoctor);

        setSelectedDoctor(pickedDoctor);
    };

    return (
        <Modal show={isOpen} onHide={onClose} classes={{ container: classes.container, paper: classes.paper }}>
            <form>
                <Modal.Title className={classes.title}>Add Appointment</Modal.Title>
                <Modal.Body>
                    <Row>
                        <Col md={12}>
                            Doctor
                            <Autocomplete
                                value={selectedDoctor}
                                onChange={(_, newValue) => {
                                    // setSelectedBookingType(newValue)
                                    handleDoctorChange(newValue);
                                }}
                                options={doctorSelection}
                                getOptionLabel={option => (option ? `${option.label}` : '')}
                                inputValue={doctorInputValue}
                                onInputChange={(_, newInputValue) => {
                                    setDoctorInputValue(newInputValue);
                                }}
                                input
                                size="small"
                                renderInput={params => <TextField {...params} variant="outlined" />}
                            />
                        </Col>

                        {selectedDoctor && (
                            <>
                                <Col md={12} style={{ marginTop: 10 }}>
                                    Booking Type
                                    <Autocomplete
                                        value={selectedBookingType}
                                        onChange={(_, newValue) => {
                                            // setSelectedBookingType(newValue)
                                            handleBookingTypeChange(newValue);
                                        }}
                                        options={bookingTypeSelection}
                                        getOptionLabel={option => (option ? `${option.label}` : '')}
                                        inputValue={selectedBookingTypeInputValue}
                                        onInputChange={(_, newInputValue) => {
                                            setSelectedBookingTypeInputValue(newInputValue);
                                        }}
                                        input
                                        size="small"
                                        renderInput={params => <TextField {...params} variant="outlined" />}
                                    />
                                </Col>
                                {bookingTypeSelection.length <= 0 && (
                                    <Col style={{ marginTop: 10 }} md={12}>
                                        This Doctor have no bookings available
                                    </Col>
                                )}
                            </>
                        )}

                        <Col style={{ marginTop: 10 }} md={12}>
                            Owner
                            <Autocomplete
                                value={selectedOwner}
                                onChange={(_, newValue) => {
                                    console.log('ROSEV', newValue);
                                    setSelectedOwner(newValue);
                                    setSelectedPatient(null);
                                    setPatients([]);
                                    setSelectedPatientInputValue(null);

                                    setSelectedOperationTableInputValue(null);
                                    setSelectedOperationTableType(null);

                                    setSelectedAppointmentSlot(null);

                                    setStartTime(null)
                                    setEndTime(null)
                                    setDescription(null)
                                }}
                                options={ownerSelection}
                                getOptionLabel={option => (option ? `${option.label}` : '')}
                                inputValue={selectedOwnerInputValue}
                                onInputChange={(_, newInputValue) => {
                                    console.log('ROSEV', newInputValue);
                                    setSelectedOwnerInputValue(newInputValue);
                                }}
                                size="small"
                                renderInput={params => <TextField {...params} variant="outlined" />}
                            />
                        </Col>

                        {patientSelection.length > 0 ? (
                            <Col style={{ marginTop: 10 }} md={12}>
                                Patient
                                <Autocomplete
                                    value={selectedPatient}
                                    onChange={(_, newValue) => {
                                        if (newValue == null) return;
                                        console.log('ROSEV', newValue);
                                        setSelectedPatient(newValue);
                                        setPatients([...patients, newValue]);
                                        if (selectedBookingType) {
                                            setEndTime(moment(endTime).add((selectedBookingType.duration * 10) / 60, 'hours'));
                                        }
                                    }}
                                    options={patientSelection.filter(pS => !patients.includes(pS))}
                                    getOptionLabel={option => (option ? `${option.label}` : '')}
                                    inputValue={selectedPatientInputValue}
                                    onInputChange={(_, newInputValue) => {
                                        setSelectedPatientInputValue(newInputValue);
                                    }}
                                    size="small"
                                    renderInput={params => <TextField {...params} variant="outlined" />}
                                />
                                {patients.length > 0 && (
                                    <div className="my-3 pt-2">
                                        {patients.map((p, index) => (
                                            <div className="my-1">
                                                <Row>
                                                    <Col>
                                                        <TextField value={p?.label} disabled={true}
                                                            label={`Pet ${index + 1}`} variant="outlined" />
                                                    </Col>
                                                    <Button
                                                        size="small"
                                                        onClick={() => {
                                                            setPatients(patients.filter((_, i) => i !== index));
                                                            setSelectedPatient('');
                                                            setEndTime(moment(endTime).subtract((selectedBookingType.duration * 10) / 60, 'hours'));
                                                        }}
                                                        color="secondary"
                                                        variant="contained"
                                                    >
                                                        X
                                                    </Button>

                                                    {/* <hr className='p-0 mt-0'></hr> */}
                                                </Row>
                                            </div>
                                        ))}
                                    </div>
                                )}
                            </Col>
                        ) : selectedOwner ? (
                            <Col style={{ marginTop: 10 }} md={12}>
                                This Owner don't have a pet registered
                            </Col>
                        ) : null}

                        {selectedBookingType && (
                            <>
                                <Col style={{ marginTop: 10 }} md={12}>
                                    Appointment Slot
                                    {
                                        appointmentSlotSelection.length <= 0 ? (
                                            <Col style={{ marginTop: 10 }} className="mb-3" md={12}>
                                                This Doctor/Booking Type combination have no open slots
                                            </Col>
                                        )
                                            :
                                            <Autocomplete
                                                value={selectedAppointmentSlot}
                                                onChange={(_, newValue) => {
                                                    setSelectedAppointmentSlot(newValue);
                                                    retrieveOperationTable(newValue.clinic_id);
                                                    setStartTime(newValue.start_time);
                                                    handleStartTimeChange(newValue.start_time);
                                                }}
                                                options={appointmentSlotSelection}
                                                getOptionLabel={option => (option ? `${option.label}` : '')}
                                                size="small"
                                                renderInput={params => <TextField {...params} variant="outlined" />}
                                            />

                                    }
                                </Col>
                            </>
                        )}

                        {selectedAppointmentSlot && (
                            <>
                                <Col md={6}>
                                    Start time
                                    <TimePicker
                                        value={startTime}
                                        onChange={date => {
                                            setStartTime(date);
                                            handleStartTimeChange(date);
                                        }}
                                        clearable
                                        TextFieldComponent={renderTimeInput}
                                    />
                                </Col>
                                <Col md={6}>
                                    End time
                                    <TimePicker
                                        value={endTime}
                                        onChange={date => setEndTime(date)}
                                        clearable
                                        TextFieldComponent={renderTimeInput}
                                        disabled
                                    />
                                </Col>
                            </>
                        )}

                        {selectedAppointmentSlot && (
                            <Col style={{ marginTop: 10 }} md={12}>
                                Operation Table
                                <Autocomplete
                                    value={selectedOperationTableType}
                                    onChange={(_, newValue) => {
                                        setSelectedOperationTableType(newValue);
                                    }}
                                    options={operationTableOption}
                                    getOptionLabel={option => (option ? `${option.label}` : '')}
                                    inputValue={selectedOperationTableInputValue}
                                    onInputChange={(_, newInputValue) => {
                                        setSelectedOperationTableInputValue(newInputValue);
                                    }}
                                    size="small"
                                    renderInput={params => <TextField {...params} variant="outlined" />}
                                />
                            </Col>
                        )}


                        <Col style={{ marginTop: 10 }} md={12}>
                            Descriptions
                            <TextField
                                multiline={true}
                                rows={3}
                                value={description}
                                onChange={e => {
                                    setDescription(e.target.value);
                                }}
                                variant="outlined"
                            />
                        </Col>
                        <Col style={{ marginTop: 10 }} md={12}>
                            <input
                                id="paymentProof"
                                onChange={(e) => {
                                    uploadPaymentProof(e.currentTarget.files[0])
                                }}
                                accept="image/*"
                                style={{ display: 'none' }}
                                multiple
                                type="file"
                            />
                            <label htmlFor="paymentProof">
                                <Button color="primary" variant="outlined" component="span">
                                    Upload Payment Proof
                                </Button>
                                <div>
                                    <span style={{ marginLeft: '1rem' }}>
                                        {paymentProof ?

                                            <img alt="payment_proof" style={{ width: '150px', height: '100px' }} src={paymentProof}></img> :
                                            ''
                                        }
                                    </span>
                                </div>

                            </label>
                        </Col>
                    </Row>
                </Modal.Body>
                <Modal.Footer className={classes.actionContainer}>
                    <>
                        {role !== 'DOCTOR' && (
                            <Button
                                style={{
                                    marginRight: 10
                                }}
                                color="primary"
                                variant="contained"
                                onClick={() => submitForm()}
                            >
                                Create Appointment
                            </Button>
                        )}
                        <Button
                            color="secondary"
                            variant="contained"
                            onClick={() => {
                                onClose();
                            }}
                        >
                            Cancel
                        </Button>
                    </>
                </Modal.Footer>
            </form>
        </Modal>
    );
};

export default AddAppointmentDialogV2;
