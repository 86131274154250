import React, { useState, useEffect } from 'react';
import { Button, Grid, Modal } from '@material-ui/core';
import swal from 'sweetalert';
import MultipleSelect from 'app/components/common/MultipleSelect/MultipleSelect';

export default function DiseaseSymptomSelectionModal({ modalData, onClose, onSubmit }) {
  const [tmpSymptom, setTmpSymptom] = useState([]);

  useEffect(() => {
    console.log('MDD', modalData);
    if (modalData?.detail) {
      console.log('MDD', modalData.detail);
      let tempSymptom = []

      for(let i = 0; i < modalData.symptoms_detail.length; i++) {
        let name = modalData.symptoms_detail[i].detail_name
        let a = modalData.detail.find(obj => obj.name == name)
        console.log("berhitung", a)
        tempSymptom.push(a?.options)
      }
      
      setTmpSymptom(tempSymptom)

      /* setTmpSymptom(
        modalData.detail.map(obj => {
          return obj.options?.map(obj => {
            return {
              ...obj
            };
          });
        })
      ); */
    }
  }, [modalData]);

  const handleClose = () => {
    setTmpSymptom([]);
    onClose();
  };

  const handleSubmit = tmpSymptom => {
    console.log('UPDATERESULTINSIDE', tmpSymptom);
    let pass = false

    for(let i = 0; i < tmpSymptom.length; i++) {
      if(tmpSymptom[i] != null) {
        pass = true;
        break;
      }
    }

    if (tmpSymptom.length == 0 || pass == false) {
      swal({
        title: 'Empty detail',
        text: 'You must fill at least 1 detail',
        icon: 'error'
      });
    } else {
      onSubmit(tmpSymptom);
      handleClose();
    }
  };

  console.log('MDD SELECTED', tmpSymptom);

  return (
    <Modal open={modalData} onClose={() => handleClose()} style={{}}>
      <div
        style={{
          position: 'absolute',
          width: '27.5rem',
          backgroundColor: 'white',
          //borderWidth: 0,
          left: '50%',
          top: '50%',
          transform: 'translate(-50%, -50%)',
          //borderColor: 'transparent',
          borderRadius: '0.75rem',
          padding: '1.25rem'
        }}
      >
        <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between', marginBottom: '1.5rem' }}>
          <h4>
            <b>{modalData?.name}</b>
          </h4>
          <Button color={'secondary'} variant="contained" style={{ padding: 0 }} onClick={() => handleClose()}>
            X
          </Button>
        </div>

        {modalData?.symptoms_detail?.map((el, idx) => {
          /* let symptom = el.symptom_detail_options.map(sy => ({
          symptom_id: el.id,
          label: sy.options,
          symptom_detail_option_id: sy.id
        })); */
          //const [tmpSelected, setTmpSelected] = useState([])
          console.log(el, tmpSymptom, el?.options, 'WK');
          if (!el) {
            return;
          }
          return (
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <b>{el.detail_name}</b>
              </Grid>
              <Grid item xs={12} sm={12}>
                {/* {tmpSymptom && JSON.stringify(tmpSymptom[idx])} */}
                <MultipleSelect
                  label={''}
                  options={el.options}
                  selected={tmpSymptom[idx] ?? []}
                  setSelected={value => {
                    console.log('tmp', value);

                    console.log('tmp state', tmpSymptom);
                    let temp = [...tmpSymptom];
                    temp[idx] = value;
                    setTmpSymptom(temp);
                    console.log('MDD tmp', temp);
                  }}
                  /* 
                  syumptom state = [
                    tmpSymptom = [
                      frequency {symptom_id, symptom_detail_option_id},
                      consistency {symptom_id, symptom_detail_option_id}
                    ],
                    tmp stats = {object muntah}
                  ]
                  */
                />
              </Grid>
            </Grid>
          );
        })}
        <br />
        <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'flex-end' }}>
          <Button
            color={'primary'}
            variant="contained"
            onClick={() => {
              handleSubmit(tmpSymptom);
            }}
          >
            Save
          </Button>
        </div>
      </div>
    </Modal>
  );
}
