import axios from '../../api/userAPI';
import { setErrorNotification } from '../snackbarNotification';

export const FETCH_BEGIN = 'appointment.list/FETCH_BEGIN';
export const FETCH_SUCCESS = 'appointment.list/FETCH_SUCCESS';
export const FETCH_FAILED = 'appointment.list/FETCH_FAILED';

const fetchBegin = () => ({ type: FETCH_BEGIN });
const fetchSuccess = data => ({ type: FETCH_SUCCESS, payload: data });
const fetchFailed = error => ({ type: FETCH_FAILED, payload: error });
export const fetchAppointment = () => async dispatch => {
  dispatch(fetchBegin());

  try {
    // const res = await axios('retrieve-appointment');
    const res = await axios('retrieve-booked-appointment-schedule');

    dispatch(fetchSuccess(res.data.result));
  } catch (error) {
    dispatch(fetchFailed(error.toString()));
    dispatch(setErrorNotification(error.toString()));
  }
};

export const fetchAppointmentByDateRange = (
  startDate,
  endDate
) => async dispatch => {
  dispatch(fetchBegin());

  try {
    const res = await axios(
      `retrieve-booked-appointment-schedule?start_time=${startDate}&end_time=${endDate}`
    );

    dispatch(fetchSuccess(res.data?.result || []));
  } catch (error) {
    dispatch(fetchFailed(error.toString()));
    dispatch(setErrorNotification(error.toString()));
  }
};

export const fetchAppointmentByDoctorAndDateRange = (
  doctorId,
  startDate,
  endDate
) => async dispatch => {
  dispatch(fetchBegin());

  try {
    const res = await axios(
      `retrieve-booked-appointment-schedule?doctor_id=${doctorId}&start_time=${startDate}&end_time=${endDate}`
    );

    dispatch(fetchSuccess(res.data?.result || []));
  } catch (error) {
    dispatch(fetchFailed(error.toString()));
    dispatch(setErrorNotification(error.toString()));
  }
};


export const fetchAppointmentByDoctor = (
  doctorId,
  startDate,
  endDate
) => async dispatch => {
  dispatch(fetchBegin());

  try {
    const res = await axios(
      `retrieve-booked-appointment-schedule?doctor_id=${doctorId}`
    );

    dispatch(fetchSuccess(res.data?.result || []));
  } catch (error) {
    dispatch(fetchFailed(error.toString()));
    dispatch(setErrorNotification(error.toString()));
  }
};


const initialState = {
  data: [],
  isFetching: false,
  error: null
};

const appointmentListReducer = (state = initialState, action) => {
  const { type, payload } = action;

  switch (type) {
    case FETCH_BEGIN:
      return { ...state, data: [], isFetching: true, error: null };
    case FETCH_SUCCESS:
      return { ...state, data: payload, isFetching: false };
    case FETCH_FAILED:
      return { ...state, isFetching: false, error: payload };

    default:
      return state;
  }
};

export default appointmentListReducer;
