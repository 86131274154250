import axios from 'axios';

const instance = axios.create({
  baseURL: `${process.env.REACT_APP_DIAGNOSIS_API_V3_CONFIG}/api/diagnosis/`,
  headers: { Authorization: 'Bearer ' + localStorage.getItem('token') }
});

instance.interceptors.request.use(
  function(config) {
    const token = localStorage.getItem('token');
    if (token) config.headers.Authorization = `Bearer ${token}`;
    return config;
  },
  function(error) {
    return Promise.reject(error);
  }
);

instance.interceptors.response.use(
  res => {
    return res;
  },
  err => {
    if (err.response.data.message === 'Token Expired') {
      localStorage.clear();
      window.location.reload();
    }
    return Promise.reject(err);
  }
);

export default instance;
