import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Checkbox, FormControlLabel, Grid, MenuItem } from '@material-ui/core';
import { useFormik } from 'formik';
import * as Yup from 'yup';

import { Card, CardBody, CardHeader } from '_metronic/_partials/controls';
import { TextField } from 'app/components/common/TextField';
// import { ActionBarAdd as ActionBar } from '../../components/common/ActionBar';
// import { fetchAnimalCategoryList } from '../../../redux/animalCategory/list';
// import { addAnimalType } from '../../../redux/animalType/ops';

const appointmentCategory = [
  {
    name : "Poliklinik",
    id : 1
  },
  {
    name : "Grooming",
    id : 2
  },
  {
    name : "Operasi",
    id : 3
  },
  {
    name : "RTWA",
    id : 4
  },
  {
    name : "Rawin",
    id : 5
  },
]

const AppointmentTypeForm = ({
  isEditing,
  values,
  handleChange,
  setFieldValue,
  touched,
  errors,
  handleSubmit,
  actionBar
}) => {
  // // Prereq Hooks
  // const dispatch = useDispatch();

  // // Animal Category
  // const { data: animalCategoryList } = useSelector(
  //   state => state.animalCategory.list
  // );
  // useEffect(() => {
  //   dispatch(fetchAnimalCategoryList());
  // }, []);

  // const { completed } = useSelector(state => state.animalType.ops);
  // const isFetching = useSelector(state => state.loading.animalType);
  // useEffect(() => {
  //   if (completed) {
  //     resetForm();
  //   }
  // }, [completed]);

  // const {
  //   values,
  //   errors,
  //   touched,
  //   handleChange,
  //   handleSubmit,
  //   handleReset,
  //   setFieldValue,
  //   setFieldError,
  //   resetForm
  // } = useFormik({
  //   initialValues: {
  //     category: '',
  //     name: ''
  //   },
  //   validationSchema: Yup.object({
  //     category: Yup.string().required('Cannot be empty'),
  //     name: Yup.string().required('Cannot be empty')
  //   }),
  //   onSubmit: values => {
  //     dispatch(addAnimalType(values));
  //   }
  // });

  console.log("category_id", values.category_id)

  return (
    <Card>
      <CardHeader title={"Appointment Type Details"}></CardHeader>
      <CardBody>
        <form onSubmit={handleSubmit}>
          <Grid container spacing={2}>
            <Grid item xs={12} md={6}>
              <TextField
                disabled={!isEditing}
                id="name"
                value={values.name}
                onChange={handleChange}
                label="Appointment Type Name *"
                helperText={touched.name ? errors.name : ''}
                error={touched.name && errors.name ? true : false}
                autoComplete="off"
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <TextField
                disabled={!isEditing}
                id="duration"
                select
                value={values.duration}
                onChange={event => {
                  setFieldValue('duration', event.target.value);
                }}
                label="Duration *"
                helperText={touched.duration ? errors.duration : ''}
                error={touched.duration && errors.duration ? true : false}
              >
                <MenuItem value={1}>10 Minutes</MenuItem>
                <MenuItem value={2}>20 Minutes</MenuItem>
                <MenuItem value={3}>30 Minutes</MenuItem>
                <MenuItem value={4}>40 Minutes</MenuItem>
                <MenuItem value={5}>50 Minutes</MenuItem>
                <MenuItem value={6}>60 Minutes</MenuItem>
                <MenuItem value={7}>70 Minutes</MenuItem>
                <MenuItem value={8}>80 Minutes</MenuItem>
                <MenuItem value={9}>90 Minutes</MenuItem>
                <MenuItem value={10}>100 Minutes</MenuItem>
                <MenuItem value={11}>110 Minutes</MenuItem>
                <MenuItem value={12}>120 Minutes</MenuItem>
              </TextField>
            </Grid>
            <Grid item xs={12} md={12}>
              <TextField
                id="category"
                select
                value={values.category_id ? values.category_id : 99}
                onChange={event => {
                  setFieldValue('category_id', event.target.value);
                }}
                label="Category"
              >
                {/*{operationOptions.map(obj => (*/}
                {/*  <MenuItem key={obj} value={obj}>*/}
                {/*    {`${obj}`}*/}
                {/*  </MenuItem>*/}
                {/*))}*/}
                {appointmentCategory.map((obj,key) => (
                  <MenuItem
                    key={obj.id}
                    value={obj.id}
                  >
                    {`${
                      obj.name
                    }`}
                  </MenuItem>
                ))}
              </TextField>
            </Grid>

            {/*<Grid item xs={12} md={6}>*/}
            {/*  <FormControlLabel*/}
            {/*    control={*/}
            {/*      <Checkbox*/}
            {/*        checked={values.isPaymentProofRequired}*/}
            {/*        onChange={() => {*/}
            {/*          setFieldValue(*/}
            {/*            'isPaymentProofRequired',*/}
            {/*            !values.isPaymentProofRequired*/}
            {/*          );*/}
            {/*        }}*/}
            {/*        color="primary"*/}
            {/*      />*/}
            {/*    }*/}
            {/*    label="Require payment proof"*/}
            {/*  />*/}
            {/*</Grid>*/}

            {actionBar}
            {/* <ActionBar
              isFetching={isFetching}
              handleSubmit={handleSubmit}
              handleReset={handleReset}
            /> */}
          </Grid>
        </form>
      </CardBody>
    </Card>
  );
};

export default AppointmentTypeForm;
