import ApiRequest from '../../utils/APIRequest';

export default class Doctor {

  static getAll = async () => {
    return await ApiRequest.set('v1/doctors', 'GET');
  };

  getDoctorById = async (id) => {
    return await ApiRequest.set('v1/doctor/'+id, 'GET');
  };

  deleteDoctorById = async (id) => {
    return await ApiRequest.set('v1/doctor/'+id, 'DELETE')
  }

  edit = async (id, values) => {
    return await ApiRequest.set('v1/doctor/'+id, 'PUT', values)
  }

  create = async (values) => {
    return await ApiRequest.set('v1/doctor', 'POST', values)
  }

  static getDoctorByBookingType = async (booking_type) => {
    return await ApiRequest.set('v1/doctors/type?booking_type='+booking_type, 'GET');
  };

  static getDoctorWithSameBookingTypeWithAppointmentId = async (appointment_id) => {
    return await ApiRequest.set('v1/doctors/sameTypeByAppointment?appointment_id='+appointment_id, 'GET');
  };

}
