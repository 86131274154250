import { addMinutes, parseISO, format } from 'date-fns';

import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(theme => ({
  root: {
    padding: '2rem',
    backgroundColor: theme.palette.common.white,
    minHeight: '100%',
    fontSize: '16px',

    '& p': {
      marginBottom: 0,
      fontSize: '1em'
    }
  },
  title: {
    marginBottom: '1rem',
    fontSize: '1.5em'
  },
  image: {
    marginBottom: '1rem'
  },
  field: {
    marginBottom: '1rem'
  },
  label: {
    fontWeight: 'bold',
    fontSize: '0.9em !important'
  },
  rtwaFormGroup: {
    marginTop: '2rem'
  }
}));

const MedicalRecordPrintPreviewPage = () => {
  const classes = useStyles();

  const medicalRecordDetail =
    JSON.parse(localStorage.getItem('print-medicalRecord')) || undefined;
  const patientDetail =
    JSON.parse(localStorage.getItem('print-medicalRecord-patient')) ||
    undefined;

  console.log(medicalRecordDetail);
  console.log(patientDetail);

  const date = parseISO(medicalRecordDetail?.date_created);

  if (!medicalRecordDetail || !patientDetail)
    return (
      <p>
        Something went wrong, please try to print again from Appointment page
      </p>
    );

  return (
    <div className={classes.root}>
      <h3 className={classes.title}>Patient Detail</h3>
      {patientDetail.picture && patientDetail.picture !== 'No Attachment' && (
        <div className={classes.image}>
          <img
            src={`${process.env.REACT_APP_USER_API_V3_CONFIG}/api/image?image_name=${patientDetail.picture}`}
            alt="patient"
            style={{ height: '160px', marginBottom: '1rem' }}
          />
        </div>
      )}

      <FormField value={patientDetail.patient_name} label="Name" />
      <FormField value={patientDetail.age_string} label="Age" />

      <h3 className={classes.title} style={{ marginTop: '2rem' }}>
        Medical Record
      </h3>

      <FormField value={format(date, 'dd MMMM yyyy')} label="Date" />
      <FormField value={medicalRecordDetail.description} label="Description" />
      <FormField value={medicalRecordDetail.medication} label="Medication" />

      {medicalRecordDetail.file_attachment &&
        medicalRecordDetail.file_attachment !== 'No Attachment' && (
          <div className={classes.image}>
            <img
              src={`${process.env.REACT_APP_USER_API_V3_CONFIG}/api/image?image_name=${medicalRecordDetail.file_attachment}`}
              alt="attachment"
              style={{ width: '50%' }}
            />
          </div>
        )}

      {/* <FormField
        value={appointmentDetail.booking_type_name}
        label="Appointment Type"
      />
      <FormField value={appointmentDetail.customer_name} label="Nama Owner" />
      <FormField value={appointmentDetail.patient_name} label="Name Pasien" />
      <FormField
        value={appointmentDetail.customer_mobile}
        label="No. HP Customer"
      />
      <FormField value={patientDetail.animal_name} label="Jenis Hewan" />
      <FormField value={patientDetail.age_string} label="Usia Hewan" />
      <FormField value={patientDetail.breed} label="Ras" />
      <FormField value={patientDetail.gender} label="Gender" />
      <FormField
        value={appointmentDetail.doctor_name}
        label="Praktisi/Dokter"
      />
      <FormField
        value={format(date, 'dd MMMM yyyy')}
        label="Appointment Date"
      />
      <FormField
        value={`${format(date, 'HH:mm')} - ${format(endTime, 'HH:mm')}`}
        label="Appointment Time"
      />
      {appointmentDetail.booking_type_name.includes(
        'RAJANTI TALKS WITH ANIMALS'
      ) && <RTwAFormGroup description={appointmentDetail.additional_storage} />}
      {appointmentDetail.proof_of_payment && (
        <>
          <hr />
          <p className={classes.label}>Payment Proof</p>
          <img
            src={`${process.env.REACT_APP_USER_API_V3_CONFIG}/api/image?image_name=${appointmentDetail.proof_of_payment}`}
            alt="payment-proof"
            style={{ maxWidth: '50%', maxHeight: '50%' }}
          />
        </>
      )} */}
    </div>
  );
};

export default MedicalRecordPrintPreviewPage;

const FormField = ({ label, value }) => {
  const classes = useStyles();

  return (
    <div className={classes.field}>
      <p className={classes.label}>{label}</p>
      <p>{value}</p>
    </div>
  );
};

const RTwAFormGroup = ({ description }) => {
  const classes = useStyles();

  return (
    <div className={classes.rtwaFormGroup}>
      <h3>Pertanyaan Yang Ingin Ditanyakan</h3>
      {/* {JSON.parse(description)?.map((question, i) => (
        <FormField value={question} label={`Question ${i + 1}`} />
      ))} */}
      <p style={{ whiteSpace: 'pre-line' }}>{description}</p>
    </div>
  );
};
