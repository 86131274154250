import ApiRequest from '../../utils/APIRequest2';

export default class Disease {

  static getAll = async () => {
    return await ApiRequest.set('v1/diseases', 'GET');
  };

  getDiseaseById = async (id) => {
    return await ApiRequest.set('v1/disease/'+id, 'GET');
  };

  deleteDiseaseById = async (id) => {
    return await ApiRequest.set('v1/disease/'+id, 'DELETE')
  }

  static edit = async (id, values) => {
    return await ApiRequest.set('v1/disease/'+id, 'PUT', values)
  } 

  static create = async (values) => {
    return await ApiRequest.set('v1/disease', 'POST', values)
  }

  static getRecent = async () => {
    return await ApiRequest.set('v1/diseases/recent', 'GET');
  };


}
