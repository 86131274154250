import React, {useEffect, useState} from 'react';
import {useHistory, Link, useParams} from 'react-router-dom';
import {useDispatch, useSelector} from 'react-redux';
import {ArrowBackIosOutlined} from '@material-ui/icons';
import {Grid, MenuItem, IconButton, Button} from '@material-ui/core';
import {useFormik} from 'formik';
import {Link as LinkIcon, Search as SearchIcon, Delete as DeleteIcon} from '@material-ui/icons';
import * as Yup from 'yup';
import InputAdornment from '@material-ui/core/InputAdornment';
import AppointmentCustomerList from './components/AppointmentCustomerList';
import KeyboardDatePicker from 'app/components/common/KeyboardDatePicker';
import moment from 'moment';

import {Card, CardBody} from '_metronic/_partials/controls';
import {TextField} from 'app/components/common/TextField';
import {ActionBarEdit} from 'app/components/common/ActionBar';

import OperationTable from 'app/models/OperationTable';
import {fetchAppointment} from '../../../redux/appointment/detail';
import swal from 'sweetalert2';
import TimePicker from '../../components/common/TimePicker/TimePicker';
import Appointment from '../../models/Appointment';
import {fetchPatientListByCustomerID} from 'redux/patient/list';

const AppointmentChangePage = ({isEditing, headerToolbar}) => {
  const history = useHistory();
  const {id} = useParams();
  const dispatch = useDispatch();

  const {data} = useSelector(state => state.appointment.detail);
  const [showDialogOwner, setShowDialogOwner] = useState(false)
  const [selectedOwner, setSelectedOwner] = useState(null)
  const [operationTableOptions, setOperationTableOptions] = useState([]);

  const {data: patientSelections} = useSelector(state => state.patient.list);
  const [selectedPets, setSelectedPets] = useState([])

  console.log('COP', patientSelections);

  useEffect(() => {
    console.log("SELECTEDOWNER", selectedOwner)
    if (selectedOwner != null) {
      dispatch(fetchPatientListByCustomerID(selectedOwner.id));
    }
  }, [selectedOwner]);


  useEffect(() => {
    dispatch(fetchAppointment(id));
  }, []);

  const {values, handleChange, handleSubmit, setFieldValue} = useFormik({
    initialValues: {
      description: data?.description || '',
      operationTable: data?.operation_table.id || null,
      start_time: data?.start_time || null,
      end_time: data?.end_time || null,
      date: null
    },
    validationSchema: Yup.object({
      description: Yup.string().max(3000)
    }),
    onSubmit: async values => {

      let params = {
        appointment_user_id : id,
        owner_id : selectedOwner.id,
        patient_ids : selectedPets.map(obj=>obj.id)
      }

      try{
        let appointmentModel = new Appointment()
        let result = await appointmentModel.appointmentEditOwnerIDAndPatientID(params)

        swal
            .fire({
              title: 'Success',
              text: 'Appointment edited successfully',
              icon: 'success',
              confirmButtonText: 'Go back',
              customClass: {
                container: 'swal-z'
              }
            })
            .then(result => {
              /* Read more about isConfirmed, isDenied below */
              if (result.isConfirmed) {
                history.goBack();
              }
            });

        console.log("COP res", result)
      }catch (e){
        console.log("COP err", e)
      }


      // const formattedValues = {
      //   description: values.description,
      //   start_time: values.start_time,
      //   end_time: values.end_time,
      //   operation_table_id: values.operationTable
      // };
      //
      // handleEdit(formattedValues);
    },
    enableReinitialize: 'true'
  });

  const fillOwnerValue = (owner) => {
    console.log('COP isi owner', owner)
    setSelectedOwner(owner)
    setSelectedPets([])

  }


  return (
    <>

      <AppointmentCustomerList
        showDialogOwner={showDialogOwner}
        toogleDialogOwner={showDialogOwner => setShowDialogOwner(false)}
        selectedOwner={ownerDetail => fillOwnerValue(ownerDetail)}
      >

      </AppointmentCustomerList>
      <Card>
        <div className="card-header">
          <div className="mt-4 card-title">
                        <span
                          style={{
                            cursor: 'pointer'
                          }}
                        >
                            <ArrowBackIosOutlined onClick={() => history.goBack()}></ArrowBackIosOutlined>
                        </span>
            <h3 className="ml-3 mt-3 mb-2">Change owner / pet</h3>
          </div>
        </div>
        <CardBody>
          <form onSubmit={handleSubmit}>
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <h4>Previous Data</h4>
              </Grid>
              <Grid item xs={12} md={6}>
                <Grid container>
                  <Grid item xs>
                    <TextField
                      disabled={true}
                      value={data?.owner?.user_name}
                      label="Owner *"
                      variant="outlined"
                      InputLabelProps={{shrink: true}}
                    />
                    <Grid item xs>
                      <IconButton size="small" color="primary">

                      </IconButton>
                    </Grid>
                  </Grid>
                  {data && (
                    <Grid item>
                      <Link to={`/customer/${data.customer_id}`}>
                        <IconButton size="small" color="primary">
                          <LinkIcon/>
                        </IconButton>
                      </Link>
                    </Grid>
                  )}
                </Grid>

              </Grid>

              <Grid item xs={12} md={6}>
                <Grid container>
                  <Grid item xs>
                    <TextField
                      value={data?.patients.map(p => p.patient_name + ' ')}
                      label="Pet Name *"
                      variant="outlined"
                      InputLabelProps={{shrink: true}}
                      disabled={true}
                    />
                  </Grid>
                </Grid>
              </Grid>

              <Grid item xs={12}>
                <h4>New Data : </h4>
              </Grid>

              <Grid item xs={11} sm={12}>
                <TextField
                  id="operationType"
                  label={selectedOwner && selectedOwner.user_name ? '' : 'Owner'}
                  value={selectedOwner && selectedOwner.user_name}
                  InputProps={{
                    readOnly: true,
                    endAdornment: (
                      <InputAdornment position="end"> <IconButton aria-label="toggle password visibility"
                                                                  onClick={() => {
                                                                    setShowDialogOwner(true)
                                                                  }}>
                        <SearchIcon> </SearchIcon></IconButton></InputAdornment>)
                  }}

                >
                </TextField>
              </Grid>

              <Grid item xs={12} md={12}>
                {
                  patientSelections.length > 0 &&
                  <TextField
                    id="patient"
                    select
                    value={values.operationTable}
                    onChange={(e) => {
                      console.log("COP", e.target.value)
                      let temp = [...selectedPets]

                      let existingPet = selectedPets.find(obj => obj.id === e.target.value)

                      if (existingPet) {
                        return
                      }

                      let newPet = patientSelections.find(obj => obj.id === e.target.value)

                      temp.push(newPet)
                      setSelectedPets(temp)
                    }}
                    label="Patient"
                    InputLabelProps={{shrink: !!values.operationTable}}
                  >
                    {patientSelections.map((obj, key) => (
                      <MenuItem key={key} value={obj.id}>
                        {`${obj.patient_name}`}
                      </MenuItem>
                    ))}
                  </TextField>
                }
              </Grid>

              <table class="table">
                <thead>
                <th style={{width: "80%"}}>Pet Name</th>
                <th>Action</th>
                </thead>
                <tbody>
                {
                  selectedPets.map(obj => {
                    return <tr>
                      <td>{obj.patient_name}</td>
                      <td>
                        <IconButton
                          onClick={()=>{
                            let temp = [...selectedPets]
                            let idx = selectedPets.findIndex(obj2=>obj2.id===obj.id)

                            temp.splice(idx,1)
                            setSelectedPets(temp)

                          }}
                        >
                          <DeleteIcon></DeleteIcon>
                        </IconButton>
                      </td>
                    </tr>
                  })
                }

                </tbody>
              </table>


              <ActionBarEdit isEditing={true} handleSubmit={handleSubmit} isDeleteButtonShown={false}/>
            </Grid>
          </form>
        </CardBody>
      </Card>
    </>
  );
};

export default AppointmentChangePage;
