import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { Button, Grid, MenuItem } from '@material-ui/core';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import { ArrowBackIosOutlined } from '@material-ui/icons';
import { Card, CardBody, CardHeader } from '_metronic/_partials/controls';
import TextField from 'app/components/common/TextField';
import { ActionBarAdd, ActionBarEdit } from 'app/components/common/ActionBar';

import Clinic from 'app/models/Clinic';
import File from 'app/models/File';
import swal from 'sweetalert2';
import { useParams, useHistory } from 'react-router-dom';

const ClinicEditPage = ({ data, headerToolbar }) => {
  const { completed } = useSelector(state => state.medicalRecord.add);
  const { id } = useParams();
  const history = useHistory();

  const [clinicData, setClinicData] = useState(null);

  useEffect(() => {
    getClinicDetails(id);
  }, [completed, resetForm]);

  useEffect(() => {}, []);

  const { values, handleChange, handleSubmit, handleReset, setFieldValue, resetForm } = useFormik({
    initialValues: {
      clinicName: clinicData?.name || '',
      address: clinicData?.address || '',
      attachment: clinicData?.image_url || null
    },
    validationSchema: Yup.object({
      clinicName: Yup.string().required('Cannot be empty'),
      address: Yup.string()
    }),
    onSubmit: formValues => {
      submitEditClinic(formValues);
    },
    enableReinitialize: 'true'
  });

  const getClinicDetails = async id => {
    let clinicModel = new Clinic();

    try {
      let result = await clinicModel.getClinicById(id);
      console.log(result);
      setClinicData(result);
    } catch (e) {
      console.log('ERROR' + e);
    }
  };

  const handleUploadImage = async imgBlob => {
    let uploadModel = new File();
    let imgUrl;

    try {
      imgUrl = await uploadModel.uploadFile(imgBlob);
      console.log(imgUrl);
      setFieldValue('attachment', imgUrl.location);
    } catch (e) {
      console.log('error: ', e);
    }
  };
  const submitEditClinic = async formValues => {
    console.log(formValues);
    let clinicModel = new Clinic();

    let formData = {
      name: formValues.clinicName,
      address: formValues.address,
      image_url: formValues.attachment,
      type: formValues.clinicType
    };

    try {
      let result = await clinicModel.editClinic(id, formData);
      console.log(result);
      handleReset();
      swal.fire({
        title: 'Success',
        text: 'Clinic edited successfully',
        icon: 'success',
        confirmButtonText: 'Go back',
        customClass: {
          container: 'swal-z'
        }
      });
    } catch (e) {
      console.log('ERROR' + e);
    }
  };

  const clinicOptions = ['SMALL', 'BIG'];

  return (
    <Card>
      <div className="card-header">
        <div className="mt-4 card-title">
          <span
            style={{
              cursor: 'pointer'
            }}
          >
            <ArrowBackIosOutlined onClick={() => history.goBack()}></ArrowBackIosOutlined>
          </span>
          <h3 className="ml-3 mt-3 mb-2">Edit {clinicData?.name}</h3>
        </div>
      </div>
      <CardBody>
        <form onSubmit={handleSubmit}>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <TextField value={values.clinicName} onChange={handleChange} name="clinicName" label="Clinic Name" />
            </Grid>
            <Grid item xs={12}>
              <TextField value={values.address} onChange={handleChange} name="address" label="Address" />
            </Grid>
            <Grid item xs={12}>
              <img src={values.attachment} alt={values.clinicName} style={{ width: '370px' }} />
            </Grid>
            <Grid item xs={12}>
              <input
                id="attachment"
                onChange={event => {
                  handleUploadImage(event.currentTarget.files[0]);
                }}
                accept="image/*"
                style={{ display: 'none' }}
                multiple
                type="file"
              />
              <label htmlFor="attachment">
                <Button color="primary" variant="outlined" component="span">
                  Edit Image
                </Button>
                <span style={{ marginLeft: '1rem' }}>{values.attachment?.name || ''}</span>
              </label>
            </Grid>
            <ActionBarEdit isEditing={true} handleSubmit={handleSubmit} handleDelete={handleReset} />
          </Grid>
        </form>
      </CardBody>
    </Card>
  );
};

export default ClinicEditPage;
