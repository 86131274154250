import ApiRequest from '../../utils/APIRequest';

export default class File {
  uploadFiles = async files => {
    return await ApiRequest.set('v1/upload/files', 'POST', files);
  };

  uploadFile = async file => {
    let formData = new FormData();

    formData.append('upload', file, file.name);
    console.log(formData);
    return await ApiRequest.setMultipart('v1/upload/file', 'POST', formData);
  };

  downloadFiles = async (id, clinicData) => {
    return await ApiRequest.set('v1/download/files', 'POST', clinicData); //ini perlu di benerin
  };
}
