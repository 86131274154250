import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Button, IconButton } from '@material-ui/core';
import { Delete as DeleteIcon } from '@material-ui/icons';

import {
  Card,
  CardBody,
  CardHeader,
  CardHeaderToolbar
} from '../../../_metronic/_partials/controls';
import { ListHeaderToolbar as HeaderToolbar } from '../../components/common/HeaderToolbar';
import { DataTableLocal as DataTable } from '../../components/common/DataTable';
import Dialog from 'app/components/common/Dialog';

import { fetchAppointmentTypeList, fetchAppointmentTypeListNEO } from '../../../redux/appointmentType/list';
import { deleteAppointmentType } from 'redux/appointmentType/ops';

const AppointmentTypeListPage = () => {
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(fetchAppointmentTypeListNEO());
  }, []);

  const { data, isFetching } = useSelector(state => state.appointmentType.list);
  const { completed } = useSelector(state => state.appointmentType.ops);

  const [selectedAppointmentType, setSelectedAppointmentType] = useState();
  const [
    isDeleteConfirmationDialogOpen,
    setIsDeleteConfirmationDialogOpen
  ] = useState(false);

  useEffect(() => {
    setIsDeleteConfirmationDialogOpen(false);
    dispatch(fetchAppointmentTypeListNEO());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [completed]);

  const columns = [
    {
      dataField: 'booking_type_name',
      text: 'Booking type'
    },
    {
      dataField: 'duration',
      text: 'Duration',
      formatter: cell => `${cell}0 mins`
    },
    {
      dataField: 'payment_proof_required',
      text: 'Require payment proof',
      formatter: cell => (cell ? 'Required' : 'Not required')
    },
    {
      dataField: 'category',
      text: 'Category',
      formatter: cell => `${cell?.name ? cell?.name : "-"}`
    },
    {
      dataField: 'id',
      text: 'Actions',
      formatter: (cell, row, rowIndex) => (
        <IconButton
          onClick={() => {
            setSelectedAppointmentType(row.booking_type_name);
            setIsDeleteConfirmationDialogOpen(true);
          }}
          size="small"
        >
          <DeleteIcon />
        </IconButton>
      ),
      classes: 'text-right pr-0',
      headerClasses: 'text-right pr-3',
      style: {
        minWidth: '50px'
      }
    }
  ];

  const handleDeleteClick = () => {
    dispatch(deleteAppointmentType(selectedAppointmentType));
  };

  return (
    <>
      <Card>
        <CardHeader title="Appointment type list">
          <CardHeaderToolbar>
            <HeaderToolbar addLink="/appointmentType/add" addText="New Type" />
          </CardHeaderToolbar>
        </CardHeader>
        <CardBody>
          <DataTable
            data={data}
            loading={isFetching}
            totalDataCount={data.length}
            columns={columns}
            editLink="/appointmentType/"

          />
        </CardBody>
      </Card>
      <Dialog
        open={isDeleteConfirmationDialogOpen}
        handleClose={() => setIsDeleteConfirmationDialogOpen(false)}
        title="Delete appointment type?"
        content="This action cannot be undone"
        action={
          <Button
            onClick={handleDeleteClick}
            variant="contained"
            color="secondary"
          >
            Delete appointment type
          </Button>
        }
      />
    </>
  );
};

export default AppointmentTypeListPage;
