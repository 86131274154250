import { MenuItem, Checkbox, ListItemText, Chip, OutlinedInput, InputLabel } from '@material-ui/core';
import React from 'react';

import Select from 'react-select';

const MultipleSelect = ({ selected, setSelected, options, label, isMulti }) => {
  const handleChange = event => {
    setSelected(event);
  };

  return (
    <>
      <InputLabel style={{marginBottom: '0.25rem'}}>{label}</InputLabel>
      <Select
        isMulti={isMulti ?? true}
        fullWidth
        value={selected ?? []}
        onChange={handleChange}
        options={options}
        styles={{
          // Fixes the overlapping problem of the component
          menu: provided => ({ ...provided, zIndex: 9999 })
        }}
        input={<OutlinedInput id="select-multiple-chip" />}
        /* renderValue={selected => (
          <div
            style={{
              display: 'flex',
              flexWrap: 'wrap',
              padding: '0rem'
            }}
          >
            {selected.map(el => (
              <Chip
                key={el.value}
                label={el.label}
                onMouseDown={(event) => {
                  event.stopPropagation();
                }}
                onDelete={e => {
                  /* alert('You clicked the delete icon.');
                  setSelected(selected.filter(el => el != e.target.value));
                   //Kenapa ga keklik ya?
                  let temp = [...selected]
                  console.log(temp, "temp", temp.filter(a => el != a), el)
                  setSelected(temp.filter(a => el != a));
                }}
                style={{
                  margin: 2,
                  marginTop: 0,
                  marginBottom: 0,
                  borderRadius: '0.25rem'
                }}
              />
            ))}
          </div>
        )} */
        style={{marginTop: 0}}
      >
        {/* {options?.map((el, idx) => {
          return (
            <MenuItem key={el.value} value={el}>
              <Checkbox checked={selected?.indexOf(el) > -1} />
              <ListItemText primary={el.label} />
            </MenuItem>
          );
        })} */}
      </Select>
    </>
  );
};
export default MultipleSelect;
