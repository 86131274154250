/**
 * @todo: fix the dynamic time range
 */
import { useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  Button,
  Dialog,
  DialogActions,
  DialogTitle,
  DialogContent,
  Grid,
  MenuItem,
  Popover
} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { addMinutes, format, parseISO } from 'date-fns';
import _ from 'lodash';

import TextField from 'app/components/common/TextField';
import DatePicker from 'app/components/common/DatePicker';
import AppointmentTimeSlot from './AppointmentTimeSlot';
import { TIME_SLOT, TIME_SLOT_GROOMING } from './AppointmentTimeSlot';
import { approveAppointment } from 'redux/appointment/approve';

const useStyles = makeStyles(theme => ({
  container: {
    backgroundColor: 'rgba(0, 0, 0, 0.25)'
  },
  paper: {
    width: '600px'
  },
  title: {
    marginBottom: '2rem',
    padding: '2rem 2rem 1rem 2rem',
    borderBottom: `1px solid ${theme.palette.divider}`
  },
  timeSlotContainer: {
    maxWidth: '360px'
  },
  actionContainer: {
    padding: '1.5rem 2rem 1.5rem 2rem',
    borderTop: `1px solid ${theme.palette.divider}`
  }
}));

const ApproveAppointmentDialog = ({ open, handleClose, data }) => {
  const dispatch = useDispatch();
  const classes = useStyles();

  const { completed, isLoading } = useSelector(
    state => state.appointment.approve
  );

  useEffect(() => {
    if (completed) {
      handleClose();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [completed]);

  const handleApproveClick = () => {
    dispatch(approveAppointment(data.id));
  };

  if (!data) return null;

  const startTime = data?.start_time
    ? format(parseISO(data?.start_time), 'HH:mm')
    : '...';
  const endTime = data?.end_time
    ? format(parseISO(data?.end_time), 'HH:mm')
    : '...';

  return (
    <Dialog
      open={open}
      onClose={handleClose}
      classes={{ container: classes.container, paper: classes.paper }}
    >
      <DialogTitle className={classes.title}>Appointment Details</DialogTitle>
      <DialogContent>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <TextField
              disabled
              value={data?.customer_name || ''}
              label="Owner"
            />
          </Grid>
          <Grid item xs={12}>
            <TextField
              disabled
              value={data?.patient_name || ''}
              label="Patient"
            />
          </Grid>
          <Grid item xs={12}>
            <TextField
              disabled
              value={data?.doctor_name || ''}
              label="Doctor"
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <TextField
              disabled
              value={
                (data?.start_time &&
                  format(parseISO(data.start_time), 'dd MMM yyyy')) ||
                ''
              }
              label="Date"
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <TextField
              disabled
              value={`${startTime} - ${endTime}`}
              label="Date"
            />
          </Grid>
        </Grid>
      </DialogContent>
      <DialogActions className={classes.actionContainer}>
        <Grid container alignItems="stretch">
          <Grid item xs={12}>
            <Grid
              container
              alignItems="flex-end"
              justify="flex-end"
              style={{ height: '100%' }}
            >
              <Grid item>
                <Button
                  onClick={handleApproveClick}
                  color="primary"
                  variant="contained"
                >
                  Approve
                </Button>
              </Grid>
              {/* <Grid item style={{ marginLeft: '0.5rem' }}>
                <Button color="secondary" variant="outlined">
                  Reject
                </Button>
              </Grid> */}
            </Grid>
          </Grid>
        </Grid>
      </DialogActions>
    </Dialog>
  );
};

export default ApproveAppointmentDialog;
