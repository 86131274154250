/**
 * High level router.
 *
 * Note: It's recommended to compose related routes in internal router
 * components (e.g: `src/app/modules/Auth/pages/AuthPage`, `src/app/BasePage`).
 */

import React from 'react';
import {
    // Redirect,
    Switch,
    Route, useLocation
} from 'react-router-dom';
import {
  // shallowEqual,
  useSelector
} from 'react-redux';
import { Layout } from '../_metronic/layout';
import BasePage from './BasePage';
// import { Logout, AuthPage } from "./modules/Auth";
// import ErrorsPage from "./modules/ErrorsExamples/ErrorsPage";

import LoginPage from './pages/login/login';
import { ErrorPage1 } from './modules/ErrorsExamples/ErrorPage1';
import AppointmentPrintPreviewPage from 'app/pages/appointment/AppointmentPrintPreviewPage';
import MedicalRecordPrintPreviewPage from 'app/pages/medicalRecord/MedicalRecordPrintPreviewPage';
import DiagnoseLayout from "../detector-layout/DiagnoseLayout";
import SamplePage from "../detector-layout/SamplePage";

import { ROUTE_CONFIG } from './RouteConfig';

export function Routes() {
  
  const { user, token } = useSelector(state => state.auth);
  const { doctorID, role } = useSelector(state => state.auth);

    const location = useLocation();
    const handleRender = () => {
        console.log('isi location.pathname', location.pathname)
        if(isAuthorized()){
            if(location?.pathname.includes('/diagnose')){
              return renderDiagnose()
            }else{
              return renderHome()
            }
        }else{
           return renderLogin()
        }
    }
  const isAuthorized = () => {
    if (user && token) {
      return true;
    } else {
      return false;
    }
  };

  const renderLogin = () => {
    return <Route component={LoginPage} />;
  };

  const renderHome = () => {
    return (
      <Layout>
        <BasePage />
      </Layout>
    );
  };

  const renderDiagnose = () => {
     // return <Route component={DecoratorLayout} />;
      return (
          // <Route path='/diagnose/:' >
              <DiagnoseLayout>
                  <Switch>
                  {ROUTE_CONFIG.map(
                      ({ path, component, blackList }) =>
                        !blackList?.includes(role) && (
                          <Route exact path={path} component={component} key={path} />
                        )
                    )}
                  </Switch>
              </DiagnoseLayout>
          // </Route>
      )
  }

  return (
    <Switch>
      <Route exact path="/error" component={ErrorPage1} />
      <Route
        exact
        path="/print/appointment"
        component={AppointmentPrintPreviewPage}
      />
      <Route
        exact
        path="/print/medicalRecord"
        component={MedicalRecordPrintPreviewPage}
      />
      {handleRender()}
    </Switch>
  );

  // return (
  // 	<Switch>
  // 		{!isAuthorized ? (
  // 			/*Render auth page when user at `/auth` and not authorized.*/
  // 			<Route>
  // 				<AuthPage />
  // 			</Route>
  // 		) : (
  // 			/*Otherwise redirect to root page (`/`)*/
  // 			<Redirect from="/auth" to="/"/>
  // 		)}

  // 		<Route path="/error" component={ErrorsPage}/>
  // 		<Route path="/logout" component={Logout}/>

  // 		{!isAuthorized ? (
  // 			/*Redirect to `/auth` when user is not authorized*/
  // 			<Redirect to="/auth/login"/>
  // 		) : (
  // 			<Layout>
  // 				<BasePage/>
  // 			</Layout>
  // 		)}
  // 	</Switch>
  // );
}
