import { memo, useEffect, useState } from 'react';
import {
  Button,
  // Dialog,
  // DialogContent,
  // DialogTitle,
  Grid,
  Typography
} from '@material-ui/core';
import { Handle } from 'react-flow-renderer';

import Dialog from 'app/components/common/Dialog';
import { TextField } from 'app/components/common/TextField';

import { makeStyles } from '@material-ui/core/styles';

const useStyles = ({ selected }) =>
  makeStyles({
    node: {
      padding: '0.5rem 1rem',
      border: `1px solid ${selected ? '#2196f3' : '#555'}`,
      borderRadius: '4px'
    },
    editButton: {
      width: '100%',
      marginTop: '0.5rem'
    }
  });

const DiagnosisNode = ({ data, setData, selected }) => {
  const classes = useStyles({ selected })();

  const [isDialogOpen, setIsDialogOpen] = useState(false);
  const [question, setQuestion] = useState(data.question);

  const handleClick = () => {
    console.log(data);
    setIsDialogOpen(true);
  };

  const handleClose = () => {
    setIsDialogOpen(false);
  };

  const handleSave = () => {};

  return (
    <>
      <Handle
        type="target"
        position="left"
        style={{ background: '#555' }}
        onConnect={params => console.log('handle onConnect', params)}
      />
      <Grid className={classes.node}>
        <Typography>{data.question}</Typography>
        <Button
          onClick={handleClick}
          color="primary"
          size="small"
          className={classes.editButton}
        >
          Edit
        </Button>
      </Grid>
      <Handle
        type="source"
        position="right"
        id="a"
        style={{ background: '#555' }}
      />
      <Dialog
        open={isDialogOpen}
        handleClose={handleClose}
        title="Edit Question"
        content={
          <TextField
            value={question}
            onChange={event => setQuestion(event.target.value)}
          />
        }
        action={
          <Button variant="contained" color="primary" onClick={handleSave}>
            Save
          </Button>
        }
      />
    </>
  );
};

export default memo(DiagnosisNode);
