//ADD
import { useEffect, useState } from 'react';
import { Grid, MenuItem } from '@material-ui/core';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import { useHistory } from 'react-router-dom';
import { ArrowBackIosOutlined } from '@material-ui/icons';

import { Card, CardBody, CardHeader } from '_metronic/_partials/controls';
import TextField from 'app/components/common/TextField';
import { ActionBarAdd } from 'app/components/common/ActionBar';
import Nametag from 'app/models/Nametag';
import swal from 'sweetalert2';

const NametagAddPage = ({ data, isEditing = true, headerToolbar, mode }) => {
  let [list, setList] = useState([]);
  const history = useHistory();

  const fetchList = async () => {

    try {
      let result = await Nametag.getAll();

      setList(result);
    } catch (e) {
      console.log('ERROR' + e);
    }
  };
  useEffect(() => {
    fetchList();
  }, []);

  const { values, handleChange, handleSubmit, handleReset, setFieldValue } = useFormik({
    initialValues: {
      id: data?.id || '',
      tag: data?.tag || null,
      pet_id: data?.pet_id || null
    },
    validationSchema: Yup.object({
      id: Yup.string().required('Cannot be empty')
    }),
    onSubmit: formValues => {
      addNametag(formValues);
    }
  });

  const addNametag = formValues => {
    console.log(formValues);

    let model = new Nametag();

    try {
      let result = Nametag.create({
        tag: formValues.tag,
        pet_id:formValues.pet_id
      });
      swal.fire({
        title: 'Success',
        text: 'Nametag added successfully',
        icon: 'success',
        confirmButtonText: 'Go back',
        customClass: {
          container: 'swal-z'
        }
      });
      handleReset();
    } catch (e) {
      console.log('error: ', e);
    }
  };

  return (
      <Card>
        <div className="card-header">
          <div className="mt-4 card-title">
          <span
              style={{
                cursor: 'pointer'
              }}
          >
            <ArrowBackIosOutlined onClick={() => history.goBack()}></ArrowBackIosOutlined>
          </span>
            <h3 className="ml-3 mt-3 mb-2">Add Nametag</h3>
          </div>
        </div>
        <CardBody>
          <form onSubmit={handleSubmit}>
            <Grid container spacing={2}>

              <Grid item xs={12}>
                <TextField value={values.id} onChange={handleChange} name="id" label="Tag" />
              </Grid>
              <Grid item xs={12}>
                <TextField
                    id="pet_id"
                    select
                    value={values.pet_id}
                    onChange={event => {
                      setFieldValue('pet_id', event.target.value);
                    }}
                    label="Pet id *"
                >
                  {['a'].map(obj => (
                      <MenuItem key={obj} value={obj}>
                        {`${obj}`}
                      </MenuItem>
                  ))}
                </TextField>
              </Grid>
              <ActionBarAdd handleSubmit={handleSubmit} handleReset={handleReset} />
            </Grid>
          </form>
        </CardBody>
      </Card>
  );
};

export default NametagAddPage;
