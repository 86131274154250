import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Button, Grid, MenuItem } from '@material-ui/core';
import { Autocomplete } from '@material-ui/lab';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import { format } from 'date-fns';

import {
  Card,
  CardBody,
  CardHeader
} from '../../../_metronic/_partials/controls';
import { TextField } from '../../components/common/TextField';
import KeyboardDatePicker from '../../components/common/KeyboardDatePicker';
import { ActionBarAdd as ActionBar } from '../../components/common/ActionBar';
import { fetchCustomerList } from '../../../redux/customer/list';
import { fetchAnimalTypeList } from '../../../redux/animalType/list';
import { addPatient } from '../../../redux/patient/ops';
import { patientNotify } from '../../../redux/patient/ops';
import PatientModel from "../../../model/PatientModel";
import UploadModel from "../../models/File";
import {Spinner} from "react-bootstrap";
const PatientAddPage = () => {
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(fetchCustomerList());
    dispatch(fetchAnimalTypeList());
  }, []);

  const { data: ownerList } = useSelector(state => state.customer.list);
  const { data: animalTypeList } = useSelector(state => state.animalType.list);

  const { completed } = useSelector(state => state.patient.ops);
  const isFetching = useSelector(state => state.loading.patient);

  const [isLoading, setIsLoading] = useState(false)

  useEffect(() => {
    if (completed) {
      resetForm();
    }
  }, [completed]);

  const {
    values,
    errors,
    touched,
    handleChange,
    handleSubmit,
    handleReset,
    setFieldValue,
    setFieldError,
    resetForm
  } = useFormik({
    initialValues: {
      name: '',
      animalType: '',
      breed: '',
      owner: '',
      age: 0,
      ageMonth: 0,
      gender: 'MALE',
      patientImage: null,
      weight: '',
      microchip: '',
      tatoo: '',
      blood_type: '',
      allergy_type: '',
      dna: '',
    },
    validationSchema: Yup.object({
      name: Yup.string().required('Cannot be empty'),
      animalType: Yup.string().required('Cannot be empty'),
      breed: Yup.string().required('Cannot be empty'),
      owner: Yup.string().required('Cannot be empty'),
      // age: Yup.number()
      //   .min(0, 'Must be greater than 0')
      //   .required('Cannot be empty'),
      weight: Yup.number()
        .min(0, 'Must be greater than 0')
        .required('Cannot be empty'),
      gender: Yup.string().required('Cannot be empty')
    }),

    onSubmit: async values => {
      setIsLoading(true)
      const formattedValues = {
        ...values,
        pet_owner_id: values.owner.id,
        patient_name: values.name,
        patient_gender: values.gender,
        animal_type_id: values.animalType,
        age: {
          year: values.age,
          month: values.ageMonth
        }
      };

      if (values.patientImage) {
        try {
          let uploadModel = new UploadModel();
          let imgUrl = await uploadModel.uploadFile(values.patientImage);
          console.log("img url", imgUrl);
          if (imgUrl.location) {
            formattedValues.patient_picture = imgUrl.location
          }
        } catch (e) {
          console.log('error: ', e);
          setIsLoading(false)
        }
      }
     
      console.log('formatted : ', formattedValues)
      try {
        let result = await PatientModel.addPatient(formattedValues)
        console.log(result)
        dispatch(patientNotify());
        console.log("RESULT", result)
        setIsLoading(false)
      } catch (e) {
        console.log(e)
        setIsLoading(false)
      }
    }
  });

  const [ownerInput, setOwnerInput] = useState('');
  const [genderInput, setGenderInput] = useState('');

  return (
    <Card>
      <CardHeader title="Patient details"></CardHeader>
      <CardBody>
        <form onSubmit={handleSubmit}>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <TextField
                id="name"
                value={values.name}
                onChange={handleChange}
                label="Name *"
                helperText={touched.name && errors.name ? errors.name : ''}
                error={touched.name && errors.name ? true : false}
              />
            </Grid>
            <Grid item xs={12}>
              <Autocomplete
                value={values.owner}
                onChange={(event, newValue) => {
                  setFieldValue('owner', newValue);
                }}
                options={ownerList}
                getOptionLabel={option =>
                  option ? `${option.user_name} (${option.mobile})` : ''
                }
                inputValue={ownerInput}
                onInputChange={(event, newInputValue) => {
                  setOwnerInput(newInputValue);
                }}
                size="small"
                renderInput={params => (
                  <TextField
                    {...params}
                    helperText={
                      touched.owner && errors.owner ? errors.owner : ''
                    }
                    error={touched.owner && errors.owner ? true : false}
                    label="Owner *"
                    variant="outlined"
                  />
                )}
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                id="animalType"
                select
                value={values.animalType}
                onChange={event => {
                  setFieldValue('animalType', event.target.value);
                }}
                label="AnimalType *"
                helperText={touched.animalType ? errors.animalType : ''}
                error={touched.animalType && errors.animalType ? true : false}
              >
                {animalTypeList.map(({ id, animal_name }) => (
                  <MenuItem key={id} value={id}>
                    {animal_name}
                  </MenuItem>
                ))}
              </TextField>
            </Grid>
            <Grid item xs={12}>
              <TextField
                id="breed"
                value={values.breed}
                onChange={handleChange}
                label="Breed *"
                helperText={touched.breed && errors.breed ? errors.breed : ''}
                error={touched.breed && errors.breed ? true : false}
              />
            </Grid>
            <Grid item xs={3}>
              {/* <KeyboardDatePicker
                id="dateOfBirth"
                value={values.dateOfBirth}
                onChange={date => setFieldValue('dateOfBirth', date, true)}
                label="Date of Birth"
                helperText={
                  touched.dateOfBirth && errors.dateOfBirth
                    ? 'Invalid date'
                    : ''
                }
                error={touched.dateOfBirth && errors.dateOfBirth ? true : false}
                disableFuture
                clearable
                format="dd/MM/yyyy"
              /> */}
              <TextField
                id="age"
                value={values.age}
                onChange={handleChange}
                label="Age (years old) *"
                helperText={touched.age && errors.age ? errors.age : ''}
                error={touched.age && errors.age ? true : false}
                type="number"
              />
            </Grid>
            <Grid item xs={3}>
              <TextField
                select
                id="ageMonth"
                value={values.ageMonth}
                onChange={event => {
                  setFieldValue('ageMonth', event.target.value);
                }}
                label="Age (months old) *"
                helperText={
                  touched.ageMonth && errors.ageMonth ? errors.ageMonth : ''
                }
                error={touched.ageMonth && errors.ageMonth ? true : false}
              >
                <MenuItem value={0}>0</MenuItem>
                <MenuItem value={1}>1</MenuItem>
                <MenuItem value={2}>2</MenuItem>
                <MenuItem value={3}>3</MenuItem>
                <MenuItem value={4}>4</MenuItem>
                <MenuItem value={5}>5</MenuItem>
                <MenuItem value={6}>6</MenuItem>
                <MenuItem value={7}>7</MenuItem>
                <MenuItem value={8}>8</MenuItem>
                <MenuItem value={9}>9</MenuItem>
                <MenuItem value={10}>10</MenuItem>
                <MenuItem value={11}>11</MenuItem>
              </TextField>
            </Grid>
            <Grid item xs={6}>
              <TextField
                select
                id="gender"
                value={values.gender}
                label="Gender *"
                helperText={
                  touched.gender && errors.gender ? errors.gender : ''
                }
                onChange={(event, newValue) => {
                  console.log(newValue.props.value)
                  setFieldValue('gender', newValue.props.value);
                }}
                error={touched.gender && errors.gender ? true : false}
              >
                <MenuItem value="MALE">Male</MenuItem>
                <MenuItem value="FEMALE">Female</MenuItem>
              </TextField>
            </Grid>
            <Grid item xs={6}>
              <TextField

                id="weight"
                value={values.weight + ""}
                onChange={handleChange}
                label="Weight (Kg) *"
                helperText={
                  touched.weight && errors.weight ? errors.weight : ''
                }
                type={"number"}
                error={touched.weight && errors.weight ? true : false}
              />
            </Grid>
            <Grid item xs={6}>
              <TextField

                id="microchip"
                value={values.microchip}
                onChange={handleChange}
                label="Microchip"
                helperText={
                  touched.microchip && errors.microchip ? errors.microchip : ''
                }
                error={touched.microchip && errors.microchip ? true : false}
              />
            </Grid>
            <Grid item xs={6}>
              <TextField

                id="tatoo"
                value={values.tatoo}
                onChange={handleChange}
                label="Tattoo"
                helperText={
                  touched.tatoo && errors.tatoo ? errors.tatoo : ''
                }
                error={touched.tatoo && errors.tatoo ? true : false}
              />
            </Grid>
            <Grid item xs={6}>
              <TextField

                id="blood_type"
                value={values.blood_type}
                onChange={handleChange}
                label="Blood Type"
                helperText={
                  touched.blood_type && errors.blood_type ? errors.blood_type : ''
                }
                error={touched.blood_type && errors.blood_type ? true : false}
              />
            </Grid>
            <Grid item xs={6}>
              <TextField

                id="allergy_type"
                value={values.allergy_type}
                onChange={handleChange}
                label="Allergy Type"
                helperText={
                  touched.allergy_type && errors.allergy_type ? errors.allergy_type : ''
                }
                error={touched.allergy_type && errors.allergy_type ? true : false}
              />
            </Grid>
            <Grid item xs={6}>
              <TextField

                id="dna"
                value={values.dna}
                onChange={handleChange}
                label="DNA"
                helperText={
                  touched.dna && errors.dna ? errors.dna : ''
                }
                error={touched.dna && errors.dna ? true : false}
              />
            </Grid>
            <Grid item xs={12}>
              <input
                id="patientImage"
                onChange={event => {
                  setFieldValue('patientImage', event.currentTarget.files[0]);
                }}
                accept="image/*"
                style={{ display: 'none' }}
                multiple
                type="file"
              />
              <label htmlFor="patientImage">
                <Button color="primary" variant="outlined" component="span">
                  Upload Patient Image
                </Button>
                {
                  isLoading &&
                    <>&nbsp;&nbsp;<Spinner size={"sm"} animation="border" /></>
                }

                <br/>
                <span style={{ marginLeft: '1rem' }}>
                  {values.patientImage?.name || ''}
                </span>

              </label>
            </Grid>
            <ActionBar
              isFetching={isFetching}
              handleSubmit={handleSubmit}
              handleReset={handleReset}
            />
          </Grid>
        </form>
      </CardBody>
    </Card>
  );
};

export default PatientAddPage;
