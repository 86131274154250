import ApiRequest from '../../utils/APIRequest';

export default class Patient {
  static getAll = async () => {
    let result = await ApiRequest.setV1userAPI('api/user/retrieve-patient', 'GET');
    return result.result
  };
  static getById = async (id) => {
    let result = await ApiRequest.setV1userAPI(`api/user/retrieve-patient?id=${id}`, 'GET');
    return result.result[0]
  };
}
