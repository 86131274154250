import React from 'react';

import { CardHeaderToolbar } from '../../../../_metronic/_partials/controls';
import ButtonLink from '../ButtonLink';

export default function HeaderToolbarList({
  addLink,
  addText,
  additionalComponent
}) {
  return (
    <CardHeaderToolbar>
      {additionalComponent}
        {
            (addLink || addText ) ?
                <ButtonLink link={addLink} variant="contained" color="primary">
                    {addText}
                </ButtonLink>
                :
                null
        }

    </CardHeaderToolbar>
  );
}
