/* eslint-disable jsx-a11y/anchor-is-valid */
import React from "react";
import useBreadcrumbs from 'use-react-router-breadcrumbs';
import { Link } from "react-router-dom";

import { ROUTE_CONFIG } from '../../../../../app/RouteConfig';

// export function BreadCrumbs({ items }) {
//   if (!items || !items.length) {
//     return "";
//   }

//   return (
//     <div className="d-flex align-items-center font-weight-bold my-2">
//       <Link className="opacity-75 hover-opacity-100" to="/dashboard">
//         <i className="flaticon2-shelter text-white icon-1x" />
//       </Link>
//       {items.map((item, index) => (
//         <React.Fragment key={index}>
//           <span className="label label-dot label-sm bg-white opacity-75 mx-3" />
//           <Link
//             className="text-white text-hover-white opacity-75 hover-opacity-100"
//             to={{ pathname: item.pathname }}
//           >
//             {item.breadcrumb}
//           </Link>
//         </React.Fragment>
//       ))}
//     </div>
//   );
// }

export const BreadCrumbs = () => {
  const breadcrumbs = useBreadcrumbs(ROUTE_CONFIG);

  return (
    <div className="d-flex align-items-center font-weight-bold my-2">
      <Link className="opacity-75 hover-opacity-100" to="/">
        <i className="flaticon2-shelter text-white icon-1x" />
      </Link>
      {breadcrumbs.map(({match, breadcrumb}, index) => index > 0 ?
        <React.Fragment key={index}>
          <span className="label label-dot label-sm bg-white opacity-75 mx-3" />
          <Link
            className="text-white text-hover-white opacity-75 hover-opacity-100"
            to={{ pathname: match.url }}
          >
            {breadcrumb}
          </Link>
        </React.Fragment> :
        null
      )}
    </div>
  );
}
