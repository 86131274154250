import React, {useEffect, useState} from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  Card,
  CardBody,
  CardHeader,
  CardHeaderToolbar
} from '../../../_metronic/_partials/controls';
import Disease from '../../models/Disease'
import ButtonLink from '../../components/common/ButtonLink';
import { DataTableLocal as DataTable } from '../../components/common/DataTable';
import { fetchDiseaseList } from '../../../redux/disease/list';

const DiseaseListPage = () => {
  const [diseases, setDiseases] = useState([])
  const initialize = async () => {
    try{
      const result = await Disease.getAll()
      console.log("penyakit", result)
      setDiseases(result)
    }catch (e) {
        console.log('failed to retrive', e)
    }

  }
  useEffect(() => {
    initialize()
    // dispatch(fetchDiseaseList());
  }, []);
  //
  // const { data } = useSelector(state => state.disease.list);
  // const isFetching = useSelector(state => state.loading.disease);

  const columns = [
    {
      dataField: 'name',
      text: 'Disease'
    },
    {
      dataField: 'animal_type_name',
      text: 'Animal',
    },

    /* {
      dataField: 'disease_general_information',
      text: 'General Condition',
      formatter: (cell, row) => {
      return row?.disease_general_information?.map((v) => {
        return v?.value
      })?.join(', ')
      }

    },
    {
      dataField: 'animal_type_name',
      text: 'Sysmptomps',
      formatter: (cell, row) => {
        return '-'
      }
    }, */
  ];

  return (
    <Card>
      <CardHeader title="Disease list">
        <CardHeaderToolbar>
          <ButtonLink link="/disease/add" variant="contained" color="primary">
            New Disease
          </ButtonLink>
        </CardHeaderToolbar>
      </CardHeader>
      <CardBody>
        <DataTable
          data={diseases}
          // loading={isFetching}
          totalDataCount={diseases.length}
          columns={columns}
          editLink="/disease/"
        />
      </CardBody>
    </Card>
  );
};

export default DiseaseListPage;
