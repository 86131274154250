import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  Button,
  Grid,
  IconButton,
  MenuItem,
  TextField
} from '@material-ui/core';
import { Autocomplete } from '@material-ui/lab';
import CloseIcon from '@material-ui/icons/Close';
import AddIcon from '@material-ui/icons/Add';

import {
  Card,
  CardBody,
  CardHeader
} from '../../../_metronic/_partials/controls';
import CatAnatomyMap from './CatAnatomyMap';
import DogAnatomyMap from './DogAnatomyMap';
import DiagnosisResultCard from './DiagnosisResultCard';
import { fetchAnimalTypeList } from '../../../redux/animalType/list';
import { fetchSymptomList } from '../../../redux/symptom/list';
import { fetchSymptomLocationList } from '../../../redux/symptomLocation/list';
import { diagnose, resetDiagnosis } from '../../../redux/diagnosis';

const DiagnosePage = () => {
  // Prereq Hooks
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(fetchAnimalTypeList());
    dispatch(fetchSymptomList());
    dispatch(fetchSymptomLocationList());
  }, []);

  const { data: animalTypeList } = useSelector(state => state.animalType.list);
  const { data: symptomList } = useSelector(state => state.symptom.list);
  const { data: symptomLocationList } = useSelector(
    state => state.symptomLocation.list
  );

  const [animalType, setAnimalType] = useState('');
  const [location, setLocation] = useState('');
  const [symptom, setSymptom] = useState('');
  const [symptomInput, setSymptomInput] = useState('');
  const [
    filteredSymptomLocationList,
    setFilteredSymptomLocationList
  ] = useState([]);
  const filterSymptomLocationList = animalType => {
    setFilteredSymptomLocationList(
      symptomLocationList.filter(
        location => location.animal_type_id === animalType
      )
    );
  };

  useEffect(() => {
    filterSymptomLocationList(animalType);
  }, [animalType]);

  const handleHeadClick = () => {
    setLocation(getPartID(filteredSymptomLocationList, 'HEAD'));
  };

  const handleChestClick = () => {
    setLocation(getPartID(filteredSymptomLocationList, 'CHEST'));
  };

  const handleBodyClick = () => {
    setLocation(getPartID(filteredSymptomLocationList, 'BODY'));
  };

  const handleFrontLegClick = () => {
    setLocation(getPartID(filteredSymptomLocationList, 'FRONT LEG'));
  };

  const handleBellyClick = () => {
    setLocation(getPartID(filteredSymptomLocationList, 'BELLY'));
  };

  const handleHindLegClick = () => {
    setLocation(getPartID(filteredSymptomLocationList, 'HIND LEG'));
  };

  const handleHipsClick = () => {
    setLocation(getPartID(filteredSymptomLocationList, 'HIPS'));
  };

  const handleTailClick = () => {
    setLocation(getPartID(filteredSymptomLocationList, 'TAIL'));
  };

  const getCatID = () =>
    animalTypeList.find(animalTypeList => animalTypeList.animal_name === 'CAT')
      .id;

  const getDogID = () =>
    animalTypeList.find(animalTypeList => animalTypeList.animal_name === 'DOG')
      .id;

  const [selectedSymptoms, setSelectedSymptoms] = useState([]);
  const addToSelectedSymptoms = () => {
    const newSymptom = {
      symptomId: symptom.id,
      symptomName: symptom.symptom_name,
      locationId: location,
      locationName:
        symptomLocationList.find(
          symptomLocationListItem => symptomLocationListItem.id === location
        )?.part_name || ''
    };

    let isExist = false;
    for (let selectedSymptom of selectedSymptoms) {
      if (
        selectedSymptom.symptomId === newSymptom.symptomId &&
        selectedSymptom.locationId === newSymptom.locationId
      ) {
        isExist = true;
        break;
      }
    }

    if (!isExist) {
      setSelectedSymptoms([...selectedSymptoms, newSymptom]);
      setSymptom('');
      setLocation('');
    }
  };

  const deleteFromSelectedSymptoms = deletedIndex => {
    setSelectedSymptoms(
      selectedSymptoms.filter((symptom, index) => index !== deletedIndex)
    );
  };

  const handleDiagnosisClick = () => {
    const formattedData = {
      symptoms: selectedSymptoms.map(symptom => symptom.symptomId)
    };

    dispatch(diagnose(formattedData));
  };

  const handleResetClick = () => {
    setSelectedSymptoms([]);
    dispatch(resetDiagnosis());
  };

  const { data: diagnosis } = useSelector(state => state.diagnosis);
  const isFetching = useSelector(state => state.loading.diagnosis);

  useEffect(() => {
    handleResetClick();
  }, [animalType]);

  const anatomyProps = {
    handleHeadClick,
    handleChestClick,
    handleBodyClick,
    handleFrontLegClick,
    handleBellyClick,
    handleHindLegClick,
    handleHipsClick,
    handleTailClick
  };

  return (
    <Grid container spacing={2}>
      <Grid item xs={8}>
        <Card>
          <CardHeader title="Symptoms List"></CardHeader>
          <CardBody>
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <TextField
                  id="animalType"
                  select
                  value={animalType}
                  onChange={event => {
                    setAnimalType(event.target.value);
                  }}
                  label="Animal Type *"
                  fullWidth
                  variant="outlined"
                  size="small"
                  // helperText={touched.animalType ? errors.animalType : ''}
                  // error={touched.animalType && errors.animalType ? true : false}
                >
                  {animalTypeList.map(type => (
                    <MenuItem value={type.id} key={type.id}>
                      {type.animal_name}
                    </MenuItem>
                  ))}
                </TextField>
              </Grid>
              <Grid item xs={6}>
                <TextField
                  disabled={!animalType && true}
                  id="location"
                  select
                  value={location}
                  onChange={event => {
                    setLocation(event.target.value);
                  }}
                  label="Location"
                  fullWidth
                  variant="outlined"
                  size="small"
                >
                  <MenuItem value="">
                    <em>NONE</em>
                  </MenuItem>
                  {filteredSymptomLocationList.map(({ id, part_name }) => (
                    <MenuItem key={id} value={id}>
                      {part_name}
                    </MenuItem>
                  ))}
                </TextField>
                <CatAnatomyMap
                  {...anatomyProps}
                  show={animalType && animalType === getCatID() && true}
                />
                <DogAnatomyMap
                  {...anatomyProps}
                  show={animalType && animalType === getDogID() && true}
                />
              </Grid>
              <Grid item xs={6}>
                <Autocomplete
                  disabled={!animalType && true}
                  value={symptom}
                  onChange={(event, newValue) => {
                    setSymptom(newValue);
                  }}
                  options={symptomList}
                  getOptionLabel={option => option.symptom_name || ''}
                  inputValue={symptomInput}
                  onInputChange={(event, newInputValue) => {
                    setSymptomInput(newInputValue);
                  }}
                  size="small"
                  renderInput={params => (
                    <TextField
                      {...params}
                      // helperText={helperText}
                      // error={error}
                      label="Symptom *"
                      variant="outlined"
                    />
                  )}
                />
              </Grid>
              <Grid item xs={12} style={{ marginTop: '2rem' }}>
                <Button
                  disabled={(!animalType || !symptom) && true}
                  onClick={() => {
                    addToSelectedSymptoms();
                  }}
                  color="primary"
                  startIcon={<AddIcon />}
                  style={{ width: '100%' }}
                >
                  Add to Selected Symptoms
                </Button>
              </Grid>
            </Grid>
          </CardBody>
        </Card>
      </Grid>
      <Grid item xs={4}>
        <Card>
          <CardHeader title="Selected Symptoms"></CardHeader>
          <CardBody>
            <Grid container spacing={2}>
              {selectedSymptoms.length > 0 ? (
                <Grid
                  container
                  item
                  xs={12}
                  style={{ borderBottom: '1px solid black' }}
                >
                  <Grid
                    item
                    xs={6}
                    style={{ fontSize: '0.8rem', fontWeight: 'bold' }}
                  >
                    Symptom
                  </Grid>
                  <Grid
                    item
                    xs={4}
                    style={{ fontSize: '0.8rem', fontWeight: 'bold' }}
                  >
                    Location
                  </Grid>
                </Grid>
              ) : (
                <Grid item xs={12}>
                  No symptoms selected yet
                </Grid>
              )}
              {selectedSymptoms.map((symptom, index) => (
                <Grid container alignItems="center" item xs={12} key={index}>
                  <Grid item xs={6}>
                    {symptom.symptomName}
                  </Grid>
                  <Grid item xs={4}>
                    {symptom.locationName}
                  </Grid>
                  <Grid item xs>
                    <Grid container justify="flex-end">
                      <IconButton
                        onClick={() => deleteFromSelectedSymptoms(index)}
                        size="small"
                      >
                        <CloseIcon />
                      </IconButton>
                    </Grid>
                  </Grid>
                </Grid>
              ))}
              {selectedSymptoms.length > 0 && (
                <Grid
                  container
                  direction="column"
                  spacing={1}
                  item
                  xs={12}
                  style={{ marginTop: '1rem' }}
                >
                  <Grid item>
                    <Button
                      onClick={handleDiagnosisClick}
                      color="primary"
                      variant="contained"
                      style={{ width: '100%' }}
                    >
                      Diagnose
                    </Button>
                  </Grid>
                  <Grid item>
                    <Button
                      onClick={handleResetClick}
                      style={{ width: '100%' }}
                    >
                      Clear
                    </Button>
                  </Grid>
                </Grid>
              )}
            </Grid>
          </CardBody>
        </Card>
      </Grid>
      {diagnosis && (
        <Grid item xs={12}>
          <DiagnosisResultCard
            data={diagnosis}
            handleReset={handleResetClick}
          />
        </Grid>
      )}
    </Grid>
  );
};

export default DiagnosePage;

const getPartID = (partList, partName) => {
  return partList.find(location => location.part_name === partName).id;
};
