import { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { useSnackbar } from 'notistack';

const SnackbarNotifier = () => {
  const { enqueueSnackbar } = useSnackbar();

  const snackbarNotification = useSelector(state => state.snackbarNotification);
  useEffect(() => {
    const { message, variant } = snackbarNotification;
    message && variant && enqueueSnackbar(message, { variant });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [snackbarNotification]);

  return null;
};

export default SnackbarNotifier;
