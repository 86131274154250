import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Grid } from '@material-ui/core';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import { format } from 'date-fns';
import crypto from 'crypto';
import { Autocomplete } from "@material-ui/lab";
import {
  Card,
  CardBody,
  CardHeader,
  Select
} from '../../../_metronic/_partials/controls';
import {
  TextField,
  PasswordTextField
} from '../../components/common/TextField';
import KeyboardDatePicker from '../../components/common/KeyboardDatePicker';
import { ActionBarAdd as ActionBar } from '../../components/common/ActionBar';
import { addCustomer } from '../../../redux/customer/ops';
import ProvinceCity from '../../../utils/ProvinceCity'
const CustomerAddPage = () => {
  // Prereq Hooks
  const dispatch = useDispatch();

  const { completed } = useSelector(state => state.customer.ops);
  const isFetching = useSelector(state => state.loading.customer);
  useEffect(() => {
    if (completed) {
      resetForm();
    }
  }, [completed]);

  const {
    values,
    errors,
    touched,
    handleChange,
    handleSubmit,
    handleReset,
    setFieldValue,
    setFieldError,
    resetForm
  } = useFormik({
    initialValues: {
      name: '',
      password: '',
      email: '',
      phone: '',
      provinsi: '',
      kabupaten_kota: '',
      dateOfBirth: null
    },
    validationSchema: Yup.object({
      name: Yup.string().required('Cannot be empty'),
      password: Yup.string().required('Cannot be empty'),
      email: Yup.string(),
      phone: Yup.string().required('Cannot be empty'),
      // provinsi: Yup.string().required('Cannot be empty'),
      // kabupaten_kota: Yup.string().required('Cannot be empty'),
      dateOfBirth: Yup.date()
        .max(new Date(), 'Date cannot be in the future')
        .nullable()
    }),
    onSubmit: (values) => {
      console.log('isi values', values)
      // values.provinsi = values.provinsi.province
      // values.kabupaten_kota = values.kabupaten_kota.type + ' ' + values.kabupaten_kota.city_name
      const formattedValues = {
        ...values,
        dateOfBirth:
          values.dateOfBirth && format(values.dateOfBirth, 'yyyy-MM-dd')
      };
      console.log('isi values', values)
      dispatch(addCustomer(formattedValues));
    }
  });

  const [provinces, setProvinces] = useState([])
  const [cities, setCitites] = useState([])
  const initializeProvinces = () => {
    let res = ProvinceCity.listProvince().map((v) => {
      return v.province
    });

    setProvinces(res)
  }
  const dependentCities = (dataProvince) => {

    let res = ProvinceCity.getCityByProvince(dataProvince)
    console.log('hubla', res)
    setCitites(res)
  }
  useEffect(() => {
    initializeProvinces()
    dependentCities();
  }, [])
  return (
    <Card>
      <CardHeader title="Customer details"></CardHeader>
      <CardBody>
        <form onSubmit={handleSubmit}>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <TextField
                id="name"
                value={values.name}
                onChange={handleChange}
                label="Full Name *"
                helperText={touched.name && errors.name ? errors.name : ''}
                error={touched.name && errors.name ? true : false}
              />
            </Grid>
            <Grid item xs={12}>
              <PasswordTextField
                id="password"
                value={values.password}
                onChange={handleChange}
                label="Password *"
                helperText={
                  touched.password && errors.password ? errors.password : ''
                }
                error={touched.password && errors.password ? true : false}
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                id="email"
                value={values.email}
                onChange={handleChange}
                label="Email"
                helperText={touched.email && errors.email ? errors.email : ''}
                error={touched.email && errors.email ? true : false}
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                id="phone"
                value={values.phone}
                onChange={handleChange}
                label="Phone"
                helperText={touched.phone && errors.phone ? errors.phone : ''}
                error={touched.phone && errors.phone ? true : false}
              />
            </Grid>
            <Grid item xs={12}>
              <Autocomplete
                value={values.provinsi}
                onChange={(_, newValue) => {

                  setFieldValue('provinsi', newValue)
                  setFieldValue('kabupaten_kota', null)

                  dependentCities(newValue)
                }}

                options={provinces}
                getOptionLabel={option => (option ? `${option}` : '')}
                size="small"
                renderInput={params => <TextField {...params} variant="outlined" label="Province *" />}
                helperText={touched.provinsi && errors.provinsi ? errors.provinsi : ''}
                error={touched.provinsi && errors.provinsi ? true : false}
              />

            </Grid>
            <Grid item xs={12}>
              <Autocomplete
                value={values.kabupaten_kota}
                onChange={(_, newValue) => {
                  setFieldValue('kabupaten_kota', newValue, true)
                }}
                options={cities}
                getOptionLabel={option => (option ? `${option}` : '')}
                size="small"
                renderInput={params => <TextField {...params} variant="outlined" label="City *" />}
                helperText={touched.kabupaten_kota && errors.kabupaten_kota ? errors.kabupaten_kota : ''}
                error={touched.kabupaten_kota && errors.kabupaten_kota ? true : false}

              />

            </Grid>
            <Grid item xs={12}>
              <TextField
                id="address"
                value={values.address}
                onChange={handleChange}
                label="address"
                helperText={touched.address && errors.address ? errors.address : ''}
                error={touched.address && errors.address ? true : false}
              />
            </Grid>
            <Grid item xs={12}>
              <KeyboardDatePicker
                id="dateOfBirth"
                value={values.dateOfBirth}
                onChange={date => setFieldValue('dateOfBirth', date, true)}
                label="Date of Birth"
                helperText={
                  touched.dateOfBirth && errors.dateOfBirth
                    ? 'Invalid date'
                    : ''
                }
                error={touched.dateOfBirth && errors.dateOfBirth ? true : false}
                disableFuture
                clearable
                format="dd/MM/yyyy"
              />
            </Grid>

            <ActionBar
              isFetching={isFetching}
              handleSubmit={handleSubmit}
              handleReset={handleReset}
            />
          </Grid>
        </form>
      </CardBody>
    </Card>
  );
};

export default CustomerAddPage;
