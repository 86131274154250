import HorizontalSeparator from 'app/components/common/HorizontalSeparator/HorizontalSeparator';
import {
  Button,
  Grid,
  MenuItem,
  //Select,
  InputLabel,
  Input,
  OutlinedInput,
  Box,
  Chip,
  Checkbox,
  ListItemText,
  FormHelperText
} from '@material-ui/core';
import { Link, useHistory, useParams } from 'react-router-dom';
import { TextField } from '../../components/common/TextField';
import { Card, CardBody, CardHeader, CardHeaderToolbar } from '../../../_metronic/_partials/controls';
import { useState, useEffect } from 'react';
import MultipleSelect from 'app/components/common/MultipleSelect/MultipleSelect';
import Select from 'react-select';
import CreatableSelect from 'react-select/creatable';
import { Autocomplete } from '@material-ui/lab';
import swal from 'sweetalert';
import Swal from 'sweetalert2';
import SymptomModel from '../../models/Symptom';
import generalInfoModel from '../../models/GeneralInformation';

const SymptomEditPageV2 = () => {
  const { id } = useParams();
  const history = useHistory()

  const [symptomName, setSymptomName] = useState('');
  const [animalOptions, setAnimalOptions] = useState([]);
  //const [bodyPartOptions, setBodyPartOptions] = useState([]);
  const [temperatureOptions, setTemperatureOptions] = useState([]);
  const [drinkingOptions, setDrinkingOptions] = useState([]);
  const [eatingOptions, setEatingOptions] = useState([]);
  const [foodTypeOptions, setFoodTypeOptions] = useState([]);
  const [generalConditionOptions, setGeneralConditionOptions] = useState([]);
  //const [tempOptions, setTempOptions] = useState([]);

  const [selectedAnimal, setSelectedAnimal] = useState([]);
  const [selectedBodyPart, setSelectedBodyPart] = useState('');
  const [selectedTemperature, setSelectedTemperature] = useState([]);
  const [selectedDrinking, setSelectedDrinking] = useState([]);
  const [selectedEating, setSelectedEating] = useState([]);
  const [selectedFood, setSelectedFood] = useState([]);

  const [reactSelectValue, setReactSelectValue] = useState([]);

  const [symptomDetails, setSymptomDetails] = useState([
    //{name: '', options: ''}
  ]);

  let typeOptions = [
    {
      value: 'checkbox',
      label: 'Checkbox'
    },
    {
      value: 'radio',
      label: 'Radio'
    },
  ]

  let bodyPartOptions = [
    {
      value: 'Mouth',
      label: 'Mouth'
    },
    {
      value: 'Nose',
      label: 'Nose'
    },
    {
      value: 'Stomach',
      label: 'Stomach'
    },
    {
      value: 'Eye',
      label: 'Eye'
    },
    {
      value: 'Fur',
      label: 'Fur'
    },
    {
      value: 'Leg',
      label: 'Leg'
    },
    {
      value: 'Chest',
      label: 'Chest'
    },
    {
      value: 'Back',
      label: 'Back'
    },
    {
      value: 'Ribs',
      label: 'Ribs'
    },
    {
      value: 'Bone',
      label: 'Bone'
    },
    {
      value: 'Nerves',
      label: 'Nerves'
    },
    {
      value: 'Others',
      label: 'Others'
    }
  ];

  const getSymptomData = async () => {
    try {
      let Symptom = new SymptomModel();
      let symptomInfo = await Symptom.getSymptomById(id);
      console.log('abc', symptomInfo);

      setSymptomName(symptomInfo.name);
      setSelectedBodyPart({ label: symptomInfo.body_part, value: symptomInfo.body_part });

      setSymptomDetails(
        symptomInfo.symptoms_detail.map(s => {
          //setTempOptions([])
          let tempOptions = s.symptom_detail_options.map(sd => {
            /* let temp = {value: sd.options, label: sd.options}
                    setTempOptions[tempOptions, temp] */
            return { value: sd.options, label: sd.options, id: sd.id };
          });
          console.log(tempOptions);
          

          let indexType = typeOptions.findIndex(e => e.value == s.type)
          
          return {
            name: s.detail_name,
            //options: ''
            options: tempOptions,
            type: typeOptions[indexType],
            id: s.id
          };
        })
      );

      console.log(symptomDetails);
    } catch (e) {
      console.log(e);
    }
  };

  const handleDetailChange = (index, event) => {
    let data = [...symptomDetails];
    data[index][event.target.name] = event.target.value;
    setSymptomDetails(data);
  };

  const handleTypeChange = (index, event) => {
    let data = [...symptomDetails];
    data[index]['type'] = event;
    setSymptomDetails(data);
  };

  const handleOptionsChange = (index, event) => {
    console.log(event);
    let data = [...symptomDetails];
    /*     let newData = event[0].value
    for(let i = 1; i < event.length; i++) {
      newData = newData + ';' + event[i].value
    } */
    data[index]['options'] = event;
    setSymptomDetails(data);

    console.log(symptomDetails);
    //setReactSelectValue(event)
  };

  const addDetails = () => {
    let newDetail = { name: '', options: '' };

    setSymptomDetails([...symptomDetails, newDetail]);
  };

  const removeDetail = index => {
    let data = [...symptomDetails];
    data.splice(index, 1);
    setSymptomDetails(data);
  };

  const handleSubmit = async e => {
    try {
      if (symptomName == null || symptomName == '') {
        swal({
          title: 'Symptom name',
          text: 'Symptom name cannot be empty',
          icon: 'error'
        });
        return;
      }
      if (selectedBodyPart == null || selectedBodyPart == '') {
        swal({
          title: 'Body part',
          text: 'Body part must be selected',
          icon: 'error'
        });
        return;
      }

      let Symptom = new SymptomModel();
      //console.log("submitting", symptomDetails)
      //console.log("submitting", symptomName)
      //console.log("submitting", selectedBodyPart)
      //console.log("submitting", symptomDetails)

      let submitDetail = [];

      if (symptomDetails.length == 0) {
        swal({
          title: 'Symptom details',
          text: 'Symptom details must be filled',
          icon: 'error'
        });
        return;
      }

      for (let i = 0; i < symptomDetails.length; i++) {
        let name = symptomDetails[i].name;
        let id = symptomDetails[i].id;
        let optionsArr = symptomDetails[i].options.map(o => {
          return {
            value: o.value,
            id: o.id,
            options: o.value
          };
        });
        let type = symptomDetails[i].type;

        if (name == null || name == '') {
          swal({
            title: 'Symptom details name',
            text: 'Symptom details name must be filled',
            icon: 'error'
          });
          return;
        }
        if (optionsArr[0] == null || optionsArr[0] == '') {
          swal({
            title: 'Symptom details values',
            text: 'Symptom details values must be filled',
            icon: 'error'
          });
          return;
        }
        if (type == null || type == '') {
          swal({
            title: 'Symptom input type',
            text: 'Symptom input type must be filled',
            icon: 'error'
          });
          return;
        }

        submitDetail.push({ detail_name: name, options: optionsArr, type: type.value, id: id });
      }

      //console.log("submitting", submitDetail)

      let body = {
        name: symptomName,
        body_part: selectedBodyPart.value,
        details: submitDetail
      };

      console.log('submitting', body);

      // setSymptomName('')
      // setSelectedBodyPart('')
      // setSymptomDetails([
      //   {name: '', options: ''}
      // ])
      // setReactSelectValue([])

      let result = await Symptom.edit(id, body);

      //console.log(result)

      swal({
        title: 'Success',
        text: 'Symptom has been updated',
        icon: 'success'
      }); /* .then(
          window.location.reload()
        ) */
    } catch (e) {
      console.log(e);

      swal({
        title: 'Error',
        text: e.error_message ? e.error_message : 'An Error Occurred',
        icon: 'error',
        customClass: {
          container: 'swal-z'
        }
      });
    }
  };

  const handleDelete = async e => {
    try {
      let Symptom = new SymptomModel();
      Swal.fire({
        title: 'Delete Symptom',
        text: 'Are you sure you want to delete this symptom?',
        icon: 'warning',
        showCancelButton: true,
        confirmButton: 'Yes',
        confirmButtonColor: '#0d6efd',
        cancelButton: 'Cancel',
        cancelButtonColor: '#d33'
        // dangerMode: true,
      }).then(async result => {
        if (result.isConfirmed) {
          try {
            let result = await Symptom.deleteSymptomById(id);

            if (result.id || result.success) {
              swal({
                title: 'Success',
                text: 'Delete Successful',
                icon: 'success'
              }).then(() => {
                history.push(`/symptom/`);
              });
            } else {
              await swal.fire('Failed', 'Failed to delete', 'error');
            }
          } catch (e) {
            console.error(e);
            await swal.fire('', e.error_message ? e.error_message : 'Something Wrong', 'error');
          }
        }
      });
    } catch (e) {
      console.log(e);

      swal({
        title: 'Error',
        text: e.error_message ? e.error_message : 'An Error Occurred',
        icon: 'error',
        customClass: {
          container: 'swal-z'
        }
      });
    }
  };

  useEffect(() => {
    getSymptomData();
    console.log(id);
    //initializeGeneralInfo()
  }, []);

  return (
    <>
      <Card>
        <CardHeader title="Symptom details">
          <CardHeaderToolbar>
            <Button color={'secondary'} variant="contained" onClick={handleDelete}>
              Delete
            </Button>
          </CardHeaderToolbar>
        </CardHeader>
        <CardBody>
          <form onSubmit={handleSubmit}>
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <TextField
                  // id="name"
                  value={symptomName}
                  onChange={e => setSymptomName(e.target.value)}
                  label="Symptom Name *"
                  input={<OutlinedInput label="Tag" />}
                  // helperText={touched.name ? errors.name : ''}
                  // error={touched.name && errors.name ? true : false}
                />
              </Grid>
              <Grid item xs={12} sm={5}>
                {/* <MultipleSelect
                  label={'Body Part associated with the disease'}
                  options={bodyPartOptions}
                  selected={selectedBodyPart}
                  setSelected={setSelectedBodyPart}
                /> */}

                <InputLabel id="demo-simple-select-label">Body Part associated with the disease</InputLabel>
                <Select
                  labelId="demo-simple-select-label"
                  id="demo-simple-select"
                  value={selectedBodyPart}
                  placeholder="Pick one"
                  onChange={e => setSelectedBodyPart(e)}
                  options={bodyPartOptions}
                  styles={{
                    // Fixes the overlapping problem of the component
                    menu: provided => ({ ...provided, zIndex: 9999 })
                  }}
                  //style={{minWidth: 120}}
                >
                  {/* {bodyPartOptions?.map((el, idx) => {
                        return (
                            <MenuItem key={el.value} value={el.value}>
                                <ListItemText primary={el.label} />
                            </MenuItem>
                        );
                    })} */}
                </Select>
                {/* {selectedBodyPart == '' ? <FormHelperText>Pick one</FormHelperText> : null} */}
              </Grid>
            </Grid>
            <HorizontalSeparator text={'Details'} />

            {symptomDetails.map((input, index) => {
              return (
                <Grid container spacing={2} key={index}>
                  <Grid item xs={12} sm={4}>
                    <TextField
                      name={'name'}
                      value={input.name}
                      onChange={event => handleDetailChange(index, event)}
                      label="Detail (frequency, time, etc)"
                      input={<OutlinedInput label="Tag" />}
                      // helperText={touched.name ? errors.name : ''}
                      // error={touched.name && errors.name ? true : false}
                    />
                  </Grid>
                  <Grid item xs={12} sm={4}>
                    {/* <TextField
                            name={"options"}
                            value={input.options}
                            onChange={event => handleDetailChange(index, event)}
                            label="Values (use ; to separate each values)"
                            input={<OutlinedInput label="Tag" />}
                            // helperText={touched.name ? errors.name : ''}
                            // error={touched.name && errors.name ? true : false}
                            /> */}
                    <CreatableSelect
                      isMulti
                      name={'options'}
                      value={input.options}
                      components={{ DropdownIndicator: null }}
                      onChange={event => handleOptionsChange(index, event)}
                      placeholder="Values (press enter to create option)"
                    />
                  </Grid>
                  <Grid item xs={12} sm={3}>
                    <Select
                      name={'type'}
                      value={input.type}
                      placeholder="Input type"
                      onChange={event => handleTypeChange(index, event)}
                      options={typeOptions}
                      styles={{
                        // Fixes the overlapping problem of the component
                        menu: provided => ({ ...provided, zIndex: 9999 })
                      }}
                      //style={{minWidth: 120}}
                    />
                  </Grid>
                  <Grid item xs={12} sm={1}>
                    <Button color={'secondary'} variant="contained" onClick={() => removeDetail(index)}>
                      Remove
                    </Button>
                  </Grid>
                </Grid>
              );
            })}

            <br />
            <Button color={'primary'} variant="contained" onClick={addDetails}>
              Add Details
            </Button>
          </form>
          <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'flex-end' }}>
            <Button color={'primary'} variant="contained" onClick={handleSubmit}>
              Save
            </Button>
          </div>
        </CardBody>
      </Card>
    </>
  );
};

export default SymptomEditPageV2;
