// import axios from '../../api/diagnosisAPI';
// import { setErrorNotification } from '../snackbarNotification';

// export const FETCH_BEGIN = 'disease.list/FETCH_BEGIN';
// export const FETCH_SUCCESS = 'disease.list/FETCH_SUCCESS';
// export const FETCH_FAILED = 'disease.list/FETCH_FAILED';

// const fetchBegin = () => ({ type: FETCH_BEGIN });
// const fetchSuccess = (data) => ({ type: FETCH_SUCCESS, payload: data });
// const fetchFailed = (error) => ({ type: FETCH_FAILED, payload: error });
// export const fetchDiseaseList = () => async (dispatch) => {
//   dispatch(fetchBegin());

//   try {
//     const res = await axios.get('retrieve-disease');

//     dispatch(fetchSuccess(res.data.result));
//   } catch(error) {
//     dispatch(fetchFailed(error.toString()));
//     dispatch(setErrorNotification(error.toString()));
//   }
// }

const reducer = (state = initialState, action) => {
  const { type, payload } = action;

  switch (type) {
    // case FETCH_BEGIN:
    //   return { ...state, isFetching: true, error: null };
    // case FETCH_SUCCESS:
    //   return { ...state, isFetching: false, data: payload };
    // case FETCH_FAILED:
    //   return { ...state, isFetching: false, error: payload };

    default:
      return state;
  }
};

export default reducer;

const MEDICAL_RECORDS = [
  { id: 'CHECK-UP', name: 'CHECK-UP' },
  { id: 'GROOMING', name: 'GROOMING' },
  { id: 'RAJANTI TALK WITH ANIMALS', name: 'RAJANTI TALK WITH ANIMALS' },
  { id: 'RAWAT JALAN', name: 'RAWAT JALAN' },
  { id: 'RAWAT INAP', name: 'RAWAT INAP' },
  { id: 'PENITIPAN HEWAN', name: 'PENITIPAN HEWAN' },
  { id: 'OPERASI', name: 'OPERASI' }
];

const initialState = {
  data: MEDICAL_RECORDS,
  error: null
};
