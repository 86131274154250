import api from '../../api/diagnosisAPI';
import {
  setSuccessNotification,
  setErrorNotification
} from '../snackbarNotification';

export const DIAGNOSE_BEGIN = 'diagnosis.ops/DIAGNOSE_BEGIN';
export const DIAGNOSE_SUCCESS = 'diagnosis.ops/DIAGNOSE_SUCCESS';
export const DIAGNOSE_FAILED = 'diagnosis.ops/DIAGNOSE_FAILED';
export const DIAGNOSE_RESET = 'diagnosis.ops/DIAGNOSE_RESET';

const diagnoseBegin = () => ({ type: DIAGNOSE_BEGIN });
const diagnoseSuccess = data => ({ type: DIAGNOSE_SUCCESS, payload: data });
const diagnoseFailed = error => ({ type: DIAGNOSE_FAILED, payload: error });
export const diagnose = data => async dispatch => {
  dispatch(diagnoseBegin());

  try {
    const res = await api.post('diagnose-this', data);

    const sortedData = res.data.result.sort(
      (a, b) =>
        1 -
        a.symptoms_met / a.total_disease_symptoms -
        (1 - b.symptoms_met / b.total_disease_symptoms)
    );

    dispatch(diagnoseSuccess(sortedData));
  } catch (error) {
    dispatch(diagnoseFailed(error));
    dispatch(setErrorNotification(error.toString()));
  }
};

export const resetDiagnosis = () => ({
  type: DIAGNOSE_RESET
});

export default (state = initialState, action) => {
  const { type, payload } = action;

  switch (type) {
    case DIAGNOSE_BEGIN:
      return { ...state, error: null };

    case DIAGNOSE_SUCCESS:
      return { ...state, data: action.payload };

    case DIAGNOSE_FAILED:
      return { ...state, error: payload };

    case DIAGNOSE_RESET:
      return { ...state, data: null, error: null };

    default:
      return state;
  }
};

const initialState = {
  data: null,
  error: null
};
