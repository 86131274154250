import api from '../../api/diagnosisAPI';
import { setSuccessNotification, setErrorNotification } from '../snackbarNotification';

export const EDIT_BEGIN = 'disease.edit/EDIT_BEGIN';
export const EDIT_SUCCESS = 'disease.edit/EDIT_SUCCESS';
export const EDIT_FAILED = 'disease.edit/EDIT_FAILED';

const editBegin = () => ({ type: EDIT_BEGIN });
const editSuccess = () => ({ type: EDIT_SUCCESS });
const editFailed = error => ({ type: EDIT_FAILED, payload: error });
export const editDisease = (id, values) => async dispatch => {
  dispatch(editBegin());

  let updateDiseaseRes;
  try {
    const data = {
      id,
      disease_name: values.name
    };

    updateDiseaseRes = await api.post('update-disease', data);
  } catch (error) {
    dispatch(editFailed(error));
    dispatch(setErrorNotification(error.toString()));
  }

  if (!updateDiseaseRes) return;

  let updateDiseaseBindRes;
  try {
    const data = {
      disease_id: id,
      animal_id: values.animalType,
      symptom_anatomy_array: JSON.stringify(
        values.symptoms.map(({ symptomId, locationId }) => ({
          symptom_id: symptomId,
          anatomy_id: locationId
        }))
      ),
      medicine_array: JSON.stringify(values.medicines.map(medicine => medicine.id))
    };

    updateDiseaseBindRes = await api.post('update-disease-animal-medicine-symptoms-anatomy', data);
  } catch (error) {
    dispatch(editFailed(error));
    dispatch(setErrorNotification(error.toString()));
  }

  if (!updateDiseaseBindRes) return;

  dispatch(editSuccess());
  dispatch(setSuccessNotification('Successfully edited data'));
};

const reducer = (state = initialState, action) => {
  const { type, payload } = action;

  switch (type) {
    case EDIT_BEGIN:
      return { ...state, completed: false, error: null, isLoading: true };

    case EDIT_SUCCESS:
      return { ...state, completed: true, isLoading: false };

    case EDIT_FAILED:
      return { ...state, error: payload, isLoading: false };

    default:
      return state;
  }
};

export default reducer;

const initialState = {
  completed: false,
  error: null,
  isLoading: false
};
