const SET_NOTIFICATION = 'snackbarNotification/SET_NOTIFICATION';
const SET_SUCCESS_NOTIFICATION = 'snackbarNotification/SET_SUCCESS_NOTIFICATION';
const SET_ERROR_NOTIFICATION = 'snackbarNotification/SET_ERROR_NOTIFICATION';

export const setNotification = (message) => ({ type: SET_NOTIFICATION, payload: message })
export const setSuccessNotification = (message) => ({ type: SET_SUCCESS_NOTIFICATION, payload: message })
export const setErrorNotification = (message) => ({ type: SET_ERROR_NOTIFICATION, payload: message })

export default (state = initialState, action) => {
  const { type, payload } = action;

  switch(type) {
    case SET_NOTIFICATION:
      return { ...state, message: payload, variant: 'default' };
    case SET_SUCCESS_NOTIFICATION:
      return { ...state, message: payload, variant: 'success' };
    case SET_ERROR_NOTIFICATION:
      return { ...state, message: payload, variant: 'error' };

    default:
      return state;
  }
}

const initialState = {
  message: undefined,
  variant: undefined,
};