import ApiRequest from '../../utils/APIRequest2';

export default class Symptom {

  static getAll = async () => {
    return await ApiRequest.set('v1/symptoms', 'GET');
  };

  static getAllActive = async () => {
    return await ApiRequest.set('v1/symptoms_active', 'GET');
  };

  getSymptomById = async (id) => {
    return await ApiRequest.set('v1/symptom/'+id, 'GET');
  };

  deleteSymptomById = async (id) => {
    return await ApiRequest.set('v1/symptom/'+id, 'DELETE')
  }

  restoreSymptomById = async (id) => {
    return await ApiRequest.set('v1/symptom/'+id, 'PATCH')
  }

  edit = async (id, values) => {
    return await ApiRequest.set('v1/symptom/'+id, 'PUT', values)
  }

  create = async (values) => {
    return await ApiRequest.set('v1/symptom', 'POST', values)
  }


}
