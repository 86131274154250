import React, { useEffect, } from 'react';
import { DataTableLocal as DataTable } from '../../../components/common/DataTable';
import Dialog from '@material-ui/core/Dialog';
import {  Button } from '@material-ui/core';
import { useDispatch, useSelector } from 'react-redux';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import { fetchCustomerList } from '../../../../redux/customer/list';
export default function AppointmentCustomerList(props) {
    const dispatch = useDispatch();
    // Initial fetch
    useEffect(() => {
        dispatch(fetchCustomerList());
    }, []);
    

    const { data } = useSelector(state => state.customer.list);
    console.log('data customer', data)
    const isFetching = useSelector(state => state.loading.customer);
    const columnsCustomer = [
        {
            dataField: 'user_name',
            text: 'name'
        },
        {
            dataField: 'mobile',
            text: 'phone'
        },
        {
            dataField: 'email',
            text: 'email'
        }
    ];

    return (
        <>
            <Dialog fullWidth={true}
                maxWidth={'sm'} onClose={false} aria-labelledby="customized-dialog-title" open={props.showDialogOwner}>
                <DialogTitle id="customized-dialog-title">
                    Select Owner
                </DialogTitle>
                <DialogContent dividers>
                    <DataTable
                        data={data}
                        loading={isFetching}
                        totalDataCount={data.length}
                        columns={columnsCustomer}
                        noEditAction
                        renderCustomActions={(row, data) => {
                  
                            return (

                             <Button onClick={() => {
                                props.selectedOwner(data);
                                props.toogleDialogOwner(false);
                             }} color="primary">Select</Button>
                            );
                        }}
                    />
                </DialogContent>
               
            </Dialog>
        </>
    )
}