import React, { useEffect, useState } from 'react';
import { Button } from '@material-ui/core';
import { ArrowBackIosOutlined, FiberManualRecordRounded } from '@material-ui/icons';
import { useHistory, useParams } from 'react-router-dom';
import { format, addDays, parseISO, startOfWeek } from 'date-fns';
import { DatePicker, MuiPickersUtilsProvider } from '@material-ui/pickers';
import DateFnsUtils from '@date-io/date-fns';

import { Card, CardBody } from '../../../_metronic/_partials/controls';
import { DataTableLocal as DataTable } from '../../components/common/DataTable';
import { sortCaret } from '../../../_metronic/_helpers';
import Appointment from '../../models/Appointment';
import Clinic from '../../models/Clinic';
import _ from 'lodash';

const ClinicSchedulePage = () => {
  let history = useHistory();
  let { id } = useParams();

  let [clinic, setClinic] = useState(null)

  const [operationTableList, setOperationTableList] = useState([]);
  const [selectedDay, setSelectedDay] = useState(0);
  const [filterDate, setFilterDate] = useState(startOfWeek(new Date()));
  const [groupedOperationTableList, setGroupedOperationTableList] = useState([]);
  const [date, setDate] = useState(addDays(startOfWeek(new Date()), 1));

  const WEEKLY = [0, 1, 2, 3, 4, 5, 6];

  const getClinicData = async() =>{
    try{
      let result = await new Clinic().getClinicById(id)
      setClinic(result)
    }catch (e) {

    }
  }

  const renderButtons = () => {
    const buttons = [];

    let monday = date;
    for (let i = 0; i < WEEKLY.length; i++) {
      const currDate = addDays(monday, i);
      buttons.push(currDate);
    }

    return buttons;
  };

  const handleDatePick = (date, index) => {
    setFilterDate(date);
    setSelectedDay(index);
  };

  const handleWeekChange = date => {
    setDate(date);
    console.log(format(date, 'yyyy-MM-dd'));
    getClinicSchedule(date, addDays(date, 7));
  };

  const getClinicSchedule = async (startDate, endDate) => {
    let appointmentModel = new Appointment();
    let formattedStart = format(startDate, 'yyyy-MM-dd');
    let formattedEnd = format(endDate, 'yyyy-MM-dd');

    try {
      let result = await appointmentModel.getOwnedAppointmentByDateAndClinicId(formattedStart, formattedEnd, id);
      console.log(result);
      setGroupedOperationTableList(_.groupBy(result, 'operation_table_id'));
      setOperationTableList(result);
    } catch (e) {
      console.log('error', e);
    }
  };

  useEffect(() => {
    getClinicData()
    getClinicSchedule(addDays(startOfWeek(new Date()), 1), addDays(startOfWeek(new Date()), 7));
  }, []);

  const columns = [
    {
      text: 'No',
      dataField: 'id'
    },
    {
      text: 'Owner',
      dataField: 'user.user_name'
    },
    {
      text: 'Patient',
      dataField: 'patients',
      formatter: (cell, row) => {
        return (
          row && (
            <div>
              {cell?.map((obj, key) => {
                return `${obj.patient_name}${key < cell.length - 1 ? ', ' : ''}`;
              })}
            </div>
          )
        );
      }
    },
    {
      text: 'TX',
      dataField: 'booking_type.booking_type_name'
    },
    {
      text: 'Waktu',
      dataField: 'start_time',
      formatter: (cell, row, rowIndex, formatExtraData) => {
        return (
          row && (
            <>
              {format(parseISO(row.start_time), 'HH:mm')} - {format(parseISO(row.end_time), 'HH:mm')}
            </>
          )
        );
      },
      sort: true,
      sortCaret: sortCaret
    },
    {
      text: 'Vet',
      dataField: 'doctor.doctor_name'
    },
    {
      text: 'Status',
      dataField: 'doctor.doctor_name',
      formatter: (cell, row) => {

        let status = ""

        if (row.status === "DONE") {
          status = "Done"
        } else if (row.payment_status === "PAID") {
          status = "Need admin confirmation"
        } else if (row.payment_status === "PAID_CONFIRMED" || row.payment_status === "ADMIN_CONFIRMED") {
          status = "Confirmed"
        } else if (row.payment_status === "UNPAID") {
          status = "Waiting for Payment"
        } else if (row.payment_status === "EXPIRED") {
          status = "Expired"
        } else if (row.status === "CANCELLED") {
          status = "Cancelled"
        } else if (row.payment_status === "ADMIN_CREATED") {
          status = "Created By Admin"
        } else {
          status = `${row.status} | ${row.payment_status}`
        }

        return row && <>
          {status}
        </>
      }
    }
  ];

  const handleRenderDataTable = list => {
    let tempArray = [];

    for (const [key, value] of Object.entries(list)) {
      tempArray.push(value);
    }

    console.log(tempArray);

    return tempArray.map((tA, index) => {
      return (
        <Card className="mb-5 p-3 mx-0" key={index}>
          <hr></hr>
          <h4>{tA[0].operation_table?.name}</h4>
          <hr></hr>
          <DataTable
            data={tA.filter(
              opTable => format(parseISO(opTable.start_time), 'yyyy-MM-dd') === format(filterDate, 'yyyy-MM-dd')
            )}
            totalDataCount={
              tA.filter(opTable => format(parseISO(opTable.start_time), 'yyyy-MM-dd') === format(date, 'yyyy-MM-dd'))
                .length
            }
            columns={columns}
            editLink="appointment/"
          />
        </Card>
      );
    });
  };
  return (
    <>
      <Card>
        <div className="card-header">
          <div className="mt-4 card-title">
            <span
              style={{
                cursor: 'pointer'
              }}
            >
              <ArrowBackIosOutlined onClick={() => history.goBack()}></ArrowBackIosOutlined>
            </span>
            <h3 className="ml-3 mt-3 mb-2">Jadwal Klinik {clinic?.name}</h3>
          </div>
          <div className="mt-4">
            <MuiPickersUtilsProvider utils={DateFnsUtils}>
              <DatePicker
                value={date}
                onChange={handleWeekChange}
                shouldDisableDate={date => date.getDay() !== 1}
                label="Start of week"
                variant="inline"
                inputVariant="outlined"
                size="small"
                format="EEEE, dd MMM yyyy"
                style={{ marginBottom: '2rem' }}
              />
            </MuiPickersUtilsProvider>
          </div>
          <div className="d-flex">
            {renderButtons().map((b, index) => (
              <>
                <Button className="m-2 d-block" onClick={() => handleDatePick(b, index)} key={index}>
                  {format(b, 'EEEE')}, {format(b, 'dd MMM yyyy')}
                  {operationTableList.filter(
                    opTable => format(parseISO(opTable.start_time), 'yyyy-MM-dd') === format(b, 'yyyy-MM-dd')
                  ).length > 0 && <FiberManualRecordRounded style={{ color: 'aqua' }} />}
                  <hr style={{ border: selectedDay === index && '1px solid blue' }} className="w-100"></hr>
                </Button>
              </>
            ))}
          </div>
        </div>
        <CardBody>{handleRenderDataTable(groupedOperationTableList)}</CardBody>
      </Card>
    </>
  );
};

export default ClinicSchedulePage;
