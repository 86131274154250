import { createElement, useState } from 'react';
import {
  Button,
  Fade,
  IconButton,
  ListItemIcon,
  ListItemText,
  Menu,
  MenuItem
} from '@material-ui/core';
import { MoreVert as MoreVertIcon } from '@material-ui/icons';

const ButtonGroupMini = ({ buttonList }) => {
  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);

  const handleClick = event => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const firstButton = (
    <Button
      onClick={buttonList[0].handleClick}
      color={buttonList[0].color}
      variant={buttonList[0].variant}
      {...(buttonList[0].icon && {
        startIcon: createElement(buttonList[0].icon, { fontSize: 'small' })
      })}
    >
      {buttonList[0].text}
    </Button>
  );

  if (buttonList.length === 1) {
    return firstButton;
  }

  return (
    <>
      {firstButton}
      <IconButton
        aria-controls="more-menu"
        aria-haspopup="true"
        onClick={handleClick}
      >
        <MoreVertIcon />
      </IconButton>
      <Menu
        id="more-menu"
        anchorEl={anchorEl}
        getContentAnchorEl={null}
        open={open}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right'
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right'
        }}
        TransitionComponent={Fade}
      >
        {buttonList.map(
          (button, index) =>
            index > 0 && (
              <MenuItem
                onClick={() => {
                  handleClose();
                  button.handleClick();
                }}
                key={index}
              >
                <ListItemIcon style={{ minWidth: '32px' }}>
                  {createElement(button.icon, { fontSize: 'small' })}
                </ListItemIcon>
                <ListItemText primary={button.text} />
              </MenuItem>
            )
        )}
      </Menu>
    </>
  );
};

export default ButtonGroupMini;
