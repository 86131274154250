import React, { useEffect, useState } from 'react';
import { useHistory, Link, useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { ArrowBackIosOutlined } from '@material-ui/icons';
import { Grid, MenuItem, IconButton, Button } from '@material-ui/core';
import { useFormik } from 'formik';
import { Link as LinkIcon, Search as SearchIcon, Delete as DeleteIcon } from '@material-ui/icons';
import * as Yup from 'yup';
import InputAdornment from '@material-ui/core/InputAdornment';
import AppointmentCustomerList from './components/AppointmentCustomerList';
import KeyboardDatePicker from 'app/components/common/KeyboardDatePicker';
import moment from 'moment';

import { Card, CardBody } from '_metronic/_partials/controls';
import { TextField } from 'app/components/common/TextField';
import { ActionBarEdit } from 'app/components/common/ActionBar';

import OperationTable from 'app/models/OperationTable';
import { fetchAppointment } from '../../../redux/appointment/detail';
import swal from 'sweetalert2';
import TimePicker from '../../components/common/TimePicker/TimePicker';
import Appointment from '../../models/Appointment';

const AppointmentEditPageV2 = ({ isEditing, headerToolbar }) => {
  const history = useHistory();
  const { id } = useParams();
  const dispatch = useDispatch();



  const { data } = useSelector(state => state.appointment.detail);
  const [operationTableOptions, setOperationTableOptions] = useState([]);

  const fetchClinicOperationTable = async () => {
    let operationTableModel = new OperationTable();
    console.log(data);
    if (data && data.clinic) {
      try {
        let result = await operationTableModel.getAllOperationByClinicId(data.clinic.id);
        setOperationTableOptions(result);
      } catch (e) {
        console.log(e);
      }
    }
  };

  const handleEdit = async formattedValues => {
    let appointmentModel = new Appointment();

    try {
      let result = await appointmentModel.editAppointment(id, formattedValues);

      swal
        .fire({
          title: 'Success',
          text: 'Appointment edited successfully',
          icon: 'success',
          confirmButtonText: 'Go back',
          customClass: {
            container: 'swal-z'
          }
        })
        .then(result => {
          /* Read more about isConfirmed, isDenied below */
          if (result.isConfirmed) {
            history.goBack();
          }
        });
    } catch (e) {
      swal.fire({
        title: 'Error',
        text: e.error_message ? e.error_message : "An Error Occured",
        icon: 'error',
        confirmButtonText: 'Ok',
        customClass: {
            container: 'swal-z'
        }
    });
      console.log('error', e);
    }
  };
  useEffect(() => {
    dispatch(fetchAppointment(id));
  }, []);

  useEffect(() => {
    fetchClinicOperationTable();
  }, [data?.clinic.id]);

  const renderTimeInput = props => {
    return (
      <TextField
        disabled={props.disabled}
        value={`${props.value}`}
        onChange={props.onChange}
        onClick={props.onClick}
        label={props.label}
      />
    );
  };

  const { values, handleChange, handleSubmit, setFieldValue } = useFormik({
    initialValues: {
      description: data?.description || '',
      operationTable: data?.operation_table.id || null,
      start_time: data?.start_time || null,
      end_time: data?.end_time || null,
      date: null
    },
    validationSchema: Yup.object({
      description: Yup.string().max(3000)
    }),
    onSubmit: values => {
      const formattedValues = {
        description: values.description === '' ? " " : values.description,
        start_time: values.start_time,
        end_time: values.end_time,
        operation_table_id: values.operationTable
      };

      handleEdit(formattedValues);
    },
    enableReinitialize: 'true'
  });

console.log('heet dah', data)

  return (
    <>

      <Card>
        <div className="card-header">
          <div className="mt-4 card-title">
            <span
              style={{
                cursor: 'pointer'
              }}
            >
              <ArrowBackIosOutlined onClick={() => history.goBack()}></ArrowBackIosOutlined>
            </span>
            <h3 className="ml-3 mt-3 mb-2">Appointment details</h3>
          </div>
        </div>
        <CardBody>
          <form onSubmit={handleSubmit}>
            <Grid container spacing={2}>
              <Grid item xs={12} md={6}>
                <Grid container>
                  <Grid item xs>
                    <TextField
                      disabled={true}
                      value={data?.owner?.user_name}
                      label="Owner *"
                      variant="outlined"
                      InputLabelProps={{ shrink: true }}
                    />
                    <Grid item xs>
                      <IconButton size="small" color="primary">

                      </IconButton>
                    </Grid>
                  </Grid>
                  {data && (
                    <Grid item>
                      <Link to={`/customer/${data.customer_id}`}>
                        <IconButton size="small" color="primary">
                          <LinkIcon />
                        </IconButton>
                      </Link>
                    </Grid>
                  )}
                </Grid>

              </Grid>

              <Grid item xs={12} md={6}>
                <Grid container>
                  <Grid item xs>
                    <TextField
                      value={data?.patients.map(p => p.patient_name + ' ')}
                      label="Pet Name *"
                      variant="outlined"
                      InputLabelProps={{ shrink: true }}
                      disabled={true}
                    />
                  </Grid>
                </Grid>
              </Grid>
              <Grid item xs={12}>
                <TextField
                  disabled={true}
                  value={data?.bookingType?.booking_type_name}
                  InputLabelProps={{ shrink: true }}
                />
              </Grid>



              <Grid item xs={12}>
                <TextField
                  id="description"
                  value={values.description}
                  onChange={handleChange}
                  label="Catatan tambahan"
                  multiline
                  rows={8}
                />
              </Grid>
              <Grid item xs={6}>
                <TextField
                  id="created_at"
                  disabled={true}
                  value={moment(data?.created_at).format('DD MMM YYYY, HH:mm:ss')}
                  onChange={handleChange}
                  label="Created At"
                  InputLabelProps={{ shrink: true }}
                />
              </Grid>
              <Grid item xs={6}>
                <TextField
                  id="expire_at"
                  disabled={true}
                  value={moment(data?.expire_at).format('DD MMM YYYY, HH:mm:ss')}
                  onChange={handleChange}
                  label="Expire At"
                  InputLabelProps={{ shrink: true }}
                />
              </Grid>
              <Grid item xs={6}>
                <TextField
                  id="payment_status"
                  disabled={true}
                  value={data?.payment_status}
                  onChange={handleChange}
                  label="Payment Status"
                  InputLabelProps={{ shrink: true }}
                />
              </Grid>
              <Grid item xs={6}>
                <TextField
                  id="status"
                  disabled={true}
                  value={data?.status}
                  onChange={handleChange}
                  label="Status"
                  InputLabelProps={{ shrink: true }}
                />
              </Grid>
              {
                data?.category_id === 3 ? <Grid item xs={6}>
                  <TextField
                    disabled={true}
                    value={data?.doctor?.doctor_name || data?.doctor_name}
                    label="Doctor *"
                    variant="outlined"
                    InputLabelProps={{ shrink: true }}
                  />
                </Grid>

                  : ''
              }


              <Grid item xs={6}>
                <KeyboardDatePicker
                  value={values.start_time}
                  onChange={date => setFieldValue('date', date, true)}
                  label="Appointment Date *"
                  format="dd/MM/yyyy"
                  InputLabelProps={{ shrink: true }}
                />
              </Grid>
              <Grid item xs={12} md={6}>
                <TimePicker
                  value={values.start_time}
                  onChange={date => setFieldValue('start_time', date)}
                  clearable
                  label="Start Time"
                  TextFieldComponent={renderTimeInput}
                />
              </Grid>
              <Grid item xs={12} md={6}>
                <TextField
                  id="clinic_name"
                  disabled={true}
                  value={data?.clinic?.name}
                  label="Clinic"
                  InputLabelProps={{ shrink: true }}
                />
              </Grid>
              <Grid item xs={12} md={6}>
                <TimePicker
                  value={values.end_time}
                  onChange={date => setFieldValue('end_time', date)}
                  clearable
                  label="End Time"
                  TextFieldComponent={renderTimeInput}
                />
              </Grid>
              {
                data?.category_id === 3 ?
                  <Grid item xs={12} md={12}>
                    <TextField
                      id="operationType"
                      select
                      value={values.operationTable}
                      onChange={event => {
                        setFieldValue('operationTable', event.target.value);
                      }}
                      label="Operation Table *"
                      InputLabelProps={{ shrink: !!values.operationTable }}
                    >
                      {operationTableOptions.map((obj, key) => (
                        <MenuItem key={key} value={obj.id}>
                          {`${obj.name}`} ({obj.type})
                        </MenuItem>
                      ))}
                    </TextField>
                  </Grid> : ''
              }
              <ActionBarEdit isEditing={true} handleSubmit={handleSubmit} isDeleteButtonShown={false} />
            </Grid>
          </form>
        </CardBody>
      </Card>
    </>
  );
};

export default AppointmentEditPageV2;
