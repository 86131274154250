import { combineReducers } from 'redux';

import list from './list';
// import detail from './detail';
import ops from './ops';

export default combineReducers({
  list,
  // detail,
  ops
});
