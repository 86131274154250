import APIRequest from "../utils/APIRequest";

export default class CategoryModel {

    static addAppointmentSlot = async(category_id, doctor_id, codoctor, start_time, end_time, clinic)=>{
        return await APIRequest.set(`v1/appointment-slot`, 'POST', {
            category_id, doctor_id, codoctor, start_time, end_time, clinic
        });
    }

    static getBookingTypeByCategoryIdAndDoctorId = async(categoryId, doctorId)=>{
        return await APIRequest.set(`/v1/categories/bookingTypeByDoctorIdAndCategoryId?category_id=${categoryId}&doctor_id=${doctorId}`, 'GET');
    }

    static getBookingTypeByCategoryIdAndDoctorIdV2 = async(categoryId, doctorId)=>{
        return await APIRequest.set(`v2/categories/bookingTypeByDoctorIdAndCategoryId?category_id=${categoryId}&doctor_id=${doctorId}`, 'GET');
    }

    static getAll = async()=>{
        return await APIRequest.set(`v1/categories`, 'GET');
    }

}
