import ApiRequest from '../../utils/APIRequest';

export default class OperationTable {
  getAllOperation = async () => {
    return await ApiRequest.set('v1/operationtables', 'GET');
  };

  getAllOperationByClinicId = async (id) => {
    return await ApiRequest.set('v1/operationtable/clinic/'+id, 'GET');
  };

  addOperationTable = async newOperationTable => {
    return await ApiRequest.set('v1/operationtable', 'POST', newOperationTable);
  };

  editOperationTable = async (id, operationData) => {
    return await ApiRequest.set('v1/operationtable/' + id, 'PUT', operationData);
  };

  getOperationTableById = async (id) => {
    return await ApiRequest.set('v1/operationtable/' + id, 'GET');
  };

  getOperationTableByClinicId = async (clinicId) => {
    return await ApiRequest.set('v1/operationtable/clinic/' + clinicId, 'GET');
  };
}
