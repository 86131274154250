import React, { useEffect, useState } from 'react';
import { useHistory, Link } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import {
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    Grid,
    MenuItem
} from '@material-ui/core';

import { makeStyles } from '@material-ui/core/styles';
import DiagnoseModel from 'app/models/Diagnose';
import SymptomModel from 'app/models/Symptom';
import { set } from 'lodash';
import moment from "moment"
import _ from 'lodash';

const useStyles = makeStyles(theme => ({
    container: {
        backgroundColor: 'rgba(0, 0, 0, 0.25)'
    },
    paper: {
        width: '800px',
        maxWidth: '800px'
    },
    title: {
        marginBottom: '2rem',
        padding: '2rem 2rem 1rem 2rem',
        borderBottom: `1px solid ${theme.palette.divider}`
    },
    timeSlotContainer: {
        maxWidth: '360px'
    },
    datePicker: {
        marginBottom: '1rem'
    },
    actionContainer: {
        justifyContent: 'flex-start',
        marginTop: '2rem',
        padding: '1.5rem 2rem 1.5rem 2rem',
        borderTop: `1px solid ${theme.palette.divider}`
    }
}));

const DiagnoseHistoryDialog = ({ isOpen, handleClose, patientID }) => {

    const [historyData, setHistoryData] = useState([])

    const getDiagnoseHistoryData = async () => {
        try {
            let result = await DiagnoseModel.getByAppointmentPatientId(patientID)
            console.log("DHD", result)

            if(result) {
                let resultSymptom = await SymptomModel.getAll();
                
                for(let i = 0; i < result.length; i++) {
                let uniqueSymptoms = _.keys(
                  _.countBy(result[i].diagnose_record_symptoms, data => {
                    return data.symptom_id;
                  })
                );
                let symptomsArray = [];
                uniqueSymptoms.forEach(ele => {
                  //console.log('LOLOL', ele);
                  let filteredSymptomDisease = result[i].diagnose_record_symptoms.filter(el2 => el2.symptom_id == ele);
                  let newSymptom = filteredSymptomDisease[0].symptoms;
                  let sd;
                  let name;
                  let tempDetail = [];
                  let unique = [];
                  
                  filteredSymptomDisease.forEach(element => {
                    if (!unique.includes(element.symptom_detail_options.symptoms_detail.detail_name)) {
                      unique.push(element.symptom_detail_options.symptoms_detail.detail_name);
                    }
                  });
                  //console.log("mencoba", filteredSymptomDisease)
          
                  unique.forEach(dn => {
                    let detail = filteredSymptomDisease
                      .filter(el3 => el3.symptom_detail_options.symptoms_detail.detail_name == dn)
                      .map((el3, idx) => {
                        //console.log('WAKACIPAW', el3, filteredSymptomDisease);
                        /* if (!oldSymptomDiseases.includes(el3.id)) {
                          oldSymptomDiseases.push(el3.id);
                        } */
                        //if (idx == 0) name = filteredSymptomDisease[idx].symptom_detail_options.symptoms_detail.detail_name;
                        let symptom = resultSymptom.filter(res => res.id == el3.symptom_id);
                        //console.log(symptom, 'PPPP');
                        sd = symptom[0].symptoms_detail.map((el, idx) => {
                          //console.log('awooga', el);
                          let a = {
                            detail_id: el.id,
                            detail_name: el.detail_name,
                            options: el.symptom_detail_options.map(sy => ({
                              symptom_id: symptom[0].id,
                              label: sy.options,
                              symptom_detail_option_id: sy.id,
                              value: sy.id
                            })),
                            type: el.type
                          };
                          //console.log('apa', a);
                          return a;
                        });
          
                        return {
                          id: el3.id,
                          symptom_id: el3.symptom_id,
                          label: el3.symptom_detail_options.options,
                          symptom_detail_option_id: el3.symptom_detail_option_id,
                          value: el3.symptom_detail_option_id
                        };
                      });
          
                    tempDetail.push({ name: dn, options: [...detail] });
                    //tempDetail[dn] = { name: dn, options: [...detail] };
                  });
          
                  //console.log(filteredSymptomDisease, 'POPOPOP', newSymptom);
                  symptomsArray.push({ ...newSymptom, detail: tempDetail, symptoms_detail: sd });
                });
                /* //console.log('WKKK', uniqueSymptoms, record.diagnose_record_symptoms, symptomsArray);
                //console.log('USMP', record, resultSymptom, symptomsArray); */
                //setOldSymptomDiseaseID(oldSymptomDiseases)
                result[i].symptoms = symptomsArray
                }
            }

            setHistoryData(result)
        } catch (e) {
            console.log(e)
        }
    }

    useEffect(() => {
        console.log("DHD patient id ", patientID)
        if (patientID) {
            getDiagnoseHistoryData()
        }
    }, [patientID])

    return (
        <Dialog
            maxWidth={"xl"}
            open={isOpen}
            onClose={handleClose}
        >
            <DialogTitle>
                <div style={{ display: "flex", flexDirection: "row" }}>
                    <div style={{ flex: 1 }}>
                        Diagnose History
                    </div>
                    <div style={{cursor: "pointer"}} onClick={()=>{
                        handleClose()
                    }}>
                        x
                    </div>

                </div>
            </DialogTitle>
            <DialogContent>
                {
                    historyData.map(obj => {
                        return <div style={{ width: 800 }}>
                            <div>Status Diagnosa : {obj.progress_status}</div>
                            <div>Waktu Dibuat : {moment(obj.created_at).format("DD MMM YYYY HH:mm")}</div>
                            <div>Gejala : {obj.symptoms?.map((el, idx) => {
                                return (     
                                <div style={{marginLeft: 30}}>
                                    > {el.name} (
                                    {el.detail
                                        ?.filter(detailEl => detailEl.options != 'undefined' && detailEl.options != null)
                                        .map((detailEl, idx2) => {
                                        //console.log(detailEl, 'WOI');
                                        return (
                                            <>
                                            {detailEl.name}:{' '}
                                            {detailEl.options?.map((optionEl, idx3) => {
                                                if (idx3 < detailEl.options.length - 1) return optionEl.label + ', ';
                                                else return optionEl.label;
                                            })}
                                            {idx2 < el.detail.length - 1 ? ' | ' : ""}
                                            </>
                                        );
                                        })}
                                    )
                                </div>);
                                })}
                            </div>
                            <div className='d-flex justify-content-end'>
                                <Link to={`/diagnose/${obj.appointment_user_id}/general-data/${obj.id}`}>
                                    <Button style={{
                                        fontSize: 14,
                                        backgroundColor: '#007bff',
                                        color: 'white',
                                        fontWeight: 600,
                                        borderRadius: 20,
                                        textTransform: "none",
                                        /* paddingLeft: 10,
                                        paddingRight: 10 */
                                    }}>
                                        Enter
                                    </Button>
                                </Link>
                            </div>
                            
                            <hr />          
                        </div>
                    })
                }
            </DialogContent>
            <DialogActions>

            </DialogActions>
        </Dialog>
    );
};

export default DiagnoseHistoryDialog;
