import React, { useEffect, useState } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { Button, Grid, IconButton, Tooltip } from '@material-ui/core';
import { Assignment as AssignmentIcon } from '@material-ui/icons';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import { format, parseISO } from 'date-fns';

import {
  Card,
  CardBody,
  CardHeader,
  CardHeaderToolbar
} from '../../../_metronic/_partials/controls';
import {
  TextField,
} from '../../components/common/TextField';
import KeyboardDatePicker from '../../components/common/KeyboardDatePicker';
import { ActionBarEdit as ActionBar } from '../../components/common/ActionBar';
import DoctorAppoinmentTypeForm from './components/DoctorAppointmentTypeForm';
import Dialog from 'app/components/common/Dialog';
import swal from 'sweetalert2';

import { fetchDoctor } from '../../../redux/doctor/detail';
import { deleteDoctor } from 'redux/doctor/delete';
import Doctor from 'app/models/Doctor';
import File from 'app/models/File';

const DoctorEditPage = () => {
  const { id } = useParams();
  const history = useHistory();
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(fetchDoctor(id));
  }, []);
  const { data } = useSelector(state => state.doctor.detail); //ini masih pake API lama
  const {
    completed: isDeleteCompleted,
    isLoading: isLoadingDelete
  } = useSelector(state => state.doctor.delete);

  const [isEditing, setIsEditing] = useState(false);

  const { completed } = useSelector(state => state.doctor.ops);
  const isFetching = useSelector(state => state.loading.doctor);
  useEffect(() => {
    if (completed) {
      setIsEditing(false);
    }
  }, [completed]);

  const [isDialogOpen, setIsDialogOpen] = useState(false);

  const handleEditDoctor = async (values) => {
    let doctorModel = new Doctor()
    let fileModel = new File()

    let formattedValues = {
      ...values,
      dateOfBirth: format(values.dateOfBirth, 'yyyy-MM-dd'),
    };

    try{
      let imgUrl = fileModel.uploadFile(values.doctorImage)

      formattedValues = {...formattedValues, image_url: imgUrl.location}
      let result = doctorModel.edit(id, formattedValues)
      swal
        .fire({
          title: 'Success',
          text: 'Doctor edited successfully',
          icon: 'success',
          confirmButtonText: 'Go back',
          customClass: {
            container: 'swal-z'
          }
        })
    }catch(e){
      console.log("error ", e)
    }
  }

  useEffect(() => {
    if (isDeleteCompleted) {
      setIsDialogOpen(false);
      history.replace('/doctor');
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isDeleteCompleted]);

  const {
    values,
    errors,
    touched,
    handleChange,
    handleSubmit,
    setFieldValue,
    resetForm
  } = useFormik({
    initialValues: {
      name: data?.doctor_name || '',
      password: '',
      email: data?.email || '',
      phone: data?.mobile || '',
      dateOfBirth: data?.birthdate ? parseISO(data?.birthdate) : null,
      doctorImage: data?.image_url
    },
    validationSchema: Yup.object({
      name: Yup.string().required('Cannot be empty'),
      password: Yup.string(),
      email: Yup.string().email('Invalid email'),
      phone: Yup.string(),
      dateOfBirth: Yup.date()
        .max(new Date(), 'Date cannot be in the future')
        .nullable()
    }),
    onSubmit: values => {
      handleEditDoctor(values)
    },
    enableReinitialize: true
  });

  const handleDeleteClick = () => {
    setIsDialogOpen(true);
  };

  const handleConfirmClick = () => {
    dispatch(deleteDoctor(id));
  };

  return (
    <>
      <Card>
        <CardHeader title="Doctor details">
          <CardHeaderToolbar>
            {!isEditing ? (
              <Grid container spacing={1} alignItems="center">
                <Grid item>
                  <Tooltip title="Schedule">
                    <IconButton
                      onClick={() => {
                        history.push(`/doctor/${id}/schedule`);
                      }}
                      size="small"
                    >
                      <AssignmentIcon />
                    </IconButton>
                  </Tooltip>
                </Grid>
                <Grid item>
                  <Button
                    onClick={() => history.push(`/doctor/editv2/${id}`)}
                    color="primary"
                    variant="contained"
                  >
                    Edit
                  </Button>
                </Grid>
              </Grid>
            ) : (
              <Button
                onClick={() => {
                  resetForm();
                  setIsEditing(false);
                }}
              >
                Cancel
              </Button>
            )}
          </CardHeaderToolbar>
        </CardHeader>
        <CardBody>
          <form onSubmit={handleSubmit}>
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <TextField
                  disabled={!isEditing}
                  id="name"
                  value={values.name}
                  onChange={handleChange}
                  label="Full Name *"
                  helperText={touched.name && errors.name ? errors.name : ''}
                  error={touched.name && errors.name ? true : false}
                />
              </Grid>
              <Grid item xs={12}>
                <TextField
                  disabled={!isEditing}
                  id="email"
                  value={values.email}
                  onChange={handleChange}
                  label="Email"
                  helperText={touched.email && errors.email ? errors.email : ''}
                  error={touched.email && errors.email ? true : false}
                />
              </Grid>
              <Grid item xs={12}>
                <TextField
                  disabled={!isEditing}
                  id="phone"
                  value={values.phone}
                  onChange={handleChange}
                  label="Phone"
                  helperText={touched.phone && errors.phone ? errors.phone : ''}
                  error={touched.phone && errors.phone ? true : false}
                />
              </Grid>
              <Grid item xs={12}>
                <KeyboardDatePicker
                  disabled={!isEditing}
                  id="dateOfBirth"
                  value={values.dateOfBirth}
                  onChange={date => setFieldValue('dateOfBirth', date, true)}
                  label="Date of Birth"
                  helperText={
                    touched.dateOfBirth && errors.dateOfBirth
                      ? 'Invalid date'
                      : ''
                  }
                  error={
                    touched.dateOfBirth && errors.dateOfBirth ? true : false
                  }
                  disableFuture
                  clearable
                  format="dd/MM/yyyy"
                />
              </Grid>
              <ActionBar
                isEditing={isEditing}
                isFetching={isFetching}
                handleSubmit={handleSubmit}
                handleDelete={handleDeleteClick}
              />
            </Grid>
          </form>
        </CardBody>
      </Card>
      <DoctorAppoinmentTypeForm doctorID={id} />
      <Dialog
        open={isDialogOpen}
        handleClose={() => setIsDialogOpen(false)}
        title="Delete doctor?"
        content="This action cannot be undone"
        action={
          <Button
            onClick={handleConfirmClick}
            variant="contained"
            color="secondary"
          >
            Delete doctor
          </Button>
        }
      />
    </>
  );
};

export default DoctorEditPage;
