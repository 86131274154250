import ApiRequest from '../../utils/APIRequest';

export default class Vaccination {

  static getVaccinationById = async (id) => {
    return await ApiRequest.set(`v1/vaccination/${id}`, 'GET');
  };

  static editVaccinationById = async (id, body) => {
    return await ApiRequest.set(`v1/vaccination/${id}`, 'PUT', body);
  };

  static getVaccinationByPatientId = async (id) => {
    return await ApiRequest.set(`v1/vaccination/patient/${id}`, 'GET');
  };

  static createVaccination = async (body) => {
    return await ApiRequest.set(`v1/vaccination`, 'POST', body);
  };

  static deleteVaccinationById = async (id) => {
    return await ApiRequest.set(`v1/vaccination/${id}`, 'DELETE');
  };

}
