import api from '../../api/diagnosisAPI';
import { setSuccessNotification, setErrorNotification } from '../snackbarNotification';

export const ADD_BEGIN = 'disease.ops/ADD_BEGIN';
export const ADD_SUCCESS = 'disease.ops/ADD_SUCCESS';
export const ADD_FAILED = 'disease.ops/ADD_FAILED';
export const EDIT_BEGIN = 'disease.ops/EDIT_BEGIN';
export const EDIT_SUCCESS = 'disease.ops/EDIT_SUCCESS';
export const EDIT_FAILED = 'disease.ops/EDIT_FAILED';
export const DELETE_BEGIN = 'disease.ops/DELETE_BEGIN';
export const DELETE_SUCCESS = 'disease.ops/DELETE_SUCCESS';
export const DELETE_FAILED = 'disease.ops/DELETE_FAILED';

const addBegin = () => ({ type: ADD_BEGIN });
const addSuccess = () => ({ type: ADD_SUCCESS });
const addFailed = error => ({ type: ADD_FAILED, payload: error });
export const addDisease = values => async dispatch => {
  dispatch(addBegin());

  let addDiseaseRes;
  try {
    const data = {
      disease_name: values.name
    };

    addDiseaseRes = await api.post('add-disease', data);
  } catch (error) {
    dispatch(addFailed(error));
    dispatch(setErrorNotification(error.toString()));
  }

  if (!addDiseaseRes) return;

  let bindDiseaseRes;
  try {
    const data = {
      disease_id: addDiseaseRes.data.result.id,
      animal_id: values.animalType,
      symptom_anatomy_array: JSON.stringify(
        values.symptoms.map(({ symptomId, locationId }) => ({
          symptom_id: symptomId,
          anatomy_id: locationId
        }))
      ),
      medicine_array: JSON.stringify(values.medicines.map(medicine => medicine.id))
    };

    bindDiseaseRes = await api.post('bind-disease-animal-medicine-symptoms-anatomy', data);
  } catch (error) {
    dispatch(addFailed(error));
    dispatch(setErrorNotification(error.toString()));
  }

  if (!bindDiseaseRes) return;

  dispatch(addSuccess());
  dispatch(setSuccessNotification('Successfully added data'));
};

const editBegin = () => ({ type: EDIT_BEGIN });
const editSuccess = () => ({ type: EDIT_SUCCESS });
const editFailed = error => ({ type: EDIT_FAILED, payload: error });
export const editDisease = (id, data) => async dispatch => {
  dispatch(editBegin());

  try {
    const bindSymptomsRes = await data.addedSymptoms.map(symptom => {
      const bindSymptomData = {
        disease_id: id,
        animal_id: data.animalType,
        symptom_id: symptom.symptomId,
        anatomy_id: symptom.locationId
      };
      return api.post('bind-symptom-to-disease', bindSymptomData);
    });

    const unbindSymptomsRes = await data.deletedSymptoms.map(symptom => {
      return api.delete(`delete-bind-symptom-to-disease?bind_id=${symptom.bindId}`);
    });

    const bindMedicinesRes = await data.addedMedicines.map(medicine => {
      const bindMedicineData = {
        disease_id: id,
        medicine_id: medicine.id
      };
      return api.post('bind-medicine-to-disease', bindMedicineData);
    });

    const unbindMedicinesRes = await data.deletedMedicines.map(medicine => {
      return api.delete(`delete-bind-medicine-to-disease?bind_id=${medicine.bind_id}`);
    });

    Promise.all([...bindSymptomsRes, ...unbindSymptomsRes, ...bindMedicinesRes, ...unbindMedicinesRes])
      .then(values => {
        dispatch(editSuccess());
        dispatch(setSuccessNotification('Successfully edited data'));
      })
      .catch(error => {
        dispatch(setErrorNotification(error.toString()));
      });
  } catch (error) {
    dispatch(editFailed(error));
    dispatch(setErrorNotification(error.toString()));
  }

  // const formattedData = {
  //   id,
  //   disease_name: data.name
  // };

  // try {
  //   const res = await api.post('update-disease', formattedData);

  //   dispatch(editSuccess());
  //   dispatch(setSuccessNotification('Successfully edited data'));
  // } catch (error) {
  //   dispatch(editFailed(error.toString()));
  //   dispatch(setErrorNotification(error.toString()));
  // }
};

const deleteBegin = () => ({ type: DELETE_BEGIN });
const deleteSuccess = () => ({ type: DELETE_SUCCESS });
const deleteFailed = error => ({ type: DELETE_FAILED, payload: error });
export const deleteDisease = id => async dispatch => {
  dispatch(deleteBegin());

  const data = {
    id
  };

  try {
    const res = await api.delete(`delete-disease?id=${id}`, { data });

    dispatch(deleteSuccess());
    dispatch(setSuccessNotification('Successfully deleted data'));
  } catch (error) {
    dispatch(deleteFailed(error.toString()));
    dispatch(setErrorNotification(error.toString()));
  }
};

const reducer = (state = initialState, action) => {
  const { type, payload } = action;

  switch (type) {
    case ADD_BEGIN:
    case EDIT_BEGIN:
    case DELETE_BEGIN:
      return { ...state, completed: false, error: null, isLoading: true };

    case ADD_SUCCESS:
    case EDIT_SUCCESS:
    case DELETE_SUCCESS:
      return { ...state, completed: true, isLoading: false };

    case ADD_FAILED:
    case EDIT_FAILED:
    case DELETE_FAILED:
      return { ...state, error: payload, isLoading: false };

    default:
      return state;
  }
};

export default reducer;

const initialState = {
  completed: false,
  error: null,
  isLoading: false
};
