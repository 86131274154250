import { Typography, Grid } from '@material-ui/core';
import { Link, useHistory, useParams } from 'react-router-dom';
import { Badge, Button, Card, Col, ListGroup, Row } from 'react-bootstrap';
import AddIcon from '@material-ui/icons/Add';
import DiagnoseBuilderEvaluation from './DiagnoseBuilderEvaluation';
import { useState, useEffect } from 'react';
import { FaArrowLeft, FaArrowRight } from 'react-icons/fa6';
import DiseaseModel from '../../models/Disease'
import DiagnoseModel from '../../models/Diagnose';
import SymptomModel from '../../models/Symptom';
import AppointmentModel from '../../../model/AppointmentModel';
import _ from 'lodash';
import swal from 'sweetalert';

export default function DiagnoseBuilderResult() {
  const { id } = useParams();
  const { diagnoseId } = useParams();
  const history = useHistory();
  const [showEvaluation, setShowEvaluation] = useState(false);
  const [dataForDiagnose, setDataForDiagnose] = useState(null);
  const [diseases, setDiseases] = useState([])
  const [selectedSymptoms, setSelectedSymptoms] = useState([])
  const [patient, setPatient] = useState(null)

  const initialize = async () => {
    try{
      let result = await DiseaseModel.getRecent()
      let temp = history.location.state
      result = result.filter(el => el.animal_type_id == temp.animal_type_id)


      result.map(el => {
        el.disease_general_information = el.disease_general_information.map(el2 => {
            return el2.general_information_id
        })
        el.symptoms_disease = el.symptoms_disease.map(el3 => {
            return el3.symptom_detail_options_id
        })
      })

      result.map(el => {
        return Object.assign(el, {possibility: 0})
      })

      let symArray = [];
      temp?.symptoms.forEach(el => {
        el.detail?.forEach(det => {
          det.options?.forEach(opt => {
            symArray.push({ symptom_id: opt.symptom_id, symptom_detail_options_id: opt.symptom_detail_option_id });
          });
        });
      });
      //console.log('manamana', symArray);
      

      let arrSymptoms = []
      
      temp?.symptoms.map(el => {
        //console.log(el)
        el?.detail?.map(el2 => {
          //console.log(el2)
          el2?.options?.map(el3 => {
            //console.log(el3)
            arrSymptoms.push(el3.symptom_detail_option_id)
          })
        })
      }) 

      //console.log('mana3', temp);
      //console.log('mana3', arrSymptoms);

      for(let s of result) {
        let possibility = s.possibility
        let generalInfo = s.disease_general_information

        for(let i = 0; i < temp.generalInfo['eatings'].length; i++) {
          if(generalInfo.includes(temp.generalInfo['eatings'][i])) {
            possibility += 1
            //console.log("masuk2", temp.generalInfo['eatings'][i], possibility)
            break
          }
        } 
        for(let gi in temp.generalInfo) {
          //console.log("uh", gi, s.name, temp.generalInfo[gi], generalInfo, possibility)
          /* if(gi == "eatings") {
            //console.log("hallo", temp.generalInfo['eatings'])
          } */

          //console.log("mana", temp.generalInfo['eatings'].length)

          

          /* if(gi.toString() == 'eatings') { 
            //console.log("yes")
            for(let e of temp.generalInfo[gi]) {
              //console.log("masuk2")
              if(generalInfo.includes(e)) {
                possibility += 1
                return
              }
            } */
            
            //console.log(gi.toString())
          /* } else  */if(generalInfo.includes(temp.generalInfo[gi])) {
              possibility += 1
              //console.log("masuk", possibility)
              //break
          }
        }

        for(let i = 0; i < arrSymptoms.length; i++) {
          if(s.symptoms_disease.includes(arrSymptoms[i])) {
            possibility += 1
            //console.log("masuk2", arrSymptoms[i], possibility)
            //break
          }
        } 
        //s = {...s, possibility: possibility}
        s.possibility = Math.floor(possibility / (arrSymptoms.length + generalInfo.length) * 100)
        //console.log("afa benar", s.name, s.possibility, possibility)
      }

      

      let sortedDisease = result.sort((a, b) => b.possibility - a.possibility).slice(0, 3);

      //console.log("penyakit", sortedDisease)


      setDiseases(sortedDisease)


    }catch (e) {
        //console.log('failed to retrive', e)
    }
  }

  const initSymptoms = async () => {
    //let record = await DiagnoseModel.getByAppointmentUserandPatientId(id, patientID)
    let record = await DiagnoseModel.getDiagnoseById(diagnoseId)

    let res = await AppointmentModel.getAppointmentDetail(id)
    

    let tempPatient = res.patients.filter(obj => obj.id == record.patient_id)[0]

    let today = Date.now()
    let birthdate = new Date(tempPatient.birthdate)
    let diffTime = Math.abs(today - birthdate);
    let diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24)); 
    let diffMonths = Math.floor((diffDays % 365) / 30)
    let age = Math.floor(diffDays / 365)

    tempPatient.years = age
    tempPatient.months = diffMonths
    console.log("ini dia", tempPatient)
    setPatient(tempPatient)
    console.log("INI", record)

    if(record) {
      let resultSymptom = await SymptomModel.getAll();
      let oldSymptomDiseases = []
      let uniqueSymptoms = _.keys(
        _.countBy(record.diagnose_record_symptoms, data => {
          return data.symptom_id;
        })
      );
      let symptomsArray = [];
      uniqueSymptoms.forEach(ele => {
        //console.log('LOLOL', ele);
        let filteredSymptomDisease = record.diagnose_record_symptoms.filter(el2 => el2.symptom_id == ele);
        let newSymptom = filteredSymptomDisease[0].symptoms;
        let sd;
        let name;
        let tempDetail = [];
        let unique = [];
        
        filteredSymptomDisease.forEach(element => {
          if (!unique.includes(element.symptom_detail_options.symptoms_detail.detail_name)) {
            unique.push(element.symptom_detail_options.symptoms_detail.detail_name);
          }
        });
        //console.log("mencoba", filteredSymptomDisease)

        unique.forEach(dn => {
          let detail = filteredSymptomDisease
            .filter(el3 => el3.symptom_detail_options.symptoms_detail.detail_name == dn)
            .map((el3, idx) => {
              //console.log('WAKACIPAW', el3, filteredSymptomDisease);
              /* if (!oldSymptomDiseases.includes(el3.id)) {
                oldSymptomDiseases.push(el3.id);
              } */
              //if (idx == 0) name = filteredSymptomDisease[idx].symptom_detail_options.symptoms_detail.detail_name;
              let symptom = resultSymptom.filter(res => res.id == el3.symptom_id);
              //console.log(symptom, 'PPPP');
              sd = symptom[0].symptoms_detail.map((el, idx) => {
                //console.log('awooga', el);
                let a = {
                  detail_id: el.id,
                  detail_name: el.detail_name,
                  options: el.symptom_detail_options.map(sy => ({
                    symptom_id: symptom[0].id,
                    label: sy.options,
                    symptom_detail_option_id: sy.id,
                    value: sy.id
                  })),
                  type: el.type
                };
                //console.log('apa', a);
                return a;
              });

              return {
                id: el3.id,
                symptom_id: el3.symptom_id,
                label: el3.symptom_detail_options.options,
                symptom_detail_option_id: el3.symptom_detail_option_id,
                value: el3.symptom_detail_option_id
              };
            });

          tempDetail.push({ name: dn, options: [...detail] });
          //tempDetail[dn] = { name: dn, options: [...detail] };
        });

        //console.log(filteredSymptomDisease, 'POPOPOP', newSymptom);
        symptomsArray.push({ ...newSymptom, detail: tempDetail, symptoms_detail: sd });
      });
      /* //console.log('WKKK', uniqueSymptoms, record.diagnose_record_symptoms, symptomsArray);
      //console.log('USMP', record, resultSymptom, symptomsArray); */
      //setOldSymptomDiseaseID(oldSymptomDiseases)
      setSelectedSymptoms(symptomsArray);
    }
  }

  const newDiagnose = async () => {
    let temp = history.location.state
    let record = await DiagnoseModel.getDiagnoseById(diagnoseId)


    console.log("INI", record)
    let symArray = [];
    temp?.symptoms?.forEach(el => {
      el.detail?.forEach(det => {
        det.options?.forEach(opt => {
          symArray.push({ symptom_id: opt.symptom_id, symptom_detail_option_id: opt.symptom_detail_option_id });
        });
      });
    });

    let body = {
      appointment_user_id: parseInt(record.appointment_user_id),
      patient_id: parseInt(record.patient_id),
      doctor_id: parseInt(record.doctor_id),
      progress_status: "animal_data",
      is_accurate: null,
      symptoms: symArray
    }
    let res = await DiagnoseModel.create(body)
    
    if(res) {
      history.push({
        pathname: '/diagnose/' + id + '/general-data/' + res.id,
      });
    }
  }

  useEffect(() => {
    //setDataForDiagnose(history.location.state);
    
    initialize()
    initSymptoms()
    //newDiagnose()
  }, []);

  return (
    <>
      <div className={'container'}>
        <div style={{ marginBottom: 40 }}>
          <div className={'row mb-2'}>
            <div className={'d-flex'} style={{ gap: 20 }}>
              <Typography style={{ fontWeight: 600 }} variant={'h4'}>
                Diagnosis Result
              </Typography>
            </div>
          </div>
          <h3>Patient Info</h3>
          <div 
            style={{ /* background: "#1776cf", */ borderRadius: 20, padding: 5, /* color: "white", */ paddingLeft: 10, display : "flex", flexDirection : "row", alignItems : "center", marginBottom : 5, marginRight : 5/* , cursor : "pointer"  */}}
          >
            Animal Type: {patient?.animal_type}
          </div>
          <div 
            style={{ /* background: "#1776cf", */ borderRadius: 20, padding: 5, /* color: "white", */ paddingLeft: 10, display : "flex", flexDirection : "row", alignItems : "center", marginBottom : 5, marginRight : 5/* , cursor : "pointer"  */}}
          >
            Breed: {patient?.breed}
          </div>
          <div 
            style={{ /* background: "#1776cf", */ borderRadius: 20, padding: 5, /* color: "white", */ paddingLeft: 10, display : "flex", flexDirection : "row", alignItems : "center", marginBottom : 5, marginRight : 5/* , cursor : "pointer"  */}}
          >
            Gender: {patient?.patient_gender}
          </div>
          <div 
            style={{ /* background: "#1776cf", */ borderRadius: 20, padding: 5, /* color: "white", */ paddingLeft: 10, display : "flex", flexDirection : "row", alignItems : "center", marginBottom : 5, marginRight : 5/* , cursor : "pointer"  */}}
          >
            Age: {patient?.years} year(s) {patient?.months} month(s)
          </div>
          <h3>Symptoms</h3>
          {selectedSymptoms?.map((el, idx) => {
              return (     
                <div 
                  /* onClick={()=>{
                    setSymptomDetailToEdit(el);
                  }} */
                  style={{ /* background: "#1776cf", */ borderRadius: 20, padding: 5, /* color: "white", */ paddingLeft: 10, display : "flex", flexDirection : "row", alignItems : "center", marginBottom : 5, marginRight : 5/* , cursor : "pointer"  */}}
                >
                  {el.name} (
                  {el.detail
                    ?.filter(detailEl => detailEl.options != 'undefined' && detailEl.options != null)
                    .map((detailEl, idx2) => {
                      //console.log(detailEl, 'WOI');
                      return (
                        <>
                          {detailEl.name}:{' '}
                          {detailEl.options?.map((optionEl, idx3) => {
                            if (idx3 < detailEl.options.length - 1) return optionEl.label + ', ';
                            else return optionEl.label;
                          })}
                          {idx2 < el.detail.length - 1 ? ' | ' : ""}
                        </>
                      );
                    })}
                  )
                  </div>
                  );
                })}
          <br/>
          <div className={'row'}>
            <Typography
              style={{
                fontSize: 14
              }}
              variant={'subtitle1'}
            >
              Here are the some potential diseases on your patient based on the information you provided
            </Typography>
          </div>
        </div>
        {/* [
          {
            title: 'Disease A',
            possibility: 85
          },
          {
            title: 'Disease B',
            possibility: 70
          },
          {
            title: 'Disease C',
            possibility: 60
          }
        ] */
        diseases.length > 0 ?  diseases?.map(disease => {
          return (
            <>
              <Row className={'mb-3'}>
                <Col md={12} className={'mx-0 p-0'}>
                  <Card>
                    <Card.Header style={{ border: 'none' }}>
                      <div className={'d-flex'} style={{ gap: 20, alignItems: 'center' }}>
                        <Typography className={'diagnose-builder-header'}>{disease.name}</Typography>
                        <Badge className={'dg-primary diagnose-builder-badge'} bg="info" pill>
                          {disease.possibility}% possibility
                        </Badge>
                      </div>
                    </Card.Header>
                    <Card.Body style={{ padding: '0 2.25rem 2.25rem 2.25rem' }}>
                      <Typography className={'diagnose-builder-content'}>
                        {/* Lorem ipsum sit dolor amet lorem ipsum sit dolor amet lorem ipsum sit dolor amet lorem ipsum sit
                        dolor amet lorem ipsum sit dolor amet lorem ipsum sit dolor amet lorem ipsum sit dolor amet
                        lorem ipsum sit dolor amet lorem ipsum sit dolor amet lorem ipsum sit dolor amet lorem ipsum sit
                        dolor amet lorem ipsum sit dolor amet. */}
                        {disease.description != null ? disease.description : "No Description"}
                      </Typography>
                      <Row className={'mt-5'} style={{ padding: '1rem' }}>
                        <Typography className={'diagnose-builder-subheader'}>Action</Typography>
                      </Row>

                      {/* <Row>
                        <Col md={6}> */}
                          <ListGroup as="ol" numbered variant="flush">
                            <Row>
                            {/* {[...Array(6).keys()]?.map(v => { */}
                            {disease.action?.split('.')?.filter(v => v != '').map(v => {
                              return (
                                <Col md={6}>
                                  <ListGroup.Item style={{ borderBottom: 'none', border: "none" }} as="li">
                                    <div className={'d-flex'} style={{ gap: 10 }}>
                                      <span
                                        style={{
                                          position: 'relative',
                                          top: 5,
                                          width: 10,
                                          fontSize: 14,
                                          height: 10,
                                          borderRadius: '100%',
                                          background: '#1776CF'
                                        }}
                                      ></span>
                                      <div>{v}</div>
                                    </div>
                                  </ListGroup.Item>
                                </Col>
                              );
                            })}
                            </Row>
                          </ListGroup>
                        {/* </Col> */}
                        {/* <Col md={6}>
                          <ListGroup as="ol" numbered variant="flush">
                            {[...Array(6).keys()]?.map(v => {
                              return (
                                <>
                                  <ListGroup.Item style={{ borderBottom: 'none' }} as="li">
                                    <div className={'d-flex'} style={{ gap: 10 }}>
                                      <span
                                        style={{
                                          position: 'relative',
                                          top: 5,
                                          width: 10,
                                          fontSize: 14,
                                          height: 10,
                                          borderRadius: '100%',
                                          background: '#1776CF'
                                        }}
                                      ></span>
                                      <div>Lorem ipsum sit dolor amet lorem ipsum sit dolor amet</div>
                                    </div>
                                  </ListGroup.Item>
                                </>
                              );
                            })}
                          </ListGroup>
                        </Col> */}
                      {/* </Row> */}
                    </Card.Body>
                  </Card>
                </Col>
              </Row>
            </>
          );
        })
        :
        <>
          <Row className={'mb-3'}>
              <Col md={12} className={'mx-0 p-0'}>
                <div style={{textAlign: 'center', marginTop: '20vh', marginBottom: '20vh'}}>
                  <h3>No Diseases Found</h3>
                </div>          
              </Col>
          </Row>
        </> 
        }
        <Row className={'mb-5 mt-5'} style={{ justifyContent: 'center' }}>
          <Button
            variant="outline-primary"
            style={{
              fontSize: '16px',
              borderRadius: '24px',
              border: '1px solid #1776CF',
              background: '#FFF'
            }}
          >
            <AddIcon /> Add test lab result
          </Button>
        </Row>
        <Grid container style={{ marginBottom: 10 }}>
          <Grid className="d-flex align-items-center justify-content-center" item xs={12}>
            <div
              /* onClick={() => {
                history.push('/diagnose/' + id + '/symptom-form/' + patientID);
              }} */
              onClick={() => {
                history.push({
                  pathname: "/diagnose/" + id + "/symptom-form/" + diagnoseId,
                  state: {
                    animal_type_id: history.location.state?.animal_type_id,
                    generalInfo: {...history.location.state?.generalInfo},
                    symptoms: history.location.state?.symptoms
                  }
                })
              }} 
              className="d-flex align-items-center justify-content-center"
              style={{ background: '#1776CF', width: 50, height: 50, borderRadius: 5, cursor: 'pointer' }}
            >
              <FaArrowLeft size={20} color="white" />
            </div>
            <div style={{ flex: 1 }}></div>
            <div
              onClick={() => setShowEvaluation(true)}
              className="d-flex align-items-center justify-content-center"
              style={{
                background: '#1776CF',
                height: 50,
                borderRadius: 50,
                cursor: 'pointer',
                color: 'white',
                paddingLeft: 20,
                paddingRight: 20,
                fontSize: '1.1em',
                fontWeight: 600
              }}
            >
              Evaluate
              <div
                className="d-flex align-items-center justify-content-center"
                style={{ background: '#12121230', borderRadius: 20, width: 20, height: 20, marginLeft: 5 }}
              >
                <FaArrowRight size={12} color="white" />
              </div>
            </div>
          </Grid>
          <Grid style={{marginTop: 10}} className="d-flex align-items-center justify-content-center" item xs={12}>
            <div style={{ flex: 1 }}></div>
            <div
              onClick={() => newDiagnose()}
              className="d-flex align-items-center justify-content-center"
              style={{
                background: '#1776CF',
                height: 50,
                borderRadius: 50,
                cursor: 'pointer',
                color: 'white',
                paddingLeft: 20,
                paddingRight: 20,
                fontSize: '1.1em',
                fontWeight: 600
              }}
            >
              New Diagnose
              <div
                className="d-flex align-items-center justify-content-center"
                style={{ background: '#12121230', borderRadius: 20, width: 20, height: 20, marginLeft: 5 }}
              >
                <FaArrowRight size={12} color="white" />
              </div>
            </div>
          </Grid>

        </Grid>

        <DiagnoseBuilderEvaluation
          diseaseOptions={diseases}
          open={showEvaluation}
          onClose={() => {
            setShowEvaluation(false);
            swal({
              title: 'Done',
              text: 'Thank you for the evaluation',
              icon: 'success'
            });
          }}
        />
      </div>
    </>
  );
}
