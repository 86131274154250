import ApiRequest from '../../utils/APIRequest';

export default class appointmentModelAppointment {
  getIncomingAppointment = async () => {
    return await ApiRequest.set('v1/appointment-user/incoming', 'GET');
  };

  getAppointmentByDate = async (startDate, endDate) => {

    let result = await ApiRequest.set(`v2/appointment-user/date?start_date=${startDate}&end_date=${endDate}`, 'GET');

    let formattedAppointments = result.map(row=>{

      let formattedStatus = ""

      if (row.status === "DONE") {
        formattedStatus = "Done"
      } else if (row.payment_status === "PAID") {
        formattedStatus = "Need admin confirmation"
      } else if (row.payment_status === "PAID_CONFIRMED" || row.payment_status === "ADMIN_CONFIRMED") {
        formattedStatus = "Confirmed"
      } else if (row.payment_status === "UNPAID") {
        formattedStatus = "Waiting for Payment"
      } else if (row.payment_status === "EXPIRED") {
        formattedStatus = "Expired"
      } else if (row.status === "CANCELLED") {
        formattedStatus = "Cancelled"
      } else if (row.payment_status === "ADMIN_CREATED") {
        formattedStatus = "Created By Admin"
      } else {
        formattedStatus = `${row.status} | ${row.payment_status}`
      }

      return {
        ...row,
        formattedStatus : formattedStatus
      }
    })

    return formattedAppointments

  };

  getDoctorAppointmentByWeekForDoctor = async(dateStart, dateEnd) => {
    return await ApiRequest.set(`v2/appointment-user/date?start_date=${dateStart}&end_date=${dateEnd}`, 'POST',{});
}

  getAppointmentByDateAndClinicId = async (startDate, endDate, clinicId) => {
    return await ApiRequest.set(`v1/appointment-user/date/clinic`, 'POST', {start_date: startDate, end_date: endDate, clinic_id: clinicId});
  };

  getOwnedAppointmentByDateAndClinicId = async (startDate, endDate, clinicId) => {
    return await ApiRequest.set(`v1/appointment-user/date/clinic/owned`, 'POST', {start_date: startDate, end_date: endDate, clinic_id: clinicId});
  };

  confirmAppointment = async (id, start_time, end_time, operation_table_id) => {

    console.log("PARAM", id, start_time, end_time, operation_table_id)

    let params = {
      appointment_userid : id,
      start_time, end_time, operation_table_id
    }

    if(!operation_table_id){
      delete params.operation_table_id
    }

    return await ApiRequest.set('v1/appointment-user/confirm', 'POST',{
      ...params
    });
  };

  getMedicalRecordByAppointmentUserId = async (appointmentUserId) => {
    return await ApiRequest.set('v1/appointment-user/'+appointmentUserId, 'GET');
  };

  getMedicalRecordAppointment = async () => {
    return await ApiRequest.set('v1/appointment-user/done', 'GET');
  };

  getMedicalRecordAppointmentForDoctor = async () => {
    return await ApiRequest.set('v1/appointment-user/done', 'POST');
  };

  editAppointment = async (id, values) => {

    return await ApiRequest.set('v1/appointment-user/'+id, 'PUT', values);
  };

  deleteAppointmentUser = async (id) => {

    return await ApiRequest.set('v1/appointment-user/'+id, 'DELETE');
  };

  editAppointmentSlot = async (id, values) => {

    return await ApiRequest.set('v1/appointment-slot/timeslot/'+id, 'PUT', values);
  };

  blockAppointmentSlot = async (id, values) => {
    return await ApiRequest.set('v1/appointment-slot/timeslot-ban/'+id, 'PUT', values);
  };

  appointmentEditDoctorID = async (values) => {
    console.log("sending", values)
    return await ApiRequest.set('v1/appointment-slot/edit_doctor_id', 'POST', values);
  };

  appointmentEditOwnerIDAndPatientID = async (values) => {
    console.log("sending", values)
    return await ApiRequest.set('v1/appointment/patient_owner/edit', 'PUT', values);
  };

  rejectAppointment = async (userId, reason) => {
    return await ApiRequest.set('v1/appointment-user/reject', 'DELETE', {
      appointment_userid : userId,
      rejected_reason : reason
    });
  };

}
