import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { format, parseISO } from 'date-fns';
import { useParams, useHistory } from 'react-router-dom';
import { Button, Grid } from '@material-ui/core';

import { Card, CardBody, CardHeader } from '_metronic/_partials/controls';
import { DataTableLocal as DataTable } from '../../../components/common/DataTable';
import LoadingCard from 'app/components/common/LoadingCard/LoadingCard';

import { fetchMedicalRecordListByPatientID } from 'redux/medicalRecord/list';
import MedicalRecord from 'app/models/MedicalRecord';
import Vaccination from "../../../models/Vaccination";

const VaccinationListCardForAppointmentUser = ({ appointmentUserId: appointmentUserId }) => {

  const {id} = useParams()
  const history = useHistory();

  const [vaccinationList, setVaccinationList] = useState([])
  const [isLoading, setIsLoading] = useState(false)

  const getVaccinationList = async () => {
    try{
      let result = await Vaccination.getVaccinationByPatientId(parseInt(id))
      console.log(result)
      setVaccinationList(result)
    }catch(e){
      console.log('error: ',e)
    }
  }

  useEffect(()=>{
    getVaccinationList()
  },[])

  const handleNewVaccination = () => {
    history.push({
      pathname: '/vaccination/'+id,
    });
  };

  const columns = [
    {
      dataField: 'vaccination_date',
      text: 'Vaccination Date',
      formatter: (cell, row, rowIndex, formatExtraData) => {
        return cell && format(parseISO(cell), 'dd MMMM yyyy');
      }
    },
    {
      dataField: 'doctor_name',
      text: 'Doctor name',
      style: {
        width: '300px'
      },
      formatter: (cell, row, rowIndex, formatExtraData) => {
        return cell && <p style={{whiteSpace : "pre-line"}}>{cell}</p>
      },
    },
    {
      dataField: 'description',
      text: 'Description',
      style: {
        width: '300px'
      },
      formatter: (cell, row, rowIndex, formatExtraData) => {
        return cell && <p style={{whiteSpace : "pre-line"}}>{cell}</p>
       },
    },
  ];

  if (isLoading) {
    <LoadingCard />;
  }

  return (
    <Card>
      <CardHeader
        title="Vaccination list"
        toolbar={
          <Grid item className="my-6">
            <Button color="primary" variant="contained" onClick={handleNewVaccination}>
              New Vaccination
            </Button>
          </Grid>
        }
      />
      <CardBody>
        <DataTable
          data={vaccinationList}
          loading={false}
          totalDataCount={vaccinationList.length}
          columns={columns}
          editLink={`/vaccination/edit/${id}/`}
        />
      </CardBody>
    </Card>
  );
};

export default VaccinationListCardForAppointmentUser;
