import APIRequest from "../utils/APIRequest";

export default class PatientModel {

    static updatePatient = async(id, body)=>{
        console.log("bodd", body)
        return await APIRequest.set(`v1/adminPatient/${id}`, 'PUT', {
            ...body
        });
    }
    static addPatient = async(body)=>{
        return await APIRequest.set(`v1/adminPatient`, 'POST', body);
    }
    static deletePatient = async(id)=>{
        return await APIRequest.set(`v1/adminPatient/${id}`, 'DELETE');
    }


}
