import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { Grid } from '@material-ui/core';
import { useFormik } from 'formik';
import * as Yup from 'yup';

import {
  Card,
  CardBody,
  CardHeader
} from '../../../_metronic/_partials/controls';
import { EditHeaderToolbar as HeaderToolbar } from '../../components/common/HeaderToolbar';
import { TextField } from '../../components/common/TextField';
import { ActionBarEdit as ActionBar } from '../../components/common/ActionBar';
import { fetchMedicine } from '../../../redux/medicine/detail';
import { editMedicine, deleteMedicine } from '../../../redux/medicine/ops';

const SymptomEditPage = () => {
  // Prereq Hooks
  const dispatch = useDispatch();

  const { id } = useParams();

  useEffect(() => {
    dispatch(fetchMedicine(id));
  }, []);

  const { data } = useSelector(state => state.medicine.detail);
  // useEffect(() => {
  //   console.log(data);
  // }, [data]);

  const { completed } = useSelector(state => state.medicine.ops);
  const isFetching = useSelector(state => state.loading.medicine);
  useEffect(() => {
    if (completed) {
      setIsEditing(false);
    }
  }, [completed]);

  const [isEditing, setIsEditing] = useState(false);

  const {
    values,
    errors,
    touched,
    handleChange,
    handleSubmit,
    resetForm
  } = useFormik({
    initialValues: {
      name: data?.medicine_name || '',
      sideEffect: data?.side_effect || '',
      dosage: data?.dosage_info || ''
    },
    validationSchema: Yup.object({
      name: Yup.string().required('Cannot be empty')
    }),
    onSubmit: values => {
      dispatch(editMedicine(id, values));
    },
    enableReinitialize: true
  });

  return (
    <Card>
      <CardHeader title="Edit Medicine">
        <HeaderToolbar
          isEditing={isEditing}
          handleEdit={() => setIsEditing(true)}
          handleCancel={() => {
            resetForm();
            setIsEditing(false);
          }}
        />
      </CardHeader>
      <CardBody>
        <form onSubmit={handleSubmit}>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <TextField
                disabled={!isEditing}
                id="name"
                value={values.name}
                onChange={handleChange}
                label="Medicine Name *"
                helperText={touched.name && errors.name ? errors.name : ''}
                error={touched.name && errors.name ? true : false}
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                disabled={!isEditing}
                id="sideEffect"
                value={values.sideEffect}
                onChange={handleChange}
                label="Side Effects"
                helperText={
                  touched.sideEffect && errors.sideEffect
                    ? errors.sideEffect
                    : ''
                }
                error={touched.sideEffect && errors.sideEffect ? true : false}
                multiline
                rows={4}
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                disabled={!isEditing}
                id="dosage"
                value={values.dosage}
                onChange={handleChange}
                label="Dosage Info"
                helperText={
                  touched.dosage && errors.dosage ? errors.dosage : ''
                }
                error={touched.dosage && errors.dosage ? true : false}
                multiline
                rows={4}
              />
            </Grid>
            <ActionBar
              isEditing={isEditing}
              isFetching={isFetching}
              handleSubmit={handleSubmit}
              handleDelete={() => dispatch(deleteMedicine(id))}
            />
          </Grid>
        </form>
      </CardBody>
    </Card>
  );
};

export default SymptomEditPage;
