import React from 'react';
import LoadingOverlay from 'react-loading-overlay';

export const overlayFactory = options => loading => 
  class TableLoadingOverlayWrapper extends React.Component {

    render() {
      return (
        <LoadingOverlay
          active={loading}
          spinner
          text='Loading'
          styles={{
            overlay: (base) => ({
              ...base,
              background: '#FFFFFF',
              height: '100%',
            }),
            content: (base) => ({
              ...base,
              color: '#181C32'
            }),
            spinner: (base) => ({
              ...base,
              '& svg circle': {
                stroke: '#181C32'
              }
            })
          }}
        >
          { this.props.children }
        </LoadingOverlay>
      );
    }
  };