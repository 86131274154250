import HorizontalSeparator from 'app/components/common/HorizontalSeparator/HorizontalSeparator';
import {
  Button,
  Col,
  Grid,
  MenuItem,
  InputLabel,
  Input,
  OutlinedInput,
  Box,
  Chip,
  Checkbox,
  ListItemText,
  Modal
} from '@material-ui/core';
import { Link, useHistory } from 'react-router-dom';
import { TextField } from '../../components/common/TextField';
import { Card, CardBody, CardHeader, CardHeaderToolbar } from '../../../_metronic/_partials/controls';
import React, { useState, useEffect } from 'react';
import MultipleSelect from 'app/components/common/MultipleSelect/MultipleSelect';
import { Autocomplete } from '@material-ui/lab';
import DiseaseModel from '../../models/Disease';
import SymptomModel from '../../models/Symptom';
import generalInfoModel from '../../models/GeneralInformation';
import Select from 'react-select';
import { Row } from 'react-bootstrap';
import swal from 'sweetalert';
import DiseaseSymptomSelectionModal from './DiseaseSymptomSelectionModal';
const DiseaseAddPageV2 = () => {
  const [personName, setPersonName] = useState([]);

  const [animalOptions, setAnimalOptions] = useState([]);
  //const [bodyPartOptions, setBodyPartOptions] = useState([]);
  const [temperatureOptions, setTemperatureOptions] = useState([]);
  const [drinkingOptions, setDrinkingOptions] = useState([]);
  const [eatingOptions, setEatingOptions] = useState([]);
  const [foodTypeOptions, setFoodTypeOptions] = useState([]);
  const [generalConditionOptions, setGeneralConditionOptions] = useState([]);
  const [symptomOptions, setSymptomOptions] = useState([]);

  const [selectedName, setSelectedName] = useState('');
  const [selectedAnimal, setSelectedAnimal] = useState(null);
  const [selectedDescription, setSelectedDescription] = useState('');
  const [selectedAction, setSelectedAction] = useState('');
  const [selectedBodyPart, setSelectedBodyPart] = useState([]);
  const [selectedTemperature, setSelectedTemperature] = useState(null);
  const [selectedDrinking, setSelectedDrinking] = useState(null);
  const [selectedEating, setSelectedEating] = useState(null);
  const [selectedFood, setSelectedFood] = useState(null);
  const [selectedGeneralCondition, setSelectedGeneralCondition] = useState(null);
  const [selectedSymptoms, setSelectedSymptoms] = useState([]);
  const [modalData, setModalData] = useState(null);

  const [symptomDetailToEdit, setSymptomDetailToEdit] = useState(null);

  // const [tmpSymptom, setTmpSymptom] = useState([]);
  const history = useHistory();

  /* const handleChangeMultiple = event => {
    const { options } = event.target;
    const value = [];
    for (let i = 0, l = options.length; i < l; i += 1) {
      if (options[i].selected) {
        value.push(options[i].value);
      }
    }
    setPersonName(value);
  }; */

  /* let animalOptions = [
    {
      value: 'Dog',
      label: 'Dog'
    },
    {
      value: 'Cat',
      label: 'Cat'
    },
    {
      value: 'Rabbit',
      label: 'Rabbit'
    }
  ]; */

  /* let bodyPartOptions = [
    {
      value: 'Mouth',
      label: 'Mouth'
    },
    {
      value: 'Nose',
      label: 'Nose'
    },
    {
      value: 'Stomach',
      label: 'Stomach'
    },
    {
      value: 'Fur',
      label: 'Fur'
    },
    {
      value: 'Leg',
      label: 'Leg'
    }
  ]; */

  /* let temperatureOptions = [
    {
      value: '>38',
      label: 'Over 38 Celcius'
    },
    {
      value: '36-38',
      label: 'Between 36-38 Celcius'
    },
    {
      value: '<36',
      label: 'Below 36 Celcius'
    }
  ]; */

  /* let drinkingOptions = [
    {
      value: 'Very Little',
      label: 'Very Little'
    },
    {
      value: 'Little',
      label: 'Little'
    },
    {
      value: 'Normal',
      label: 'Normal'
    },
    {
      value: 'Much',
      label: 'Much'
    },
    {
      value: 'Too Much',
      label: 'Too Much'
    } 
  ]; */

  /* let eatingOptions = [
    {
      value: '1x',
      label: '1x'
    },
    {
      value: '2x',
      label: '2x'
    },
    {
      value: '3x',
      label: '3x'
    },
    {
      value: '>5x',
      label: '>5x'
    }
  ]; */

  /* let foodTypeOptions = [
    {
      value: 'Raw food',
      label: 'Raw food'
    },
    {
      value: 'Canned food',
      label: 'Canned food'
    },
    {
      value: 'Liquid',
      label: 'Liquid'
    }
  ]; */

  console.log('SYTO', symptomOptions, selectedSymptoms);

  const initializeGeneralInfo = async () => {
    try {
      let result = await generalInfoModel.getAll();
      let resultSymptom = await SymptomModel.getAllActive();

      console.log(resultSymptom);
      console.log(result);

      setSymptomOptions(resultSymptom);

      const uniqueGenInfo = [...new Set(result.generalinformation.map(item => item.category))];
      console.log(uniqueGenInfo);

      setTemperatureOptions(
        result.generalinformation
          .filter(el => el.category == 'body_temperature')
          .map(a => ({
            value: a.id,
            label: a.value
          }))
      );
      console.log(drinkingOptions);

      setDrinkingOptions(
        result.generalinformation
          .filter(el => el.category == 'drinking')
          .map(a => ({
            value: a.id,
            label: a.value
          }))
      );
      console.log(drinkingOptions);

      setFoodTypeOptions(
        result.generalinformation
          .filter(el => el.category == 'food_type')
          .map(a => ({
            value: a.id,
            label: a.value
          }))
      );
      console.log(foodTypeOptions);

      setEatingOptions(
        result.generalinformation
          .filter(el => el.category == 'eating')
          .map(a => ({
            value: a.id,
            label: a.value
          }))
      );
      console.log(eatingOptions);

      setGeneralConditionOptions(
        result.generalinformation
          .filter(el => el.category == 'general_condition')
          .map(a => ({
            value: a.id,
            label: a.value
          }))
      );
      console.log(generalConditionOptions);

      setAnimalOptions(
        result.animal_types.map(a => ({
          value: a.id,
          label: a.animal_name
        }))
      );
      console.log(result.animal_types);
      /* let tempDrink = result.generalInformation.drinking.map((a) => ({
          value: a.value,
          label: a.value
      }))
      setDrinkingOptions(tempDrink)

      let tempFood = result.generalInformation.food_type.map((a) => ({
        value: a.value,
        label: a.value
      }))
      setFoodTypeOptions(tempFood)

      let tempEating = result.generalInformation.eating.map((a) => ({
        value: a.value,
        label: a.value
      }))
      setEatingOptions(tempEating)

      let tempTemperature = result.generalInformation.body_temperature.map((a) => ({
        value: a.value,
        label: a.value
      }))
      setTemperatureOptions(tempTemperature)

      let tempGeneral = result.generalInformation.general_condition.map((a) => ({
        value: a.value,
        label: a.value
      }))
      setGeneralConditionOptions(tempGeneral) */
    } catch (e) {
      console.log('error', e);
    }
  };

  const submit = async () => {
    //console.log(selectedSymptoms);
    if (selectedName == null || selectedName == '' || selectedName == []) {
      swal({
        title: 'Name',
        text: 'Name cannot be empty',
        icon: 'error'
      });
      return;
    }
    if (selectedAnimal == null || selectedAnimal == '' || selectedAnimal == []) {
      swal({
        title: 'Animal',
        text: 'Animal cannot be empty',
        icon: 'error'
      });
      return;
    }
    if (selectedAction == null || selectedAction == '' || selectedAction == []) {
      swal({
        title: 'Action',
        text: 'Action cannot be empty',
        icon: 'error'
      });
      return;
    }
    /* if (selectedTemperature == null || selectedTemperature == '' || selectedTemperature == []) {
      swal({
        title: 'Body Temperature',
        text: 'Body temperature cannot be empty',
        icon: 'error'
      });
      return;
    }
    if (selectedDrinking == null || selectedDrinking == '' || selectedDrinking == []) {
      swal({
        title: 'Drinking',
        text: 'Drinking cannot be empty',
        icon: 'error'
      });
      return;
    }
    if (selectedEating == null || selectedEating == '' || selectedEating == []) {
      swal({
        title: 'Food Frequency',
        text: 'Food frequency cannot be empty',
        icon: 'error'
      });
      return;
    }
    if (selectedFood == null || selectedFood == '' || selectedFood == []) {
      swal({
        title: 'Food Type',
        text: 'Food type cannot be empty',
        icon: 'error'
      });
      return;
    }
    if (selectedGeneralCondition == null || selectedGeneralCondition == '' || selectedGeneralCondition == []) {
      swal({
        title: 'General Condition',
        text: 'General condition cannot be empty',
        icon: 'error'
      });
      return;
    } */
    /* if (selectedSymptoms == null || selectedSymptoms == '' || selectedSymptoms == []) {
      swal({
        title: 'Symptom',
        text: 'Symptoms cannot be empty',
        icon: 'error'
      });
      return;
    } */

    let generalInfo = [];
    selectedDrinking?.forEach(element => {
      generalInfo.push(element.value);
    });
    selectedEating?.forEach(element => {
      generalInfo.push(element.value);
    });
    selectedFood?.forEach(element => {
      generalInfo.push(element.value);
    });
    selectedGeneralCondition?.forEach(element => {
      generalInfo.push(element.value);
    });
    selectedTemperature?.forEach(element => {
      generalInfo.push(element.value);
    });
    let body = {
      name: selectedName,
      description: selectedDescription,
      action: selectedAction,
      animal_type_id: selectedAnimal?.value,
      general_informations: generalInfo
    };
    //console.log(body, selectedSymptoms)
    let symArray = [];
    selectedSymptoms?.forEach(el => {
      el.detail?.forEach(det => {
        det.options?.forEach(opt => {
          symArray.push({ symptom_id: opt.symptom_id, symptom_detail_options_id: opt.symptom_detail_option_id });
        });
      });
    });
    body.symptoms = symArray;
    console.log(body, 'BODY');

    try {
      let res = await DiseaseModel.create(body);
      //console.log("apa itu res", res)

      swal({
        title: 'Success',
        text: "Disease '" + res.name + "' has been added",
        icon: 'success'
      }).then(() => {
        history.push("/disease/" + res.id)
      });

      /* alert("Disease '" + res.name + "' has been added successfully")

      window.location.href = `/disease/${res.id}` */
    } catch (error) {
      swal({
        title: 'Error',
        text: 'Cannot create new disease',
        icon: 'error'
      });
    }
  };

  const reset = () => {
    setSelectedAction('');
    setSelectedAnimal(null);
    setSelectedDrinking(null);
    setSelectedEating(null);
    setSelectedFood(null);
    setSelectedGeneralCondition(null);
    setSelectedName('');
    setSelectedSymptoms(null);
    setSelectedTemperature(null);
  };

  useEffect(() => {
    initializeGeneralInfo();
  }, []);

  return (
    <>
      <Card>
        <CardHeader title="Disease details" />
        <CardBody>
          <form>
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <TextField
                  // id="name"
                  value={selectedName}
                  onChange={e => setSelectedName(e.target.value)}
                  label="Disease Name *"
                  input={<OutlinedInput label="Tag" />}
                  // helperText={touched.name ? errors.name : ''}
                  // error={touched.name && errors.name ? true : false}
                />
              </Grid>
            </Grid>
            <HorizontalSeparator text={'Animal'} />
            <Grid container spacing={2}>
              <Grid item xs={12} sm={6}>
                <MultipleSelect
                  label={'Type'}
                  options={animalOptions}
                  selected={selectedAnimal}
                  setSelected={setSelectedAnimal}
                  isMulti={false}
                />
                {/* <Select
                  isMulti
                  name="animal"
                  options={animalOptions}
                  onChange={e => setSelectedAnimal(e.target.value)}
                  className="basic-multi-select"
                  classNamePrefix="select"
                /> */}
              </Grid>
              {/* <Grid item xs={12} sm={6}>
                <MultipleSelect
                  label={'Body Part associated with the disease'}
                  options={bodyPartOptions}
                  selected={selectedBodyPart}
                  setSelected={setSelectedBodyPart}
                />
              </Grid> */}
            </Grid>
            <HorizontalSeparator text={'Description'} />
            <TextField
              rows={6}
              fullWidth
              multiline
              variant="outlined"
              value={selectedDescription}
              onChange={e => setSelectedDescription(e.target.value)}
            />
            <HorizontalSeparator text={'Action'} />
            <TextField
              rows={6}
              fullWidth
              multiline
              variant="outlined"
              value={selectedAction}
              onChange={e => setSelectedAction(e.target.value)}
            />
            <HorizontalSeparator text={'General Information'} />
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <b>Body Temperature</b>
              </Grid>
              <Grid item xs={12} sm={6}>
                <MultipleSelect
                  options={temperatureOptions}
                  selected={selectedTemperature}
                  setSelected={setSelectedTemperature}
                />
              </Grid>
            </Grid>
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <b>Drinking</b>
              </Grid>
              <Grid item xs={12} sm={6}>
                <MultipleSelect
                  label={'Frequency (in a day)'}
                  options={drinkingOptions}
                  selected={selectedDrinking}
                  setSelected={setSelectedDrinking}
                />
              </Grid>
            </Grid>
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <b>Eating</b>
              </Grid>
              <Grid item xs={12} sm={6}>
                <MultipleSelect
                  label={'Frequency (in a day)'}
                  options={eatingOptions}
                  selected={selectedEating}
                  setSelected={setSelectedEating}
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <MultipleSelect
                  label={'Food type'}
                  options={foodTypeOptions}
                  selected={selectedFood}
                  setSelected={setSelectedFood}
                />
              </Grid>
            </Grid>
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <b>General Condition</b>
              </Grid>
              <Grid item xs={12} sm={12}>
                <MultipleSelect
                  label={''}
                  options={generalConditionOptions}
                  selected={selectedGeneralCondition}
                  setSelected={setSelectedGeneralCondition}
                />
              </Grid>
            </Grid>
            <HorizontalSeparator text={'Symptoms'} />
            <Autocomplete
              id="combo-box-demo"
              // options={symptomOptions ? : []}
              options={
                symptomOptions
                  ? symptomOptions?.filter(obj => {
                      for (let ss of selectedSymptoms) {
                        if (obj.id === ss.id) {
                          return false;
                        }
                      }
                      return true;
                    })
                  : []
              }
              getOptionLabel={option => option.name}
              style={{ width: 300 }}
              onChange={(event, value) => {
                let sd = value.symptoms_detail.map((el, idx) => {
                  console.log(value);
                  let a = {
                    detail_name: el.detail_name,
                    options: el.symptom_detail_options.map(sy => ({
                      symptom_id: value.id,
                      label: sy.options,
                      symptom_detail_option_id: sy.id,
                      value: sy.id
                    }))
                  };
                  return a;
                });
                value = { ...value, symptoms_detail: sd };
                console.log(value);
                setModalData(value);
              }}
              value={modalData}
              renderTags={selectedSymptoms => (
                <div
                  style={{
                    display: 'flex',
                    flexWrap: 'wrap',
                    padding: '0rem'
                  }}
                >
                  {selectedSymptoms.map(el => (
                    <Chip
                      key={el.id}
                      label={el.name}
                      onMouseDown={event => {
                        event.stopPropagation();
                      }}
                      style={{
                        margin: 2,
                        marginTop: 0,
                        marginBottom: 0,
                        borderRadius: '0.25rem'
                      }}
                    />
                  ))}
                </div>
              )}
              renderInput={params => (
                <TextField {...params} label="Search symptoms, e.g. vomiting" variant="outlined" />
              )}
            />
            <Grid container style={{ marginTop: '0.75rem' }} spacing={2}>
              {selectedSymptoms?.map((el, idx) => {
                return (
                  <Grid item md={3} sm={4} xs={12}>
                    <Card
                      onClick={() => {
                        console.log('MDD PARAM', el);
                        setSymptomDetailToEdit(el);
                      }}
                      style={{ backgroundColor: 'rgba(18, 18, 18, 0.24)', color: '#666', cursor: 'pointer' }}
                    >
                      <CardBody style={{ padding: '0.25rem 0.38rem 0.25rem 0.5rem' }}>
                        <div
                          style={{
                            display: 'flex',
                            flexDirection: 'row',
                            justifyContent: 'space-between',
                            cursor: 'pointer'
                          }}
                        >
                          <div>{el.name}</div>
                          <div
                            onClick={e => {
                              let temp = [...selectedSymptoms];
                              temp.splice(idx, 1);
                              setSelectedSymptoms(temp);
                              e.stopPropagation();
                            }}
                            style={{
                              color: 'white',
                              backgroundColor: 'rgba(18, 18, 18, 0.24)',
                              borderRadius: 999999,
                              width: '1rem',
                              height: '1rem',
                              display: 'flex',
                              justifyContent: 'center',
                              alignItems: 'center',
                              cursor: 'pointer'
                            }}
                          >
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              width="12"
                              height="12"
                              viewBox="0 0 12 12"
                              fill="none"
                            >
                              <path
                                d="M3 9L9 3M3 3L9 9"
                                stroke="white"
                                stroke-linecap="round"
                                stroke-linejoin="round"
                              />
                            </svg>
                          </div>
                        </div>
                        <ul>
                          {el.detail
                            ?.filter(detailEl => detailEl.options != 'undefined' && detailEl.options != null)
                            .map((detailEl, idx2) => {
                              console.log(detailEl, 'WOI');
                              return (
                                <li>
                                  <span>
                                    {detailEl.name}:{' '}
                                    {detailEl.options?.map((optionEl, idx3) => {
                                      if (idx3 < detailEl.options.length - 1) return optionEl.label + ', ';
                                      else return optionEl.label;
                                    })}
                                  </span>
                                </li>
                              );
                            })}
                        </ul>
                      </CardBody>
                    </Card>
                  </Grid>
                );
              })}
            </Grid>
          </form>
          <br />
          <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'flex-end' }}>
            <Button color={'primary'} variant="contained" onClick={submit}>
              Save
            </Button>
          </div>
        </CardBody>
      </Card>

      <DiseaseSymptomSelectionModal
        modalData={modalData}
        onClose={() => setModalData(null)}
        onSubmit={tmpSymptom => {
          let tmp = selectedSymptoms ? [...selectedSymptoms] : [];
          let tmp2 = [...tmpSymptom];
          tmp2 = tmp2.map((el, idx) => ({ name: modalData?.symptoms_detail[idx]?.detail_name, options: el }));
          //console.log(tmp2, 'oi');
          tmp.push({ name: modalData.name, detail: tmp2, id: modalData.id, ...modalData });
          //console.log(tmp, 'oi');
          console.log('UPDATERESULTCREATE', tmp);
          setSelectedSymptoms(tmp);
        }}
      />

      <DiseaseSymptomSelectionModal
        modalData={symptomDetailToEdit}
        onClose={() => setSymptomDetailToEdit(null)}
        onSubmit={tmpSymptom => {
          let currentIndex = selectedSymptoms.findIndex(obj => obj.id === symptomDetailToEdit.id);
          let temp = [...selectedSymptoms];

          let tmp2 = [...tmpSymptom];
          tmp2 = tmp2.map((el, idx) => ({ name: symptomDetailToEdit?.symptoms_detail[idx]?.detail_name, options: el }));

          temp[currentIndex] = { ...temp[currentIndex], detail: tmp2 };
          console.log('UPDATERESULTUPDATE', temp);

          setSelectedSymptoms(temp);

          // let tmp = selectedSymptoms ? [...selectedSymptoms] : [];
          // let tmp2 = [...tmpSymptom];
          // tmp2 = tmp2.map((el, idx) => ({ name: modalData?.symptoms_detail[idx]?.detail_name, options: el }));
          // //console.log(tmp2, 'oi');
          // tmp.push({ name: modalData.name, detail: tmp2, id : modalData.id});
          // //console.log(tmp, 'oi');
          // setSelectedSymptoms(tmp);
        }}
      />
    </>
  );
};

export default DiseaseAddPageV2;
