import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  Card,
  CardBody,
  CardHeader,
  CardHeaderToolbar
} from '../../../_metronic/_partials/controls';

import { ListHeaderToolbar as HeaderToolbar } from '../../components/common/HeaderToolbar';
import { DataTableLocal as DataTable } from '../../components/common/DataTable';
import { fetchParticipantsList } from '../../../redux/participant/list';

const ParticipantListPage = () => {
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(fetchParticipantsList());
  }, []);

  const { data, isFetching } = useSelector(state => state.participant.list);

  const columns = [
    {
      dataField: 'full_name',
      text: 'Name'
    },
    {
      dataField: 'youtube_name',
      text: 'Youtube Name'
    },
    {
      dataField: 'youtube_email',
      text: 'Youtube Email'
    },
    {
      dataField: 'phone_number',
      text: 'Phone'
    }
  ];

  return (
    <Card>
      <CardHeader title="Participant List">
        {/* <CardHeaderToolbar>
          <HeaderToolbar addLink="/animalType/add" addText="New Type" />
        </CardHeaderToolbar> */}
      </CardHeader>
      <CardBody>
        <DataTable
          data={data}
          loading={isFetching}
          totalDataCount={data.length}
          columns={columns}
          editLink="/participant/"
        />
      </CardBody>
    </Card>
  );
};

export default ParticipantListPage;
