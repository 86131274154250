import api from '../../api/userAPI';
import { setErrorNotification } from '../snackbarNotification';

export const FETCH_BEGIN = 'patient.list/FETCH_BEGIN';
export const FETCH_SUCCESS = 'patient.list/FETCH_SUCCESS';
export const FETCH_FAILED = 'patient.list/FETCH_FAILED';

const fetchBegin = () => ({ type: FETCH_BEGIN });
const fetchSuccess = data => ({ type: FETCH_SUCCESS, payload: data });
const fetchFailed = error => ({ type: FETCH_FAILED, payload: error });
export const fetchPatientList = () => async dispatch => {
  dispatch(fetchBegin());

  try {
    const res = await api('retrieve-patient');

    dispatch(fetchSuccess(res.data.result));
  } catch (error) {
    dispatch(fetchFailed(error.toString()));
    dispatch(setErrorNotification(error.toString()));
  }
};

export const fetchPatientListByCustomerID = customerID => async dispatch => {
  dispatch(fetchBegin());

  try {
    const res = await api(`retrieve-patient?owner_id=${customerID}`);

    console.log("patients", res)

    dispatch(fetchSuccess(res.data.result));
  } catch (error) {
    dispatch(fetchFailed(error.toString()));
    dispatch(setErrorNotification(error.toString()));
  }
};

const reducer = (state = initialState, action) => {
  const { type, payload } = action;

  switch (type) {
    case FETCH_BEGIN:
      return { ...state, data: [], isLoading: true, error: null };
    case FETCH_SUCCESS:
      return { ...state, data: payload, isLoading: false };
    case FETCH_FAILED:
      return { ...state, isLoading: false, error: payload };

    default:
      return state;
  }
};

export default reducer;

const initialState = {
  data: [],
  isLoading: false,
  error: null
};

const DUMMY_CUSTOMER_LIST = [
  { id: 1, name: 'John Doe', phone: '08123123123', email: 'john@mail.com' },
  { id: 2, name: 'Mary Sue', phone: '08125125125', email: 'mary@mail.com' },
  {
    id: 3,
    name: 'Budi Darmawan',
    phone: '08124124124',
    email: 'budi@mail.com'
  },
  { id: 4, name: 'Gary Stu', phone: '08123123123', email: 'gary@mail.com' },
  { id: 5, name: 'Wowo Jidodo', phone: '08125125125', email: 'wowo@mail.com' },
  { id: 6, name: 'Arif Setiawan', phone: '08124124124', email: 'arif@mail.com' }
];
