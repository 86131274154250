import React, {useEffect, useState} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {
    Card,
    CardBody,
    CardHeader
} from '../../../_metronic/_partials/controls';
import {Grid, IconButton, MenuItem, Tooltip} from '@material-ui/core';
import {CardMembership, Delete} from '@material-ui/icons';
import {ListHeaderToolbar} from '../../components/common/HeaderToolbar';
import {DataTableLocal as DataTable} from '../../components/common/DataTable';
import {fetchPatientList} from '../../../redux/patient/list';
import PatientCard from './components/PatientCard'
import swal from "sweetalert2";
import PatientModel from "../../../model/PatientModel";

export default function CustomerListPage() {
    // Prereq Hooks
    const dispatch = useDispatch();

    // Initial fetch
    useEffect(() => {
        dispatch(fetchPatientList());
    }, []);

    const {data} = useSelector(state => state.patient.list);
    const isFetching = useSelector(state => state.loading.patient);
    const [selectedPatientCard, setSelectedPatientCard] = useState(null)
    const [showCardDialog, setShowCardDialog] = useState(false)
    const columns = [
        {
            dataField: 'patient_name',
            text: 'name'
        },
        {
            dataField: 'animal_name',
            text: 'animal'
        },
        {
            dataField: 'pet_owner_name',
            text: 'owner'
        },
    ];


    return (
        <>
            <PatientCard
                patientData={selectedPatientCard}
                showDialog={showCardDialog}
                toogleSelected={showDialog => setShowCardDialog(false)}
            />
            <Card>
                <CardHeader title="Patient lists">
                    <ListHeaderToolbar addLink="/patient/add" addText="New Patient"/>
                </CardHeader>
                <CardBody>

                    <DataTable
                        renderCustomActions={row => {
                            return (
                                <>
                                    <IconButton
                                        onClick={async (e) => {
                                            let result = await swal.fire({
                                                title: 'Are You Sure',
                                                text: 'Are you sure you want to delete patient',
                                                icon: 'warning',
                                                confirmButtonText: 'Yes',
                                                cancelButtonText: "No",
                                                showCancelButton: true,
                                                customClass: {
                                                    container: 'swal-z'
                                                }
                                            });
                                            if (result.isConfirmed) {
                                                let result = await PatientModel.deletePatient(row.id)
                                                dispatch(fetchPatientList())
                                            }
                                        }}
                                        size="small"
                                    >
                                        <Delete/>
                                    </IconButton>
                                    <IconButton
                                        onClick={(e) => {
                                            setSelectedPatientCard(row)
                                            setShowCardDialog(true)
                                        }}
                                        size="small"
                                    >
                                        <CardMembership/>
                                    </IconButton>
                                </>
                            );
                        }}
                        data={data}
                        loading={isFetching}
                        totalDataCount={data.length}
                        columns={columns}
                        editLink="/patient/"
                    />
                </CardBody>
            </Card>
        </>

    );
}
