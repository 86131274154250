import {Badge, Button, Card, Col, Image, ListGroup, Modal, Row} from "react-bootstrap";
import {IconButton, Typography} from "@material-ui/core";
import CloseIcon from "@material-ui/icons/Close";
import {useEffect} from "react";
import CancelIcon from '@material-ui/icons/Cancel';
import PerfectScrollbar from "react-perfect-scrollbar";
export default function SymptomAddModal({
 open,
 onClose, data, options, setModalData
}) {
    useEffect(() => {
        console.log('data symptom', data)
    }, []);
    const tempData = [
        'Vomit',
        'Lip wounds',
        'Inflammation of the tounge',
        'Bad breath',
        'Mouth burns',
        'Sores',
        'Drooling',
        'Refusal to eat',
        'Mouth inflammation',
        'Coughing',
        'Gagging'
    ]
    return (
            <Modal size={'lg'} show={open} onHide={() => {}}>
                <Modal.Header  style={{border: 'none', display: 'block'}}>
                    <div className={'d-flex'} style={{justifyContent: "space-between", alignItems: "center"}}>
                           <div>
                               <Modal.Title>{data?.label}</Modal.Title>
                               <p>Choose Symptom below.</p>
                           </div>
                        <div>

                            <IconButton style={{
                                background:'#E8E8E8',
                                borderRadius: "20px",
                                fontSize:12,
                                padding: "5px"
                            }} onClick={() => {
                                onClose()
                            }} aria-label="delete">
                                Close  <CancelIcon fontSize="small" />
                            </IconButton>
                        </div>

                    </div>
                </Modal.Header>
                <Modal.Body style={{paddingTop: 0}}>
                   <Row>
                       <Col md={3}>
                           <Image style={{
                               objectFit:'fill',
                               width:'100%',
                               borderRadius:12,
                               border:'1px solid #E8E8E8'
                           }} src={data?.image}></Image>
                       </Col>
                       <Col md={9}>

                           <Card style={{
                               borderRadius:12,
                               background:'#F7F7F8',
                               maxHeight:400,
                               overflowY: 'auto'
                           }}>

                               <div className={'p-4'}>

                                       {
                                           options.map((v) => {
                                               return (
                                                   <>
                                                       <div style={{display:'grid'}}>
                                                            <Button 
                                                                variant="link" 
                                                                size="lg"
                                                                onClick={() => {
                                                                    let sd = v.symptoms_detail.map((el, idx) => {
                                                                        console.log(v);
                                                                        let a = {
                                                                          detail_name: el.detail_name,
                                                                          type: el.type,
                                                                          options: el.symptom_detail_options.map(sy => ({
                                                                            symptom_id: v.id,
                                                                            label: sy.options,
                                                                            symptom_detail_option_id: sy.id,
                                                                            value: sy.id
                                                                          }))
                                                                        };
                                                                        return a;
                                                                      });
                                                                      v = { ...v, symptoms_detail: sd };
                                                                      console.log(v);
                                                                      setModalData(v);
                                                                    onClose()
                                                                }}    
                                                            > 
                                                           <Card.Text style={{
                                                               color: '#121212',
                                                               fontWeight: 400,
                                                               lineHeight: 2,
                                                               fontSize: 14,
                                                               textAlign:'left'
                                                           }}>{v.name}</Card.Text>
                                                        </Button>
                                                       </div>
                                                   </>
                                               )
                                           })
                                       }


                               </div>

                           </Card>

                       </Col>
                   </Row>
                </Modal.Body>
            </Modal>
        )
}