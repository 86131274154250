import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  Button,
  CircularProgress,
  Checkbox,
  FormControlLabel,
  FormGroup,
  Grid
} from '@material-ui/core';
import { cloneDeep } from 'lodash';

import { Card, CardBody, CardHeader } from '_metronic/_partials/controls';
import LoadingCard from 'app/components/common/LoadingCard';

import { fetchAppointmentTypeList } from 'redux/appointmentType/list';
import { fetchAppointmentTypesByDoctor } from 'redux/appointmentType/listByDoctor';
import { upsertDoctorAppointmentTypes } from 'redux/doctor/upsertAppointmentType';

const DoctorAppointmentTypeForm = ({ doctorID }) => {
  const dispatch = useDispatch();

  const {
    data: appointmentTypes,
    isFetching: isLoadingAppointmentTypes
  } = useSelector(state => state.appointmentType.list);

  const {
    data: appointmentTypesByDoctor,
    isFetching: isLoadingAppointmentTypesByDoctor
  } = useSelector(state => state.appointmentType.listByDoctor);

  useEffect(() => {
    dispatch(fetchAppointmentTypeList());
    dispatch(fetchAppointmentTypesByDoctor(doctorID));
  }, []);

  useEffect(() => {
    if (appointmentTypes && appointmentTypesByDoctor) {
      const newSelectedAppointmentTypes = appointmentTypes.map(
        appointmentType => ({
          id: appointmentType.id,
          name: appointmentType.booking_type_name,
          duration: appointmentType.duration * 10,
          selected: appointmentTypesByDoctor.some(
            appointmentTypeByDoctor =>
              appointmentTypeByDoctor.booking_type_name ===
              appointmentType.booking_type_name
          )
        })
      );

      setSelectedAppointmentTypes(newSelectedAppointmentTypes);
    }
  }, [appointmentTypes, appointmentTypesByDoctor]);

  const [selectedAppointmentTypes, setSelectedAppointmentTypes] = useState();

  const toggleAppointmentTypesByIndex = index => {
    const newSelectedAppointmentTypes = cloneDeep(selectedAppointmentTypes);
    newSelectedAppointmentTypes[index]['selected'] = !Boolean(
      newSelectedAppointmentTypes[index]['selected']
    );
    setSelectedAppointmentTypes(newSelectedAppointmentTypes);
  };

  const handleSaveClick = () => {
    const appointmentTypeNames = selectedAppointmentTypes
      .filter(selectedAppointmentType => selectedAppointmentType.selected)
      .map(selectedAppointmentType => selectedAppointmentType.name);

    const formattedValues = {
      doctor: doctorID,
      appointmentTypes: JSON.stringify(appointmentTypeNames)
    };

    dispatch(upsertDoctorAppointmentTypes(formattedValues));
  };

  if (isLoadingAppointmentTypes || isLoadingAppointmentTypesByDoctor)
    return <LoadingCard />;

  return (
    <Card>
      <CardHeader title="Doctor Appointment Types" />
      <CardBody>
        <FormGroup>
          {selectedAppointmentTypes?.map((appointmentType, i) => (
            <FormControlLabel
              control={
                <Checkbox
                  checked={selectedAppointmentTypes[i]?.selected}
                  onChange={() => toggleAppointmentTypesByIndex(i)}
                  color="primary"
                />
              }
              label={`${appointmentType.name} (${appointmentType.duration} minutes)`}
              key={i}
            />
          ))}
        </FormGroup>
        <Grid style={{ marginTop: '1rem' }}>
          <Button onClick={handleSaveClick} color="primary" variant="contained">
            Simpan
          </Button>
        </Grid>
      </CardBody>
    </Card>
  );
};

export default DoctorAppointmentTypeForm;
