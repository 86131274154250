import React, { useEffect, useState } from 'react';
import { Button, Grid, IconButton, MenuItem } from '@material-ui/core';
import { Autocomplete } from '@material-ui/lab';
import CloseIcon from '@material-ui/icons/Close';
import { makeStyles } from '@material-ui/core/styles';

import { TextField } from '../../../components/common/TextField';

const useStyles = makeStyles(theme => ({
  root: {
    '& .Mui-disabled .MuiAutocomplete-endAdornment': {
      display: 'none'
    }
  }
}));

export default function SymptomAndLocationSelect({
  disabled,
  index,
  deleteValue,
  symptomList,
  symptomLocationList,
  value,
  helperText,
  error,
  addSymptomValue,
  deleteSymptomValue,
  openDialog
}) {
  const classes = useStyles();

  const { bindId, symptomId, symptomName, locationId, locationName } = value;

  const [symptom, setSymptom] = useState(null);
  const [symptomInput, setSymptomInput] = useState('');
  const [location, setLocation] = useState('');

  useEffect(() => {
    value?.symptom && setSymptom(value.symptom);
    value?.location && setLocation(value.location);
  }, []);

  useEffect(() => {
    symptom && addSymptomValue(index, { symptom, location });
  }, [symptom, location]);

  const handleSymptomChange = (event, value) => setSymptom(value);
  const handleLocationChange = event => setLocation(event.target.value);
  const handleDeleteClick = () => deleteValue(index);
  const handleOpenDialogClick = () => openDialog();

  return (
    <Grid container spacing={2}>
      <Grid item xs={8}>
        <TextField
          disabled={true}
          // id={`${symptom}${index}`}
          value={symptomName}
          label="Symptom"
          // fullWidth
          // variant="outlined"
          // size="small"
        />
        {/* <Autocomplete
          disabled={true}
          id={`${symptom}${index}`}
          value={symptom}
          onChange={handleSymptomChange}
          options={symptomList}
          getOptionLabel={option => option.symptom_name || ''}
          inputValue={symptomInput}
          onInputChange={(event, newInputValue) => {
            setSymptomInput(newInputValue);
          }}
          size="small"
          noOptionsText={
            <Grid>
              No symptoms found
              <Button
                onMouseDown={event => event.preventDefault()}
                onClick={handleOpenDialogClick}
                color="primary"
                style={{ marginLeft: '0.5rem' }}
              >
                Create Symptom
              </Button>
            </Grid>
          }
          renderInput={params => (
            <TextField
              {...params}
              helperText={helperText}
              error={error}
              label="Symptom"
              variant="outlined"
            />
          )}
          className={classes.root}
        /> */}
      </Grid>
      <Grid item xs={4}>
        <Grid container alignItems="center">
          <Grid item xs>
            <TextField disabled={true} value={locationName || '-'} label="Location" />
            {/* <TextField
              disabled={true}
              id="location"
              select
              value={location}
              onChange={handleLocationChange}
              label="Location"
              fullWidth
              variant="outlined"
              size="small"
            >
              <MenuItem value="">
                <em>NONE</em>
              </MenuItem>
              {symptomLocationList.map(({ id, part_name }) => (
                <MenuItem key={id} value={id}>
                  {part_name}
                </MenuItem>
              ))}
            </TextField> */}
          </Grid>
          {!disabled && (
            <Grid item style={{ marginLeft: '0.5rem', height: 'fit-content' }}>
              <IconButton onClick={handleDeleteClick} size="small">
                <CloseIcon />
              </IconButton>
            </Grid>
          )}
        </Grid>
      </Grid>
    </Grid>
  );
}
