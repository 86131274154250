import APIRequest from "../utils/APIRequest";

export default class UserModel {

    static retrieveUser = async()=>{
        return await APIRequest.setV1userAPI(`api/user/retrieve-users`, 'GET');
    }

    static retrievePatientByOwnerId = async(ownerId)=>{
        return await APIRequest.setV1userAPI(`api/user/retrieve-patient?owner_id=${ownerId}`, 'GET');
    }

}
