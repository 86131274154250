import AppointmentListDoctor from './pages/appointment/AppointmentListDoctor';
import AppointmentListPage from './pages/appointment/AppointmentListPageV2';
import AppointmentAddPage from './pages/appointment/AppointmentAddPage';
import AppointmentSummaryPage from './pages/appointment/AppointmentSummaryPage';
import AppointmentTypeListPage from './pages/appointmentType/AppointmentTypeListPage';
import AppointmentTypeAddPage from './pages/appointmentType/AppointmentTypeAddPage';
import AppointmentTypeEditPage from './pages/appointmentType/AppointmentTypeEditPage';
import AnimalDataPage from './pages/diagnoseV2/AnimalDataPage';
import PreDiagnosePage from './pages/diagnoseV2/PreDiagnosePage';
import SymptomPage from './pages/diagnoseV2/SymptomPage';
import CustomerListPage from './pages/customer/CustomerListPage';
import CustomerAddPage from './pages/customer/CustomerAddPage';
import CustomerEditPage from './pages/customer/CustomerEditPage';
import PatientListPage from './pages/patient/PatientListPage';
import PatientAddPage from './pages/patient/PatientAddPage';
import PatientEditPage from './pages/patient/PatientEditPage';
import DoctorListPage from './pages/doctor/DoctorListPage';
import DoctorAddPage from './pages/doctor/DoctorAddPage';
import DoctorEditPage from './pages/doctor/DoctorEditPage';
import DoctorTimetablePage from './pages/doctor/DoctorTimetablePage';
import ParticipantListPage from './pages/participant/ParticipantListPage';
import ParticipantEditPage from './pages/participant/ParticipantEditPage';
import VisitReminderEditPage from './pages/visitReminder/VisitReminderEditPage';

// Diagnosis Module
import DiagnosePage from './pages/diagnose/DiagnosePage';
import DiagnoseBuilderAddPage from './pages/diagnoseBuilder/DiagnoseBuilderAddPage';
import DiseaseListPage from './pages/disease/DiseaseListPage';
import DiseaseAddPage from './pages/disease/DiseaseAddPage';
import DiseaseAddPageV2 from './pages/disease/DiseaseAddPageV2';
import DiseaseEditPage from './pages/disease/DiseaseEditPage';
import DiseaseEditPageV2 from './pages/disease/DiseaseEditPageV2';
import SymptomListPage from './pages/symptom/SymptomListPage';
import SymptomAddPage from './pages/symptom/SymptomAddPage';
import SymptomAddPageV2 from './pages/symptom/SymptomAddPageV2';
import SymptomEditPage from './pages/symptom/SymptomEditPage';
import SymptomEditPageV2 from './pages/symptom/SymptomEditPageV2';
import MedicineListPage from './pages/medicine/MedicineListPage';
import MedicineAddPage from './pages/medicine/MedicineAddPage';
import MedicineEditPage from './pages/medicine/MedicineEditPage';
import AnimalTypeListPage from './pages/animalType/AnimalTypeListPage';
import AnimalTypeAddPage from './pages/animalType/AnimalTypeAddPage';
import AnimalTypeEditPage from './pages/animalType/AnimalTypeEditPage';
import AnimalCategoryListPage from './pages/animalCategory/AnimalCategoryListPage';
import AnimalCategoryAddPage from './pages/animalCategory/AnimalCategoryAddPage';
import AnimalCategoryEditPage from './pages/animalCategory/AnimalCategoryEditPage';

import ResetPasswordPage from './pages/resetPassword/ResetPasswordPage';
import ClinicAddPage from './pages/clinic/ClinicAddPage';
import OperationTableAddPage from './pages/operation/OperationTableAddPage';
import ClinicListPage from './pages/clinic/ClinicListPage';
import OperationTableListPage from './pages/operation/OperationTableListPage';
import ClinicEditPage from './pages/clinic/ClinicEditPage';
import OperationTableEditPage from './pages/operation/OperationTableEditPage';
import MedicalRecordAddPageV2 from './pages/medicalRecord/MedicalRecordAddPageV2';
import IncomingAppointmentListPage from "./pages/appointment/IncomingAppointmentListPage";
import ReminderAddPage from './pages/reminder/ReminderAddPage';
import MedicalRecordAppointmentListPage from "./pages/appointment/MedicalRecordAppointmentListPage";
import MedicalRecordEditPageV2 from './pages/medicalRecord/MedicalRecordEditPageV2';
import ClinicSchedulePage from './pages/clinic/ClinicSchedulePage';
import ReminderEditPage from './pages/reminder/ReminderEditPage';
import AppointmentEditPageV2 from './pages/appointment/AppointmentEditPageV2';
import DoctorEditPageV2 from './pages/doctor/DoctorEditPageV2';
import AppointmentChangePage from './pages/appointment/AppointmentChangePage';
import ReportPage from './pages/report/ReportPage'
import NametagListPage from './pages/nametag/v2/NametagListPageV2';
import NametagAddPage from './pages/nametag/v2/NametagAddPageV2';
import NametagEditPage from './pages/nametag/v2/NametagEditPageV2';
import VaccinationAddPage from "./pages/vaccination/VaccinationAddPage";
import VaccinationEditPage from "./pages/vaccination/VaccinationEditPage";
import DiagnoseBuilderResult from "./pages/diagnoseBuilder/DiagnoseBuilderResult";


export const ROUTE_CONFIG = [
  // USER Module
  {
    path: '/appointment',
    component: AppointmentListPage,
    breadcrumb: 'Appointment',
    title: 'View Appointments',
    blackList: ['DOCTOR']
  },
  {
    path: '/incoming-appointment',
    component: IncomingAppointmentListPage,
    breadcrumb: 'Appointment',
    title: 'Incoming Appointments',
    blackList: ['DOCTOR']
  },
  {
    path: '/appointment/add',
    component: AppointmentAddPage,
    breadcrumb: 'Add',
    title: 'Add Appointment',
    blackList: ['DOCTOR']
  },
  {
    path: '/appointment/:id',
    component: AppointmentSummaryPage,
    breadcrumb: 'Summary',
    title: 'Summary Appointment'
  },
  {
    path: '/diagnose/:id/general-data/:diagnoseId',
    component: AnimalDataPage,
    breadcrumb: 'Summary',
    title: 'Summary Appointment'
  },
  {
    path: '/diagnose/:id/pre-diagnose/:diagnoseId',
    component: PreDiagnosePage,
    breadcrumb: 'Diagnose',
    title: 'Appointment Diagnose'
  },
  {
    path: '/diagnose/:id/symptom-form/:diagnoseId',
    component: SymptomPage,
    breadcrumb: 'Diagnose',
    title: 'Appointment Diagnose'
  },
  {
    path: '/diagnose/:id/result/:diagnoseId',
    component: DiagnoseBuilderResult,
    breadcrumb: 'Diagnose Builder',
    title: 'Diagnose Builder'
  },
  {
    path: '/appointment/change/:id',
    component: AppointmentChangePage,
    breadcrumb: 'Summary',
    title: 'Summary Appointment'
  },


  {
    path: '/appointment/edit/:id',
    component: AppointmentEditPageV2,
    breadcrumb: 'Edit',
    title: 'Edit Appointment'
  },
  {
    path: '/appointment/:id/reminder',
    component: ReminderAddPage,
    breadcrumb: 'Add Reminder',
    title: 'Add Reminder'
  },
  {
    path: '/appointment/:id/reminder/edit/:reminderId',
    component: ReminderEditPage,
    breadcrumb: 'Edit Reminder',
    title: 'Edit Reminder'
  },
  {
    path: '/appointmentType',
    component: AppointmentTypeListPage,
    breadcrumb: 'Appointment Type',
    title: 'View Appointment Types',
    blackList: ['DOCTOR']
  },
  {
    path: '/appointmentType/add',
    component: AppointmentTypeAddPage,
    breadcrumb: 'Add',
    title: 'Add Appointment Type',
    blackList: ['DOCTOR']
  },
  {
    path: '/appointmentType/:id',
    component: AppointmentTypeEditPage,
    breadcrumb: 'Edit',
    title: 'Edit Appointment Type',
    blackList: ['DOCTOR']
  },
  {
    path: '/clinic/add',
    component: ClinicAddPage,
    breadcrumb: 'Add',
    title: 'Add Clinic',
    isEditing: true
  },
  {
    path: '/clinic/edit/:id',
    component: ClinicEditPage,
    breadcrumb: 'Edit',
    title: 'Edit Clinic',
    isEditing: true
  },
  {
    path: '/clinic/schedule/:id',
    component: ClinicSchedulePage,
    breadcrumb: 'Schedule',
    title: 'Schedule Clinic',
    isEditing: true
  },
  {
    path: '/clinic',
    component: ClinicListPage,
    breadcrumb: 'Clinic List',
    title: 'Clinic List'
  },
  {
    path: '/customer',
    component: CustomerListPage,
    breadcrumb: 'Customer',
    title: 'View Customers',
    blackList: ['DOCTOR']
  },
  {
    path: '/customer/add',
    component: CustomerAddPage,
    breadcrumb: 'Add',
    title: 'Add Customer',
    blackList: ['DOCTOR']
  },
  {
    path: '/customer/:id',
    component: CustomerEditPage,
    breadcrumb: 'Edit',
    title: 'Edit Customer'
  },
  {
    path: '/patient',
    component: PatientListPage,
    breadcrumb: 'Patient',
    title: 'View Patients',
    blackList: ['DOCTOR']
  },
  {
    path: '/patient/add',
    component: PatientAddPage,
    breadcrumb: 'Add',
    title: 'Add Patient',
    blackList: ['DOCTOR']
  },
  {
    path: '/patient/:id',
    component: PatientEditPage,
    breadcrumb: 'Edit',
    title: 'Edit Patient'
  },
  {
    path: '/doctor',
    component: DoctorListPage,
    breadcrumb: 'Doctor',
    title: 'View Doctors',
    blackList: ['DOCTOR']
  },
  {
    path: '/doctor/add',
    component: DoctorAddPage,
    breadcrumb: 'Add',
    title: 'Add Doctor',
    blackList: ['CS', 'DOCTOR']
  },
  {
    path: '/doctor/:id',
    component: DoctorEditPage,
    breadcrumb: 'Edit',
    title: 'Edit Doctor',
    blackList: ['CS', 'DOCTOR']
  },
  {
    path: '/doctor/editv2/:id',
    component: DoctorEditPageV2,
    breadcrumb: 'Edit',
    title: 'Edit Doctor',
    blackList: ['CS', 'DOCTOR']
  },
  {
    path: '/doctor/edit/:id',
    component: DoctorEditPage,
    breadcrumb: 'Edit',
    title: 'Edit Doctor',
    blackList: ['CS', 'DOCTOR']
  },
  {
    path: '/doctor/:id/schedule',
    component: DoctorTimetablePage,
    breadcrumb: 'Schedule',
    title: 'Doctor Schedule'
  },
  {
    path: '/doctor/:id/appointment',
    component: AppointmentListDoctor,
    breadcrumb: 'Appointment',
    title: 'My Appointment'
  },
  {
    path: '/operation',
    component: OperationTableListPage,
    breadcrumb: 'Operation Table',
    title: 'Operation Table'
  },
  {
    path: '/operation/add',
    component: OperationTableAddPage,
    breadcrumb: 'Add Operation Table',
    title: 'Operation Table'
  },
  {
    path: '/operation/edit/:id',
    component: OperationTableEditPage,
    breadcrumb: 'Edit Operation Table',
    title: 'Edit Operation Table'
  },
  {
    path: '/nametag',
    component: NametagListPage,
    breadcrumb: 'Name tag',
    title: 'Name tag'
  },
  {
    path: '/nametag/add',
    component: NametagAddPage,
    breadcrumb: 'Add Name tag',
    title: 'Add Name tag'
  },
  {
    path: '/nametag/edit/:id',
    component: NametagEditPage,
    breadcrumb: 'Edit Name tag',
    title: 'Edit Name tag'
  },
  {
    path: '/participant',
    component: ParticipantListPage,
    breadcrumb: 'Participant',
    title: 'View Participants',
    blackList: ['DOCTOR']
  },
  {
    path: '/participant/:id',
    component: ParticipantEditPage,
    breadcrumb: 'Edit',
    title: 'Edit Participant',
    blackList: ['DOCTOR']
  },
  {
    path: '/medicalRecord/add/:id',
    component: MedicalRecordAddPageV2,
    breadcrumb: 'Add',
    title: 'Add Medical Record'
  },
  {
    path: '/medicalRecord/edit/:id',
    component: MedicalRecordEditPageV2,
    breadcrumb: 'Edit',
    title: 'Edit Medical Record'
  },
  {
    path: '/visitReminder/:id',
    component: VisitReminderEditPage,
    breadcrumb: 'Edit',
    title: 'Edit Visit Reminder',
    blackList: ['DOCTOR']
  },

  // DIAGNOSIS Module
  {
    path: '/diagnose',
    component: DiagnosePage,
    breadcrumb: 'Diagnose',
    title: 'Diagnose',
    blackList: ['DOCTOR']
  },
  {
    path: '/diagnose-builder',
    component: DiagnoseBuilderAddPage,
    breadcrumb: 'Diagnose Builder',
    title: 'Diagnose Builder'
  },
  {
    path: '/disease',
    component: DiseaseListPage,
    breadcrumb: 'Disease',
    title: 'View Diseases',
    blackList: ['DOCTOR']
  },
  /* {
    path: '/disease/add',
    component: DiseaseAddPage,
    breadcrumb: 'Add',
    title: 'Add Disease',
    blackList: ['DOCTOR']
  }, */
  {
    path: '/disease/add',
    component: DiseaseAddPageV2,
    breadcrumb: 'Add',
    title: 'Add Disease',
    blackList: ['DOCTOR']
  },
  {
    path: '/disease/:id',
    component: DiseaseEditPageV2,
    breadcrumb: 'Edit',
    title: 'Edit Disease',
    blackList: ['DOCTOR']
  },
  {
    path: '/symptom',
    component: SymptomListPage,
    breadcrumb: 'Symptom',
    title: 'View Symptoms',
    blackList: ['DOCTOR']
  },
  {
    path: '/symptom/add',
    component: SymptomAddPageV2,
    breadcrumb: 'Add',
    title: 'Add Symptom',
    blackList: ['DOCTOR']
  },
  {
    path: '/symptom/:id',
    component: SymptomEditPageV2,
    breadcrumb: 'Edit',
    title: 'Edit Symptom',
    blackList: ['DOCTOR']
  },
  {
    path: '/medicine',
    component: MedicineListPage,
    breadcrumb: 'Medicine',
    title: 'View Medicines',
    blackList: ['DOCTOR']
  },
  {
    path: '/medicine/add',
    component: MedicineAddPage,
    breadcrumb: 'Add',
    title: 'Add Medicine',
    blackList: ['DOCTOR']
  },
  {
    path: '/medicine/:id',
    component: MedicineEditPage,
    breadcrumb: 'Edit',
    title: 'Edit Medicine',
    blackList: ['DOCTOR']
  },
  {
    path: '/animalType',
    component: AnimalTypeListPage,
    breadcrumb: 'Animal Type',
    title: 'View Animal Types',
    blackList: ['DOCTOR']
  },
  {
    path: '/animalType/add',
    component: AnimalTypeAddPage,
    breadcrumb: 'Add',
    title: 'Add Animal Type',
    blackList: ['DOCTOR']
  },
  {
    path: '/animalType/:id',
    component: AnimalTypeEditPage,
    breadcrumb: 'Edit',
    title: 'Edit Animal Type',
    blackList: ['DOCTOR']
  },
  {
    path: '/animalCategory',
    component: AnimalCategoryListPage,
    breadcrumb: 'Animal Category',
    title: 'View Animal Categories',
    blackList: ['DOCTOR']
  },
  {
    path: '/animalCategory/add',
    component: AnimalCategoryAddPage,
    breadcrumb: 'Add',
    title: 'Add Animal Category',
    blackList: ['DOCTOR']
  },
  {
    path: '/animalCategory/:id',
    component: AnimalCategoryEditPage,
    breadcrumb: 'Edit',
    title: 'Edit Animal Category',
    blackList: ['DOCTOR']
  },

  {
    path: '/reset-password',
    component: ResetPasswordPage,
    breadcrumb: 'Reset Password',
    title: 'Reset Password'
  },
  {
    path: '/v2/medical-records',
    component: MedicalRecordAppointmentListPage,
    breadcrumb: 'Medical Record List',
    title: 'Medical Records'
  },
  {
    path: '/report',
    component: ReportPage,
    breadcrumb: 'Report Page',
    title: 'Report page'
  },
  {
    path: '/vaccination/edit/:patient_id/:vaccination_id',
    component: VaccinationEditPage,
    breadcrumb: 'Edit Vaccination Page',
    title: 'Edit Vaccination'
  },
  {
    path: '/vaccination/:patient_id',
    component: VaccinationAddPage,
    breadcrumb: 'New Vaccination Page',
    title: 'New Vaccination'
  },

];
