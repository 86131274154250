import React from "react";
import { toAbsoluteUrl } from "../_metronic/_helpers";
import { Link, useParams, useLocation } from "react-router-dom";
import moment from "moment/moment";
import TextField from "../app/components/common/TextField/TextField";
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import { Button, CircularProgress } from "@material-ui/core";
import DiagnoseStepper from "./CustomStep";
// import { Stepper } from 'react-form-stepper';

export default function DiagnoseLayout({ children }) {
    let location = useLocation();
    let locations = location.pathname.split("/")
    let id = locations[2]
    console.log("CEK", locations)
    return (
        <>
            <div className="d-flex flex-column flex-root" style={{ background: "white" }}>
                {/*begin::Login*/}
                <div
                    style={{
                        background: 'linear-gradient(90deg, rgba(97,65,234,1) 0%, rgba(55,127,234,1) 100%)',
                    }}
                    className="login login-1 login-signin-on d-flex flex-column"
                    id="kt_login"
                >
                    <div className={'container'}>
                        <div className={'row p-5 mt-5'}>
                            <Link to={`/appointment/${id}`}>
                                <Button style={{
                                    fontSize: 14,
                                    background: '#FFFFFF24',
                                    color: "#fff",
                                    fontWeight: 600,
                                    borderRadius: 20,
                                    textTransform: "none",
                                    paddingLeft: 10,
                                    paddingRight: 10
                                }}>
                                    <ArrowBackIcon />  Back to home
                                </Button>
                            </Link>
                        </div>
                        <div style={{ justifyContent: 'center' }} className={'row'}>
                            <div
                                style={{
                                    margin: '0 auto',
                                    fontSize: 25,
                                    color: '#fff',
                                    fontWeight: 600
                                }}
                            >
                                Symptom Assessment
                            </div>
                        </div>
                        <div className={'row'} style={{ justifyContent: 'center', padding: '2rem 10rem 2rem 10rem' }}>
                            <DiagnoseStepper />
                        </div>
                    </div>

                    <div>

                    </div>
                    <svg viewBox="0 0 1024 44" style={{ width: "100%" }} fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path
                            // width="100%" height={"150px"}
                            d="M0 44C0 40.8895 0 39.3342 0.149556 38.0008C1.33899 27.3963 9.41565 18.8318 19.9323 17.0231C21.2546 16.7957 22.8351 16.703 25.9961 16.5175C401.299 -5.5059 622.701 -5.50578 998.004 16.5175C1001.16 16.703 1002.75 16.7957 1004.07 17.0232C1014.58 18.8318 1022.66 27.3963 1023.85 38.0008C1024 39.3342 1024 40.8895 1024 44H0Z"
                            fill="white" />
                    </svg>

                </div>

                {/* <div style={{
                    marginTop : "-4.296vw",
                    display : "flex", alignItems : "flex-end", justifyContent : "flex-end",
                    borderTop : "1px solid #00000000"
                    // background : "blue"
                }}>
                    <svg viewBox="0 0 1024 44" style={{height : "4.296vw", width : "100vw"}} fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path
                            // width="100%" height={"150px"}
                            d="M0 44C0 40.8895 0 39.3342 0.149556 38.0008C1.33899 27.3963 9.41565 18.8318 19.9323 17.0231C21.2546 16.7957 22.8351 16.703 25.9961 16.5175C401.299 -5.5059 622.701 -5.50578 998.004 16.5175C1001.16 16.703 1002.75 16.7957 1004.07 17.0232C1014.58 18.8318 1022.66 27.3963 1023.85 38.0008C1024 39.3342 1024 40.8895 1024 44H0Z"
                            fill="green" />
                    </svg>

                </div> */}

                <div className={'container'} style={{ background: "white" }}>
                    {children}
                </div>
            </div>
        </>
    )
}