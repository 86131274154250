import React, {useEffect, useState} from 'react';

import {
  Card,
  CardBody,
  CardHeader
} from '../../../_metronic/_partials/controls';

import { ListHeaderToolbar } from '../../components/common/HeaderToolbar';
import { DataTableLocal as DataTable } from '../../components/common/DataTable';
import Symptom from "../../models/Symptom";

const SymptomListPage = () => {
  const [symptomps, setSymptomps] = useState([])
  const initialize = async () => {
    try{
      const result = await Symptom.getAllActive()
      setSymptomps(result)
    }catch (e) {
      console.log('failed to retrive', e)
    }

  }
  useEffect(() => {
    initialize()
    // dispatch(fetchDiseaseList());
  }, []);
  //
  // const { data } = useSelector(state => state.disease.list);
  // const isFetching = useSelector(state => state.loading.disease);

  const columns = [
    {
      dataField: 'name',
      text: 'Symptom'
    },
    {
      dataField: 'body_part',
      text: 'Body Part',
    },

  ];

  return (
    <Card>
      <CardHeader title="Symptom list">
        <ListHeaderToolbar addLink="/symptom/add" addText="New Symptom" />
      </CardHeader>
      <CardBody>
        <DataTable
          data={symptomps}
          totalDataCount={symptomps.length}
          columns={columns}
          editLink="/symptom/"
        />
      </CardBody>
    </Card>
  );
};

export default SymptomListPage;
