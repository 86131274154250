import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Button, Grid, MenuItem } from '@material-ui/core';
import { Autocomplete } from '@material-ui/lab';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import { format, parseISO } from 'date-fns';

import { Card, CardBody, CardHeader } from '_metronic/_partials/controls';
import TextField from 'app/components/common/TextField';
import KeyboardDatePicker from 'app/components/common/KeyboardDatePicker';
import LoadingCard from 'app/components/common/LoadingCard';
import { ActionBarAdd } from 'app/components/common/ActionBar';

import { fetchCustomerList } from 'redux/customer/list';
import { fetchPatientList } from 'redux/patient/list';
import { fetchAppointmentTypeList } from 'redux/appointmentType/list';
import { addVisitReminder } from 'redux/visitReminder/add';

const VisitReminderForm = ({ data, isEditing = true, headerToolbar }) => {
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(fetchCustomerList());
    dispatch(fetchPatientList());
    dispatch(fetchAppointmentTypeList());
  }, []);

  const { data: customerList, isLoading: isLoadingCustomerList } = useSelector(
    state => state.customer.list
  );
  const { data: patientList, isLoading: isLoadingPatientList } = useSelector(
    state => state.patient.list
  );
  const {
    data: appointmentTypeList,
    isFetching: isLoadingAppointmentTypeList
  } = useSelector(state => state.appointmentType.list);

  const { completed: isAddCompleted, isLoading: isAddLoading } = useSelector(
    state => state.visitReminder.add
  );
  useEffect(() => {
    isAddCompleted && resetForm();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isAddCompleted]);

  const getCustomerFromID = ID =>
    customerList.find(customer => customer.id === ID);
  const getPatientFromID = ID => patientList.find(patient => patient.id === ID);

  const {
    values,
    errors,
    touched,
    handleChange,
    handleSubmit,
    handleReset,
    setFieldValue,
    setFieldError,
    resetForm
  } = useFormik({
    initialValues: {
      customer: getCustomerFromID(data?.customer_id) || '',
      patient: getPatientFromID(data?.patient_id) || '',
      appointmentType: data?.booking_type_name || '',
      date:
        (data?.target_send_date && parseISO(data?.target_send_date)) || null,
      description: data?.description || ''
    },
    validationSchema: Yup.object({
      customer: Yup.string().required('Cannot be empty'),
      patient: Yup.string().required('Cannot be empty'),
      appointmentType: Yup.string().required('Cannot be empty'),
      date: Yup.date().required('Cannot be empty'),
      description: Yup.string()
    }),
    onSubmit: formValues => {
      const formattedValues = {
        appointmentType: formValues.appointmentType,
        date: format(formValues.date, 'yyyy-MM-dd'),
        patientID: formValues.patient.id,
        description: formValues.description
      };

      dispatch(addVisitReminder(formattedValues));
    },
    enableReinitialize: true
  });

  const [customerInput, setCustomerInput] = useState('');
  const [patientInput, setPatientInput] = useState('');

  if (
    isLoadingCustomerList ||
    isLoadingPatientList ||
    isLoadingAppointmentTypeList
  )
    return <LoadingCard />;

  return (
    <Card>
      <CardHeader title="Visit reminder details">{headerToolbar}</CardHeader>
      <CardBody>
        <form onSubmit={handleSubmit}>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <Autocomplete
                disabled={!isEditing}
                value={values.customer}
                onChange={(_, newValue) => {
                  setFieldValue('customer', newValue);
                }}
                options={customerList}
                getOptionLabel={option =>
                  option ? `${option.user_name} (${option.mobile})` : ''
                }
                inputValue={customerInput}
                onInputChange={(_, newInputValue) => {
                  setCustomerInput(newInputValue);
                }}
                size="small"
                renderInput={params => (
                  <TextField
                    {...params}
                    helperText={
                      touched.customer && errors.customer ? errors.customer : ''
                    }
                    error={touched.customer && errors.customer ? true : false}
                    label="Owner *"
                    variant="outlined"
                  />
                )}
              />
            </Grid>
            <Grid item xs={12}>
              <Autocomplete
                disabled={!isEditing}
                value={values.patient}
                onChange={(event, newValue) => {
                  setFieldValue('patient', newValue);
                }}
                options={patientList.filter(
                  patient => patient.pet_owner_id === values.customer?.id || ''
                )}
                getOptionLabel={option => (option ? option.patient_name : '')}
                inputValue={patientInput}
                onInputChange={(event, newInputValue) => {
                  setPatientInput(newInputValue);
                }}
                size="small"
                renderInput={params => (
                  <TextField
                    {...params}
                    helperText={
                      touched.patient && errors.patient ? errors.patient : ''
                    }
                    error={touched.patient && errors.patient ? true : false}
                    label="Pet Name *"
                    variant="outlined"
                  />
                )}
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                disabled={!isEditing}
                id="appointmentType"
                select
                value={values.appointmentType}
                onChange={event => {
                  setFieldValue('appointmentType', event.target.value);
                }}
                label="Appointment Type *"
                helperText={
                  touched.appointmentType ? errors.appointmentType : ''
                }
                error={
                  touched.appointmentType && errors.appointmentType
                    ? true
                    : false
                }
              >
                {appointmentTypeList.map(appointmentType => (
                  <MenuItem
                    key={appointmentType.id}
                    value={appointmentType.booking_type_name}
                  >
                    {`${
                      appointmentType?.booking_type_name
                    } (${appointmentType?.duration * 10} minutes)`}
                  </MenuItem>
                ))}
              </TextField>
            </Grid>

            <Grid item xs={12}>
              <KeyboardDatePicker
                disabled={!isEditing}
                value={values.date}
                onChange={date => setFieldValue('date', date, true)}
                label="Appointment Date *"
                helperText={touched.date && errors.date ? 'Invalid date' : ''}
                error={touched.date && errors.date ? true : false}
                clearable
                format="dd/MM/yyyy"
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                disabled={!isEditing}
                id="description"
                value={values.description}
                onChange={handleChange}
                label="Description *"
                helperText={touched.description ? errors.description : ''}
                error={touched.description && errors.description ? true : false}
              />
            </Grid>
            {data ? null : (
              <ActionBarAdd
                isFetching={isAddLoading}
                handleSubmit={handleSubmit}
                handleReset={handleReset}
              />
            )}
          </Grid>
        </form>
      </CardBody>
    </Card>
  );
};

export default VisitReminderForm;
