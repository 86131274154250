import { useEffect } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { Button } from '@material-ui/core';
import { useDispatch, useSelector } from 'react-redux';

import { CardHeaderToolbar } from '_metronic/_partials/controls';
import VisitReminderForm from './components/VisitReminderForm';

import { fetchVisitReminder } from 'redux/visitReminder/detail';
import { deleteVisitReminder } from 'redux/visitReminder/delete';
import { usePrevious } from 'hooks/usePrevious';

const VisitReminderAddPage = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const { id } = useParams();

  const { data } = useSelector(state => state.visitReminder.detail);
  const { completed } = useSelector(state => state.visitReminder.delete);
  const previousCompleted = usePrevious(completed);

  useEffect(() => {
    dispatch(fetchVisitReminder(id));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (
      previousCompleted !== undefined &&
      completed !== previousCompleted &&
      completed
    ) {
      history.replace('/visitReminder');
    }
  }, [completed, previousCompleted]);

  const handleDeleteClick = () => {
    dispatch(deleteVisitReminder(id));
  };

  return (
    <VisitReminderForm
      data={data}
      isEditing={false}
      headerToolbar={
        <CardHeaderToolbar>
          <Button
            onClick={handleDeleteClick}
            color="secondary"
            variant="contained"
          >
            Delete
          </Button>
        </CardHeaderToolbar>
      }
    />
  );
};

export default VisitReminderAddPage;
