import React, {useEffect, useState} from 'react';
import {useSelector} from 'react-redux';
import {Button, Grid, MenuItem} from '@material-ui/core';
import {Autocomplete} from '@material-ui/lab';

import {TextField} from 'app/components/common/TextField';
import TimePicker from 'app/components/common/TimePicker';

import {makeStyles} from '@material-ui/core/styles';
import {Row, Col, Modal} from 'react-bootstrap';

import moment from 'moment';
import Appointment from '../../../models/Appointment';
import swal from 'sweetalert2';
import {parseISO} from "date-fns/esm";
import {format} from "date-fns";

const useStyles = makeStyles(theme => ({
    container: {
        backgroundColor: 'rgba(0, 0, 0, 0.25)'
    },
    paper: {
        width: '800px',
        maxWidth: '800px'
    },
    title: {
        marginBottom: '2rem',
        padding: '2rem 2rem 1rem 2rem',
        borderBottom: `1px solid ${theme.palette.divider}`
    },
    timeSlotContainer: {
        maxWidth: '360px'
    },
    datePicker: {
        marginBottom: '1rem'
    },
    actionContainer: {
        justifyContent: 'flex-start',
        marginTop: '2rem',
        padding: '1.5rem 2rem 1.5rem 2rem',
        borderTop: `1px solid ${theme.palette.divider}`
    }
}));

const EditAppointmentModal = ({isOpen, handleClose, doctorID, data}) => {
    const classes = useStyles();
    const {role} = useSelector(state => state.auth);

    const [startTime, setStartTime] = useState(null);
    const [endTime, setEndTime] = useState(null);
    const [category, setCategory] = useState(null)

    let [isMidnight, setIsMidnight] = useState(false)

    const [selectedBookingType, setSelectedBookingType] = useState(null);


    const onClose = refresh => {
        handleClose(refresh);
    };

    const submitForm = async () => {
        let appointmentModel = new Appointment();

        const formattedStart = parseISO(
            `${format(startTime, 'yyyy-MM-dd')} ${format(
                startTime,
                'HH:mm'
            )}:00`,
            'yyyy-MM-dd HH:mm:ss'
        );

        let formattedEnd

        if(isMidnight){
            let momentDate = moment(startTime)
            momentDate.add(1, "day")
            momentDate.set({hour : 0, minute : 0, second : 0, millisecond : 0})


            formattedEnd =  parseISO(
                `${format(momentDate.toDate(), 'yyyy-MM-dd')} ${format(
                    momentDate.toDate(),
                    'HH:mm'
                )}:00`,
                'yyyy-MM-dd HH:mm:ss'
            );
        }else{
            formattedEnd =  parseISO(
                `${format(endTime, 'yyyy-MM-dd')} ${format(
                    endTime,
                    'HH:mm'
                )}:00`,
                'yyyy-MM-dd HH:mm:ss'
            );
        }

        const formattedNewStart = moment(formattedStart)
        const formattedNewEnd = moment(formattedEnd)

        try {

            console.log("sded", formattedNewStart.toDate(), formattedNewEnd.toDate())

            let result = await appointmentModel.editAppointmentSlot(data.id, {
                start_time: formattedNewStart.toDate(),
                end_time: formattedNewEnd.toDate(),
                category_id: category.id
            });

            let confirmResult = await swal.fire({
                title: 'Success',
                text: 'Appointment berhasil diubah',
                icon: 'success',
                confirmButtonText: 'Go back',
                customClass: {
                    container: 'swal-z'
                }
            });

            handleClose(true);
        } catch (e) {
            let error = await swal.fire({
                title: 'Error',
                text: e.error_message ? e.error_message : 'Terjadi Kesalahan',
                icon: 'error',
                confirmButtonText: 'Go back',
                customClass: {
                    container: 'swal-z'
                }
            });

            console.log(e);
        }
    };

    useEffect(() => {
        reset();
        if (data) {
            setStartTime(moment(data.start_time, 'YYYY-MM-DDTHH:mm:ssZ').toDate());
            setEndTime(moment(data.end_time, 'YYYY-MM-DDTHH:mm:ssZ').toDate());
            setSelectedBookingType(data)
            if (moment(data.end_time).format("HH:mm") === "00:00") {
                setIsMidnight(true)
            }
            setCategory(data.categories?.find(c => c.id === data.category_id))
        }
        console.log(data.categories)
    }, [data]);

    const reset = () => {
        setStartTime(null);
        setEndTime(null);
    };

    const renderTimeInput = props => {
        return (
            <TextField
                disabled={props.disabled}
                value={`${props.value}`}
                onChange={props.onChange}
                onClick={props.onClick}
                label={props.label}
            />
        );
    };

    return (
        <Modal show={isOpen} onHide={handleClose} classes={{container: classes.container, paper: classes.paper}}>
            <form>
                <Modal.Title className={classes.title}>Edit Appointment</Modal.Title>
                <Modal.Body>
                    <Row>
                        <Col md={6}>
                            Start time
                            <TimePicker
                                value={startTime}
                                onChange={date => {
                                    setStartTime(date);
                                }}
                                clearable
                                TextFieldComponent={renderTimeInput}
                            />
                        </Col>
                        <Col md={6}>
                            End time
                            <TimePicker
                                disabled={isMidnight}
                                value={endTime}
                                onChange={date => setEndTime(date)}
                                clearable
                                TextFieldComponent={renderTimeInput}
                            />
                        </Col>
                        <Col md={6}>
                            <Autocomplete
                                value={category}
                                onChange={(_, newValue) => {
                                    // setSelectedBookingType(newValue)
                                    setCategory(newValue);
                                }}
                                options={data?.categories}
                                getOptionLabel={(option) => option.name}
                                renderInput={params => <TextField {...params} variant="outlined"/>}
                            />
                        </Col>
                        <Col style={{textAlign: 'right'}} item xs={12} md={12}>
                            <label>
                                <input
                                    checked={isMidnight}
                                    onChange={(e) => {
                                        if (e.target.checked) {
                                            setIsMidnight(true)
                                            let midTime = moment().subtract(1, 'days').startOf('day');
                                            setEndTime(midTime)
                                        } else {

                                            let startTimeFormatted = moment(startTime)
                                            startTimeFormatted.set({hour : 23, minute : 59})

                                            setEndTime(startTimeFormatted.toDate())
                                            setIsMidnight(false)
                                        }
                                    }} type="checkbox"/>
                                &nbsp;Midnight
                                <div>
                                    <small>*Jika pilih midnight maka akan dihitung hari berikutnya</small>
                                </div>
                            </label>
                        </Col>
                    </Row>
                </Modal.Body>
                <Modal.Footer className={classes.actionContainer}>
                    <>
                        {role !== 'DOCTOR' && (
                            <Button color="primary" variant="contained" className="mr-2" onClick={() => submitForm()}>
                                Edit Appointment
                            </Button>
                        )}
                        <Button
                            color="secondary"
                            variant="contained"
                            onClick={() => {
                                onClose();
                            }}
                        >
                            Cancel
                        </Button>
                    </>
                </Modal.Footer>
            </form>
        </Modal>
    );
};

export default EditAppointmentModal;
