export default class APIRequest {

    static set = async (endpoint, method, body) => {
  
      // console.log(localStorage.token)
  
      let token = localStorage.token;
      let request = {
        method: method,
        headers: {
          'Content-Type': 'application/json',
          Authorization: token ? `bearer ${token}` : null,
          Accept: 'application/json',
        },
        body: JSON.stringify(body)
      };
  
      let response = await fetch(`${process.env.REACT_APP_NEO_DIAGNOSIS_API_V3_CONFIG}/` + endpoint, request);
      if (response.ok) {
        return response.json();
      }
  
      let error = await response.json();
      console.log(error.msg)
  
      if (
        error.msg === 'JWT_EXPIRED' ||
        error.msg === 'NO_TOKEN_PROVIDED' ||
        error.msg === 'INVALID_TOKEN' ||
        error.msg === 'BAD_TOKEN_FORMAT' ||
        error.msg === 'NO_SECRET_DEFINED' ||
        error.error_message === 'JWT_MALFORMED' ||
        error.error_message?.msg === 'JWT_MALFORMED' ||
        error.error_message === 'JWT_EXPIRED' ||
        error.code === "SUBSCRIPTION_EXPIRED" ||
        error.code === "JWT_EXPIRED"
      ) {
        localStorage.clear();
        console.log("Token deleted");
        window.location.reload()
      }
      throw error;
    }
  
    static setV1userAPI = async (endpoint, method, body) => {
  
      // console.log(localStorage.token)
  
      let token = localStorage.token;
      let request = {
        method: method,
        headers: {
          'Content-Type': 'application/json',
          Authorization: token ? `bearer ${token}` : null,
          Accept: 'application/json',
        },
        body: JSON.stringify(body)
      };
  
      let response = await fetch(`${process.env.REACT_APP_USER_API_V3_CONFIG}/` + endpoint, request);
      if (response.ok) {
        return response.json();
      }
  
      let error = await response.json();
      console.log(error.msg)
  
      if (
        error.msg === 'JWT_EXPIRED' ||
        error.msg === 'NO_TOKEN_PROVIDED' ||
        error.msg === 'INVALID_TOKEN' ||
        error.msg === 'BAD_TOKEN_FORMAT' ||
        error.msg === 'NO_SECRET_DEFINED' ||
        error.error_message === 'JWT_MALFORMED' ||
        error.error_message?.msg === 'JWT_MALFORMED' ||
        error.error_message === 'JWT_EXPIRED' ||
        error.code === "SUBSCRIPTION_EXPIRED"
      ) {
        delete localStorage.token;
        console.log("Token deleted");
        window.location.reload();
      }
      throw error;
    }
  
    static setMultipart = async (endpoint, method, body) => {
      let token = localStorage.token;
  
      let response = await fetch(`${process.env.REACT_APP_NEO_DIAGNOSIS_API_V3_CONFIG}/` + endpoint, {
        method: method,
        headers: {
          Authorization: token ? `bearer ${token}` : null,
          'Access-Control-Allow-Origin': '*',
        },
        body: body
      });
      if (response.ok) {
        return response.json()
      }
      else {
        let error = await response.json()
        console.log(error)
        throw error
      }
    }
  
  }
  