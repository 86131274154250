import { useEffect, useState } from 'react';
import { IconButton, Tooltip } from '@material-ui/core';
import { ScheduleOutlined, KingBed } from '@material-ui/icons';
import { Link, useHistory } from 'react-router-dom';
import Clinic from 'app/models/Clinic';
import swal from 'sweetalert2';
import { ListHeaderToolbar } from '../../components/common/HeaderToolbar';
import { Card, CardBody, CardHeader } from '../../../_metronic/_partials/controls';
import { DataTableLocal as DataTable } from '../../components/common/DataTable';
import OperationTable from 'app/models/OperationTable';

const operationTable = new OperationTable()
const ClinicListPage = () => {
  let [clinicList, setClinicList] = useState([]);
  let history = useHistory();
  const fetchClinicList = async () => {
    let clinicModel = new Clinic();
    try {
      let result = await clinicModel.getAllClinic();

      setClinicList(result);
      console.log(result);
    } catch (e) {
      console.log('ERROR' + e);
    }
  };

  useEffect(() => {
    fetchClinicList();
  }, []);

  const columns = [
    {
      text: 'Clinic Name',
      dataField: 'name'
    },
    {
      text: 'Address',
      dataField: 'address'
    }
  ];

  window.editop = (id)=>{
    swal.close()
    history.push(`/operation/edit/${id}`)
  }

  window.createop = (id)=>{
    swal.close()
    history.push(`/operation/add`)
  }

const optablehtml = (optables)=>{
  return `<table class="table table-striped table-bordered dt-responsive nowrap" cellspacing="0" width="100%">
  <button onclick="createop()">Create</button>
  <thead>
      <tr>
          <th>Name</th>
          <th>Type</th>
         
      </tr>
  </thead>
  <tbody>
  ${optables.map(o=>`<tr>
  <td>${o.name}</td>
  <td>${o.type}</td>
  <td><button onclick="editop(${o.id})">Edit</button></td>
</tr>`).join('')}   
   
  </tbody>
</table>`
}

  return (
    <>
      <Card>
        <CardHeader title="Clinic list">
          <ListHeaderToolbar addLink="/clinic/add" addText="New Clinic" />
        </CardHeader>
        <CardBody>
          <DataTable
            data={clinicList}
            totalDataCount={clinicList.length}
            columns={columns}
            editLink="/clinic/edit/"
            renderCustomActions={row => {
              return (<>
              <IconButton size="small" onClick={async()=>{
                
                let optables = await operationTable.getAllOperationByClinicId(row.id)

                swal.fire({
                  title: 'Operation tables',
                  html: optablehtml(optables),
                  confirmButtonText: 'Ok',
                  customClass: {
                      container: 'swal-z'
                  }
              });
              }}>
                    <KingBed />
                  </IconButton>
                <Link to={`/clinic/schedule/${row.id}`}>
                  <IconButton size="small">
                    <ScheduleOutlined />
                  </IconButton>
                </Link>
                
              </>
              
              );
            }}
          />
        </CardBody>
      </Card>
    </>
  );
};

export default ClinicListPage;
