import ApiRequest from '../../utils/APIRequest2';

export default class Symptom {

  static getAll = async () => {
    return await ApiRequest.set('v1/generalInformations', 'GET');
  };


}
