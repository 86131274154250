import React from 'react';
import { KeyboardDatePicker } from '@material-ui/pickers';
import { makeStyles } from '@material-ui/core/styles';
import { MuiPickersUtilsProvider } from '@material-ui/pickers';
import DateFnsUtils from '@date-io/date-fns';

const useStyles = makeStyles(theme => ({
  root: {
    '& label.Mui-disabled': {
      color: '#282828'
    },
    '& input:disabled': {
      color: '#282828'
    },
    '& .Mui-disabled fieldset': {
      borderColor: 'transparent !important'
    },
    '& .Mui-disabled .MuiInputAdornment-root': {
      display: 'none'
    },
    '& .MuiInputBase-marginDense.MuiOutlinedInput-adornedEnd': {
      paddingRight: '0'
    }
  }
}));

const CustomKeyboardDatePicker = props => {
  const classes = useStyles();

  return (
    <MuiPickersUtilsProvider utils={DateFnsUtils}>
      <KeyboardDatePicker
        {...props}
        fullWidth
        inputVariant="outlined"
        size="small"
        className={classes.root}
      />
    </MuiPickersUtilsProvider>
  );
};

export default CustomKeyboardDatePicker;
